import {Fragment, useCallback, useEffect, useReducer, useState } from "react";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Api } from "../../services/Api";
import Typography from "@material-ui/core/Typography";
import { 
  Box,
  IconButton, 
  TextField, 
  Button,
  Container, 
  MenuItem,
  Select,
  InputLabel,
  Switch, 
  Chip, 
  FormControl, 
  FormControlLabel, 
  InputAdornment, 
  TableContainer,
  Tabs,
  Tab,
  Card,
  CardContent
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";

import FloatingActionButton from "../../screens/FloatingActionButton";
import ReceiptIcon from '@material-ui/icons/Receipt';
import Checkbox from "@material-ui/core/Checkbox";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SearchIcon from "@material-ui/icons/Search"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PurchaseOrderGrn from "../PurchaseOrder/Grn";
import QRCode from 'qrcode.react';

import ReactDOMServer from 'react-dom/server';
import FileDownload from 'js-file-download';
function roundAmount(amount){
  let frac = 100;
  return Math.round((amount+Number.EPSILON)*frac) / frac;
}
const StudentOfficialInvoiceView = (props) =>{  
  const invoice = props.invoice;
  const invoicePayment = props.invoicePayment;
  const student = props.student;

  useEffect(()=>{
    console.log(invoice, invoicePayment);
  }, [invoice, invoicePayment]);

  const downloadInvoicePdf = () => {
    const canvas = document.getElementById("invoiceQRCode");
    const dataUrl = canvas.toDataURL("img/png");
    let htmlInvoice = ReactDOMServer.renderToString(<HtmlComponent qrCode={dataUrl} />);
    //console.log(htmlInvoice);
    Api.downloadStudentInvoice(htmlInvoice).then((res)=>{
      FileDownload(res,invoice.issuedOn+".pdf");
    }).catch((e)=>{
      console.log(e);
    });
  }

  const HtmlComponentWK = (props) =>{
    const qrCode = props.qrCode;
    const invoiceContainer = {
      marginTop: "100px",
    }
    return (
      <html>
        <head>
          <title>KSA E-Invoice</title>
          <link href="https://raw.githubusercontent.com/adobe-fonts/source-sans/release/source-sans-3.css" rel="stylesheet" />
          {/* <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" /> */}
        </head>
        <body style={{fontFamily: "'Source Sans 3'", fontSize: "'12pt'"}}>
          <div style={invoiceContainer}>
            <div className="row">
              <div className="col-xs-4">
                <img src="https://cacticonsult.com/assets/images/logo-small.png" style={{maxWidth: "150px"}} />
              </div>
              <div className="col-xs-4">
                <img src={qrCode} style={{maxWidth: "150px"}} />
              </div>
              <div className="col-xs-4">
                Invoice #: 123<br />
                Invoice ID: {invoice._id} <br />
                Created: {invoice.issuedOn}<br />
                Due: {invoice.dueOn}
              </div>              
            </div>
            <div className="row">
              <div className="col-xs-4">
                  Cacti Software W.L.L<br />
									Office 51 Al Maha Tower<br />
									Manama, Kingdom of Bahrain
              </div>
              <div className="col-xs-4">
                  Electronic Invoice
              </div>
              <div className="col-xs-4">
                  {student.name}<br />
									Attention: {student.guardians[0]._id.name}<br />
									{student.guardians[0]._id.email}
              </div>
            </div>
          </div>
        </body>
      </html>
    )
  }

  const HtmlComponent = (props) =>{
    const qrCode = props.qrCode;
    const invoiceContainer = {
      marginTop: "20pt",
    }
    const pullLeft = {
      textAlign: 'left'
    }
    return (
      <html>
        <head>
          <title>KSA E-Invoice</title>
        </head>
        <body style={{fontFamily: "Roboto",/*  fontSize: "12pt" */}}>
          <div style={invoiceContainer}>
            <table style={{marginBottom: '40px', width: '100%'}}>
              <tr>
                <td width="50%"><img src="https://cacticonsult.com/assets/images/logo-small.png" style={{maxWidth: "100px"}} /></td>
                <td width="50%" style={{textAlign: 'right'}}>ABC Company LLC<br />
									Office 51 Al Maha Tower<br />
									Jeddah, Kingdom of Saudi Arabia
                </td>
              </tr>
            </table>
            <table style={{width: '100%'}}>
              <thead>
                <tr>
                  <td width="70%">
                    <table width="100%">
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Invoice #</td>
                        <td>123</td>
                        <td dir="rtl"><span lang="ar" style={{fontFamily: 'TajawalBold', textAlign:'right'}}>رقم الفاتورة</span></td>
                      </tr>
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Invoice ID</td>
                        <td>{invoice._id}</td>
                        <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>هوية الفاتورة</span></td>
                      </tr>
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Created</td>
                        <td>{invoice.issuedOn}</td>
                        <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>التاريخ و الوقت</span></td>
                      </tr>
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Student</td>
                        <td>{student.name}</td>
                        <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>الطالب</span></td>
                      </tr>
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Nat.ID Number</td>
                        <td>{student.demographics.nationalid}</td>
                        <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>الرقم الوطني</span></td>
                      </tr>
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Nationality</td>
                        <td>{student.demographics.nationality.Name}</td>
                        <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>الجنسية</span></td>
                      </tr>
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Parent</td>
                        <td>{student.guardians[0]._id.name}</td>
                        <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>ولي الأمر</span></td>
                      </tr>
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Email</td>
                        <td>{student.guardians[0]._id.email}</td>
                        <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>البريد الالكتروني</span></td>
                      </tr>
                      <tr>
                        <td style={{fontFamily: 'RobotoBold'}}>Mobile</td>
                        <td>{student.guardians[0]._id.mobile}</td>
                        <td dir="rtl"><span lang="ar" dir="rtl" style={{fontFamily: 'TajawalBold'}}>رقم الجوال</span></td>
                      </tr>
                    </table>
                  </td>
                  <td width="30%" style={{paddingLeft: '20px', verticalAlign:'top', textAlign:'right'}}>
                    <img src={qrCode} style={{maxWidth: "150px"}} /><br /><br />
                    <table style={{float: 'right'}}>
                      <tr>
                        <td style={pullLeft}>Total</td>
                        <td>{invoice.ksa_einvoice.InvoiceTotalWithVat.Currency}</td>
                        <td>{invoice.ksa_einvoice.InvoiceTotalWithVat.TotalAmountWithVat}</td>
                      </tr>
                      <tr>
                        <td style={pullLeft}>Paid</td>
                        <td>{invoice.ksa_einvoice.PaidAmount.Currency}</td>
                        <td>{(invoicePayment&&invoicePayment.paid_amount) || "0"}</td>
                      </tr>
                      <tr>
                        <td style={pullLeft}>Due</td>
                        <td>{invoice.ksa_einvoice.DueAmount.Currency}</td>
                        <td>{(invoicePayment&&invoicePayment.due_amount!=null)?invoicePayment.due_amount:invoice.ksa_einvoice.InvoiceTotalWithVat.TotalAmountWithVat}</td>
                      </tr>
                      
                    </table>
                  </td>
                </tr>
                <tr>
                 
                 
                </tr>
              </thead>
            </table>

            <Table style={{width: "100%", marginTop: "5em"}}>
          
          <TableBody>
            <TableRow style={{fontFamily: 'RobotoBold'}}>
              <TableCell>
                Line Item
              </TableCell>
              <TableCell>
                Amount
              </TableCell>
              <TableCell>
                Discount
              </TableCell>
              <TableCell>
                Net Amount
              </TableCell>
              <TableCell>
                VAT Amount
              </TableCell>
              <TableCell>
                Total Amount
              </TableCell>
            </TableRow>
            {invoice.ksa_einvoice&&invoice.ksa_einvoice.LineItems.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>{obj.Name || ''}</TableCell>
                  <TableCell>
                    {obj.Amount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.Discount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.NetAmount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.VatAmount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.NetAmount&&obj.VatAmount?parseFloat(obj.VatAmount)+parseFloat(obj.NetAmount):0}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
          </div>
        </body>
      </html>
    )
  }

  const BasicHtmlComponent = (props) =>{
    const qrCode = props.qrCode;
    const invoiceContainer = {
      marginTop: "20pt",
    }
    const pullLeft = {
      textAlign: 'left'
    }
    return (
      <html>
      <head>
        <title>KSA E-Invoice</title>
      </head>
      <body style={{fontFamily: "Roboto",/*  fontSize: "12pt" */}}>
          <table style={{marginBottom: '40px', width: '100%'}}>
            <tr>
              <td width="50%"><img src="https://cacticonsult.com/assets/images/logo-small.png" style={{maxWidth: "100px"}} /></td>
              <td width="50%" style={{textAlign: 'right'}}>ABC Company LLC<br />
                Office 51 Al Maha Tower<br />
                Jeddah, Kingdom of Saudi Arabia
              </td>
            </tr>
          </table>
          <table style={{width: '100%'}}>
            <thead>
              <tr>
                <td width="70%">
                  <table width="100%">
                    <tr>
                      <td>Invoice #</td>
                      <td>123</td>
                      
                    </tr>
                    <tr>
                      <td>Invoice ID</td>
                      <td>{invoice._id}</td>
                      
                    </tr>
                    <tr>
                      <td style={{fontFamily: 'RobotoBold'}}>Created</td>
                      <td>{invoice.issuedOn}</td>
                      
                    </tr>
                    <tr>
                      <td>Student</td>
                      <td>{student.name}</td>
                      
                    </tr>
                    <tr>
                      <td>Nat.ID Number</td>
                      <td>{student.demographics.nationalid}</td>
                      
                    </tr>
                    <tr>
                      <td>Nationality</td>
                      <td>{student.demographics.nationality.Name}</td>
                      
                    </tr>
                    <tr>
                      <td>Parent</td>
                      <td>{student.guardians[0]._id.name}</td>
                      
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{student.guardians[0]._id.email}</td>
                      
                    </tr>
                    <tr>
                      <td>Mobile</td>
                      <td>{student.guardians[0]._id.mobile}</td>
                      
                    </tr>
                  </table>
                </td>
                <td width="30%" style={{paddingLeft: '20px', verticalAlign:'top', textAlign:'right'}}>
                  <img src={qrCode} style={{maxWidth: "150px"}} /><br /><br />
                  <table style={{float: 'right'}}>
                    <tr>
                      <td style={pullLeft}>Total</td>
                      <td>{invoice.ksa_einvoice.InvoiceTotalWithVat.Currency}</td>
                      <td>{invoice.ksa_einvoice.InvoiceTotalWithVat.TotalAmountWithVat}</td>
                    </tr>
                    <tr>
                      <td style={pullLeft}>Due</td>
                      <td>{invoice.ksa_einvoice.DueAmount.Currency}</td>
                      <td>{invoice.ksa_einvoice.DueAmount.DueAmount}</td>
                    </tr>
                    <tr>
                      <td style={pullLeft}>Paid</td>
                      <td>{invoice.ksa_einvoice.PaidAmount.Currency}</td>
                      <td>{invoice.ksa_einvoice.PaidAmount.PaidAmount}</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
               
               
              </tr>
            </thead>
          </table>
          </body>
      </html>
    )
  }
  
  /* 
  const printQRCodeSvg = () =>{
    const canvas = document.getElementById("invoiceQRCode");
    const dataUrl = canvas.toDataURL("img/png");
    console.log(dataUrl);
  } */
  
  return (
    <Fragment>
    <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="center">
        <Grid item>
        <IconButton
          aria-label="Grn"
          onClick={() => props.setSelectedInvoice(false)}>
          <CloseIcon />
        </IconButton>
        </Grid>
        <Grid item>
          <Typography component="h2" variant="h6" color="primary">
            Invoice
          </Typography>
        </Grid>
       <Grid item xs={6}>
          <Button onClick={()=> downloadInvoicePdf()}>Download Pdf</Button>
       </Grid>
      </Grid>

      <Card sx={{ minWidth: 500 }} >
      <CardContent>
        
        <Grid container justify="space-between" alignContent="center">
          <Grid item>
            {invoice.ksa_einvoice&&invoice.ksa_einvoice.QRCode?<QRCode id="invoiceQRCode" value={(invoice.ksa_einvoice.QRCode) } /> : ""}
          </Grid>
          <Grid item>
          <Grid container direction="column">
            <Typography component="h2" variant="h4" color="primary">
              Electronic Invoice
            </Typography>
            <Typography component="h2" variant="h6" color="primary">
             {invoice.ksa_einvoice.ID}
            </Typography>
            <Typography component="h2" variant="h6" color="primary">
             {invoice.issuedOn}
            </Typography>
          </Grid>
          </Grid>
          
          <Grid item>
          <Grid container direction="column">
            <Typography component="h2" variant="h4">
              {invoice.ksa_einvoice.Seller.SellerName}
            </Typography>
            <Typography component="h2" variant="h6">
             Issue to: {/* invoice.ksa_einvoice.Buyer.BuyerName */ student.guardians[0]._id.name}
            </Typography>
            <Typography component="h2" variant="h6">
             Total Including VAT: {invoice.ksa_einvoice.InvoiceTotalWithVat.TotalAmountWithVat}
            </Typography>
            <Typography component="h2" variant="h6">
             Paid Amount: {(invoicePayment&&invoicePayment.paid_amount) || 0} - Due Amount: {(invoicePayment&&invoicePayment.due_amount!=null)?invoicePayment.due_amount:invoice.ksa_einvoice.InvoiceTotalWithVat.TotalAmountWithVat}
            </Typography>
          </Grid>
          </Grid>
         
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Line Item</b>
              </TableCell>
              <TableCell>
                <b>Amount</b>
              </TableCell>
              <TableCell>
                <b>Discount</b>
              </TableCell>
              <TableCell>
                <b>Net Amount</b>
              </TableCell>
              <TableCell>
                <b>VAT Amount</b>
              </TableCell>
              <TableCell>
                <b>Amount Due</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.ksa_einvoice&&invoice.ksa_einvoice.LineItems.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>{obj.Name || ''}</TableCell>
                  <TableCell>
                    {obj.Amount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.Discount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.NetAmount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.VatAmount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.NetAmount&&obj.VatAmount?parseFloat(obj.VatAmount)+parseFloat(obj.NetAmount):0}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </CardContent>
      </Card>

  </Fragment>
  )
}

const StudentInvoicesView = (props) =>{
  const invoices = props.invoices;
  const invoicePayments = props.invoicePayments;
  const voidInvoice = props.voidInvoice;
  //const setSelectedProduct = props.setSelectedProduct;
  //const updateNewCharge = props.updateNewCharge;
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedInvoicePayment, setSelectedInvoicePayment] = useState(null);
  const student = props.student;

  return (selectedInvoice?
        <StudentOfficialInvoiceView 
        student={student} 
        invoice={selectedInvoice} 
        invoicePayment={selectedInvoicePayment}
        setSelectedInvoice={setSelectedInvoice} />
      :
       <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Issue Date</b>
              </TableCell>
              <TableCell>
                <b>Revenue Amount</b>
              </TableCell>
              <TableCell>
                <b>VAT Amount</b>
              </TableCell>
              <TableCell>
                <b>Net Amount</b>
              </TableCell>
              <TableCell>
                <b>Paid Amount</b>
              </TableCell>
              <TableCell>
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices&&invoices.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>
                  <Button style={obj.voided?{color: '#ff1111'}:{color: '#000000'}}
                  onClick={() => {setSelectedInvoice(obj);setSelectedInvoicePayment(invoicePayments[obj._id]||null)}}>{obj.issuedOn || ''}
                  </Button>
                    </TableCell>
                  <TableCell>
                    {obj.revenue_amount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.vat_amount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.net_amount || 0}
                  </TableCell>
                  <TableCell>
                    {invoicePayments[obj._id]&&invoicePayments[obj._id].paid_amount || 0}
                  </TableCell>
                  <TableCell>
                    {!obj.voided?<Button onClick={()=> voidInvoice(obj)}>Void</Button>:<Typography style={obj.voided?{color: '#ff1111'}:{color: '#000000'}}>VOIDED</Typography>}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
  )
}

const StudentPaymentsView = (props) =>{
  const payments = props.payments;
  const student = props.student;
  const [selectedPayment, setSelectedPayment] = useState(null);
  return (
       selectedPayment?
        <StudentPaymentReceiptView 
        student={student} 
        payment={selectedPayment} 
        setSelectedPayment={setSelectedPayment} />
       :
       <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Payment Date</b>
              </TableCell>
              <TableCell>
                <b>Amount</b>
              </TableCell>
              <TableCell>
                <b>Receipt</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments&&payments.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>{obj.paidOn || ''}</TableCell>
                  <TableCell>
                    {obj.paid_amount || 0}
                  </TableCell>
                  <TableCell>
                  <IconButton
                  title="View Receipt"
                  aria-label="View Receipt"
                  color="primary"
                  onClick={() => setSelectedPayment(obj)}
                  >
                  <ReceiptIcon />
                  </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
  )
}

const StudentPaymentReceiptView = (props) =>{
  const student = props.student;
  const payment = props.payment;

  useEffect(()=>{
    console.log(student);
  }, [student]);
  return (
    <Fragment>
      <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
          <IconButton
            aria-label="Grn"
            onClick={() => props.setSelectedPayment(false)}>
            <CloseIcon />
          </IconButton>
          </Grid>
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Payment Receipt
            </Typography>
          </Grid>
         <Grid item xs={6}>
         </Grid>
        </Grid>

        <Card sx={{ minWidth: 500 }}>
        <CardContent>
          <Grid container justify="space-between" alignContent="center">
            <Grid item direction="column">
              <p><b>Receipt</b></p>
              <p>{student.guardians[0]._id.name}</p>
              <p>Toward's {student.name}'s Account</p>
              <p>{payment.paidOn}</p>
            </Grid>
            <Grid item>
              Address
              Telephone
              Email
            </Grid>
          </Grid>
        </CardContent>
        </Card>

    </Fragment>
  )

}

const StudentRefundsView = (props) =>{
  const refunds = props.refunds;
  const issueRefundVisible = props.issueRefundVisible;
  const setIssueRefundVisible = props.setIssueRefundVisible;
  const issueStudentRefund = props.issueStudentRefund;
  const student = props.student;
  return (
    <Fragment>
     
      {!issueRefundVisible&&
        <IconButton
        title="Issue Refund"
        aria-label="Issue Refund"
        color="primary"
        onClick={() => setIssueRefundVisible(true)}
        >
        <AddIcon />
        </IconButton>
        }

        {issueRefundVisible&& 
          <IssueRefundView 
            setIssueRefundVisible={setIssueRefundVisible}
            issueStudentRefund={issueStudentRefund}
          />
          }
          {student.accounts["Net Student Balance"]<=0&&
            <Typography>Student with a negative balance cannot be refunded.</Typography>
          }
         
           <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Refund Date</b>
              </TableCell>
              <TableCell>
                <b>Amount</b>
              </TableCell>
              <TableCell>
                <b>Credit Note</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {refunds&&refunds.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>{obj.refundedOn || ''}</TableCell>
                  <TableCell>
                    {obj.refund_amount || 0}
                  </TableCell>
                  <TableCell>
                    
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

    </Fragment>
      )
}

const IssueRefundView = (props) =>{
  const defaultAmount = 0.01;
  const [amount, setAmount] = useState(defaultAmount);
  const [reason, setReason] = useState("");
  const [method, setMethod] = useState("Cash");

  return(
      <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
        <Grid item> 
        <TextField
        variant="outlined"
        label="Amount to Refund"
        defaultValue={defaultAmount}
        onChange={(e)=>setAmount(parseFloat(e.target.value.trim()))}
        onInput={(e) => {          
          if(!isNaN(e.target.value) && parseFloat(e.target.value)<0) e.target.value=defaultAmount;
          if(isNaN(e.target.value)) e.target.value=defaultAmount;
        }}
      ></TextField>
      </Grid>
      <Grid item> 
      <TextField 
        variant="outlined"
        label="Reason"
        defaultValue={"General Refund"}
        onChange={(e)=>setReason(e.target.value.trim())}
      ></TextField>
      </Grid>
      <Grid item> 
      <IconButton 
       title="Confirm Issue Refund"
       onClick={()=>props.issueStudentRefund(amount, reason, method)}>
        <DoneIcon />
      </IconButton>
      </Grid>
      <Grid item> 
      <IconButton 
       onClick={()=>props.setIssueRefundVisible(false)}>
        <CloseIcon />
      </IconButton>
      </Grid>
      </Grid>  
      )
}

const StudentStatementView = (props) =>{
  const refunds = props.refunds;
  const payments = props.payments;
  const invoices = props.invoices;
  const creditNotes = props.creditNotes;

  const [studentStatementAccounts, setStudentStatementAccounts] = useState([]);

  useEffect(() => {
    const studentAccounts = [];
    refunds.map((refund, key)=>{
      let obj = {
        type: "Refund",
        acc_type: "Debit",
        transDate: refund.refundedOn,
        amount: refund.refund_amount
      };
      studentAccounts.push(obj);
    });
    payments.map((payment, key)=>{
      let obj = {
        type: "Payment",
        acc_type: "Credit",
        transDate: payment.paidOn,
        amount: payment.paid_amount,
      };
      studentAccounts.push(obj);
    });
    invoices.map((invoice, key)=>{
      let obj = {
        type: "Invoice",
        acc_type: "Debit",
        transDate: invoice.issuedOn,
        amount: invoice.net_amount,
      };
      studentAccounts.push(obj);
    });

    creditNotes.map((cn, key)=>{
      let obj = {
        type: "Credit Note",
        acc_type: "Credit",
        transDate: cn.voidedOn,
        amount: cn.net_amount,
      };
      studentAccounts.push(obj);
    });

    studentAccounts.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.transDate) - new Date(b.transDate);
    });

    let carryingBalance = 0;
    studentAccounts.map((obj, key) => {
      obj.acc_type==="Debit"?carryingBalance-=obj.amount:carryingBalance+=obj.amount;
      obj.carryingBalance = roundAmount(carryingBalance);
    });

    setStudentStatementAccounts(studentAccounts);

  }, [refunds, payments, invoices]);
  
  return (
    <Fragment>
      <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell>
                <b>Type</b>
              </TableCell>
              <TableCell>
                <b>Debit</b>
              </TableCell>
              <TableCell>
                <b>Credit</b>
              </TableCell>
              <TableCell>
                <b>Balance</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentStatementAccounts&&studentStatementAccounts.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>{obj.transDate || ''}</TableCell>
                  <TableCell>{obj.type || ''}</TableCell>
                  <TableCell>
                    {obj.acc_type==="Debit" && obj.amount}
                  </TableCell>
                  <TableCell>
                    {obj.acc_type==="Credit" && obj.amount}
                  </TableCell>
                  <TableCell>
                    {obj.carryingBalance || 0}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

    </Fragment>
      )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function Student(props) {
  const [loading, setLoading] = useState(false);
  const [refundError, setRefundError] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [payments, setPayments] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const [creditNotes, setCreditNotes] = useState([]);

  const [invoicePayments, setInvoicePayments] = useState({});
  const [issueRefundVisible, setIssueRefundVisible] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const student = props.selectedStudent;
  const toggleStudent = props.toggleStudent;
  const updateStudentAccounts = props.updateStudentAccounts;
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  useEffect(() => {
    student&&Promise.all([
      Api.getStudentInvoices(student),
      Api.getStudentPayments(student),
      Api.getStudentRefunds(student)
    ]).then(([invoices, payments, refunds]) => {
      setInvoices(invoices);
      setPayments(payments);
      setRefunds(refunds);
      let cns = [];
      invoices.map((inv, key)=>{
        inv.voided&&cns.push(inv);
      });
      setCreditNotes(cns);
    }).catch((e)=>{
      console.log(e);
    });
  }, [student]);

  useEffect(() =>{
    let remaining_amount =0;
    let invoicePaymentsObject = {};
    payments.map((payment, key)=>{
      remaining_amount += payment.paid_amount;
    });
    refunds.map((refund, key)=>{
      remaining_amount -= refund.refund_amount;
    });

    let invoiceIndex = 0;
    while(remaining_amount>0&&invoiceIndex<invoices.length){
      if(!invoices[invoiceIndex].voided){
        if(invoices[invoiceIndex].net_amount>=remaining_amount){
          invoicePaymentsObject[invoices[invoiceIndex]._id] = {
            paid_amount: roundAmount(remaining_amount),
            due_amount: roundAmount(invoices[invoiceIndex].net_amount) - roundAmount(remaining_amount)
          }
          remaining_amount=0;
        }else{
          invoicePaymentsObject[invoices[invoiceIndex]._id] = {
            paid_amount: roundAmount(invoices[invoiceIndex].net_amount),
            due_amount: 0
          }
          remaining_amount -= invoices[invoiceIndex].net_amount;
        }
      }
      invoiceIndex++;
    }
    //console.log(invoices[invoices.length-1].ksa_einvoice?invoices[invoices.length-1].ksa_einvoice:"none");
    //console.log(invoicePaymentsObject);
    setInvoicePayments(invoicePaymentsObject);
   
  }, [invoices, payments, refunds])
  
  const issueStudentRefund = (amount, reason, method) =>{
    Api.issueStudentRefund(student,amount,reason,method).then((res)=>{
      setIssueRefundVisible(false);
      Api.getStudentRefunds(student).then((refunds)=>{
        setRefunds(refunds);
        updateStudentAccounts(student._id);
      });
    }).catch((err)=>{
      setRefundError("Cannot refund amount because the student balance is 0 or negative. Refunds can only be issued on positive student balance");
    })
  }

  const voidInvoice = (invoice) => {
    Api.voidStudentInvoice(invoice).then((res)=>{
        updateStudentAccounts(student._id);
    }).catch((e)=>{
      console.log(e);
    })
  }

  return (
      <Fragment>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
          <IconButton
            aria-label="Grn"
            onClick={() => toggleStudent()}>
            <CloseIcon />
          </IconButton>
          </Grid>
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Student Accounts
            </Typography>
          </Grid>
         <Grid item xs={6}>
         </Grid>
        </Grid>

        <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Grid container alignItems="center" justify="center">
            <Grid item xs="6">
              <Typography variant="h5" component="div">
                {student.name}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="primary">
                {student.demographics.nationalid}
              </Typography>
            </Grid>
            <Grid item xs="6">
              <Typography variant="h5" component="div">
                Balance: {student.accounts["Net Student Balance"]}
              </Typography>
            </Grid>
            
          </Grid>
          
          
        </CardContent>
        </Card>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Invoices" {...a11yProps(0)} />
            <Tab label="Payments" {...a11yProps(1)} />
            <Tab label="Refunds" {...a11yProps(2)} />
            <Tab label="Statement" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <StudentInvoicesView voidInvoice={voidInvoice} invoices={invoices}  invoicePayments={invoicePayments} student={student}/>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <StudentPaymentsView 
          student={student} 
          payments={payments}  />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <StudentRefundsView refunds={refunds} 
          student={student} 
          setIssueRefundVisible={setIssueRefundVisible} 
          issueRefundVisible = {issueRefundVisible}
          issueStudentRefund = {issueStudentRefund} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <StudentStatementView refunds={refunds} invoices={invoices} payments={payments} creditNotes={creditNotes} />
        </TabPanel>
      </Fragment>
    );
}