import { Fragment, useCallback, useEffect, useReducer, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Api } from "../../services/Api";
import Typography from "@material-ui/core/Typography";
import { 
  IconButton, 
  TextField, 
  Button, 
  Container, 
  MenuItem,
  Select,
  InputLabel,
  Switch, Chip, FormControl, FormControlLabel, InputAdornment, TableContainer } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add"

import FloatingActionButton from "../../screens/FloatingActionButton";

import Checkbox from "@material-ui/core/Checkbox";
import PaymentIcon from "@material-ui/icons/Payment";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import DoneIcon from "@material-ui/icons/Done"
import SearchIcon from "@material-ui/icons/Search"
import CloseIcon from '@material-ui/icons/Close';
import { Payment } from "@material-ui/icons";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import ChargesView from './Charges';
import StudentView from './Student';
import { update } from "lodash";

const StudentBillsView = (props) =>{
  const student = props.student;
  const weightedTuitionPayments = props.weightedTuitionPayments;
  const currentLevelFees = props.currentLevelFees;
  
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <b>Bill</b>
          </TableCell>
          <TableCell>
            <b>Fee Amount</b>
          </TableCell>
          <TableCell>
            <b>VAT Amount</b>
          </TableCell>
          <TableCell>
            <b>Invoices</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {weightedTuitionPayments?.map((obj, key)=>(
          <TableRow key={key}>
            <TableCell>
              {obj.name}
            </TableCell>
            <TableCell>
              {obj.weightRatio * currentLevelFees.fee_amount}
            </TableCell>
            <TableCell>
              {obj.weightRatio * currentLevelFees.fee_amount * currentLevelFees.default_vat_rate}
            </TableCell>
            <TableCell>
            <TextField
              defaultValue={1}
              id="Invoices"
              //label="Invoices"
              variant="outlined"
              fullWidth
              helperText={""}
              type="number"
              max={4}
            />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
} 

const ReceivePaymentView = (props) =>{
  const defaultAmount = 0.01;
  const [amount, setAmount] = useState(defaultAmount);

  return(
    <Fragment>
      <TextField
        id="outlined-basic"
        variant="outlined"
        label="Amount Received"
        defaultValue={defaultAmount}
        onChange={(e)=>setAmount(parseFloat(e.target.value.trim()))}
        onInput={(e) => {          
          if(!isNaN(e.target.value) && parseFloat(e.target.value)<0) e.target.value=defaultAmount;
          if(isNaN(e.target.value)) e.target.value=defaultAmount;
        }}
      ></TextField>
      <IconButton
       title="Confirm Receive Payment"
       onClick={()=>props.receiveStudentPayment(props.student._id, amount)}>
        <DoneIcon />
      </IconButton>
      <IconButton
       onClick={()=>props.toggleReceivePayment(props.student._id)}>
        <CloseIcon />
      </IconButton>
    </Fragment>
  )
}

const PostInvoicesView  = (props) =>{
  const selectedStudents = props.getSelectedStudents();
  const currentCharges = props.currentCharges;
  const setCurrentCharges = props.setCurrentCharges;
  const currentYear = props.currentYear;
  const [selectedCharges, setSelectedCharges] = useState([]);
  const [inventoryAvailableItems, setInventoryAvailableItems] = useState({});

  const deselectAllCharges = props.deselectAllCharges;

  useEffect(() => {
    currentCharges.map((charge, key)=>{
      if(charge._inventoryproductid){
        Api.getAvailableItemsForSale(charge._inventoryproductid).then((items)=>{
          setInventoryAvailableItems((prev)=>{
            return {
              ...prev,
              [charge._inventoryproductid]: items
            }
          });
        }).catch((e)=>console.log(e));
      }
    });
  }, []);

  function toggleCharge (e, charge, key){
    let newCharge = {...charge, selected:e.target.checked?true:false};
    let newCurrCharges = [...currentCharges];
    newCurrCharges[key] = newCharge;
    setCurrentCharges(newCurrCharges);
    setSelectedCharges(newCurrCharges.filter((c)=>c.selected===true));
  }

  

  function postStudentInvoices(){
    let tempCharges = [...selectedCharges];
    let students = [];
    let availableInvItems = {...inventoryAvailableItems};
    selectedStudents.map(([key, value])=>{
      let studentObject = {
        _id: value.student._id,
        charges: []
      };
      students.push(studentObject);
    });
    tempCharges.map((charge, ckey)=>{
      if(charge._inventoryproductid){
        students.map((student, skey)=>{
          let next = availableInvItems[charge._inventoryproductid].pop();
          let newCharge = {...charge, item: next};
          student.charges.push(newCharge);
        })
      }else{
        students.map((student, skey)=>{
          let newCharge = {...charge};
          student.charges.push(newCharge);
        })
      }
    });
    Api.postStudentInvoices(students, currentYear).then((res)=>{
      deselectAllCharges();
      selectedStudents.map(([key, value])=>{
        props.updateStudentAccounts(value.student._id);
        props.deselectStudent(value.student._id);
      });
      props.togglePostInvoices();
    }).catch((e)=>console.log(e));
  }

  function getTotalChargesPerStudent(){
    let total = 0;
    selectedCharges.map((charge, key)=>{
      total = total + charge.amount + charge.vat_amount;
    });
    return total;
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item>
          Charges Per Student: {getTotalChargesPerStudent()}  Charges for All Students: {getTotalChargesPerStudent()*selectedStudents.length}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
      {selectedStudents?.map(([key, value])=>(
        <Grid item>
          {value.student.name}
        </Grid>
      ))}
      </Grid>

      <Table>
      <TableHead>
        <TableRow>
          <TableCell>
          </TableCell>
          <TableCell><b>Charge Name</b></TableCell>
          <TableCell><b>Charge Amount</b></TableCell>
          <TableCell><b>Charge VAT Amount</b></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {currentCharges.map((charge, key)=>(
          <TableRow key={key}>
            <TableCell>
            <Checkbox
                //checked={charge.selected}
                disabled={charge._inventoryproductid
                  &&inventoryAvailableItems[charge._inventoryproductid]
                  &&(inventoryAvailableItems[charge._inventoryproductid].length===0
                  ||inventoryAvailableItems[charge._inventoryproductid].length<selectedStudents.length)
                  }
                onChange={(e)=>toggleCharge(e,charge, key)}
                inputProps={{ 'aria-label': 'Select Charge' }}
            />
            {charge._inventoryproductid&&
                inventoryAvailableItems[charge._inventoryproductid]?.length + " Available"
            }
            {/* {charge._inventoryproductid&&!inventoryAvailableItems[charge._inventoryproductid]&&
                "Loading Availability" 
            } */}
            {!charge._inventoryproductid&& "Fee"}
            </TableCell>
            <TableCell>
              {charge.name}
              
            </TableCell>
            <TableCell>
              {charge.amount}
            </TableCell>
            <TableCell>
              {charge.vat_amount}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      </Table>
      <Grid container spacing={3} style={{marginTop:10}}
        direction="row"
        justify="flex-end"
        alignItems ="center">
          <Grid item>
            <Button onClick={(e)=>props.togglePostInvoices()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button 
            variant="contained" 
            onClick={(e)=>postStudentInvoices()}
            color="primary"
            disabled={selectedStudents.length===0||selectedCharges.length===0}
            >
              Post Invoices {selectedStudents.length>0&&'('+selectedStudents.length+')'}
            </Button>
          </Grid>

      </Grid>
    </Fragment>
  )

}

export default function Fees() {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const [enrollments, setEnrollments] = useState([]);
  const [studentsObject, setStudentsObject] = useState({});
  const [currentLevelFees, setCurrentLevelFees] = useState([]);
  const [weightedTuitionPayments, setWeightedTuitionPayments] = useState([]);
  const [selectedStudentBills, setSelectedStudentBills] = useState(null);

  const [currentLevelId, setCurrentLevelId] = useState(null);
  const [levelFullPath, setLevelFullPath] = useState('');
  const [levelSchoolName, setLevelSchoolName] = useState('');
  const [currentYear, setCurrentYear] = useState("2021");
  const [currentCharges, setCurrentCharges] = useState([]);
  
  const [levels, setLevels] = useState([]);

  const [receivePaymentVisible, setReceivePaymentVisible] = useState({});
  const [postInvoicesVisible, setPostInvoicesVisible] = useState(false);
  
  const [manageChargesVisible, setManageChargesVisible] = useState(false);

  const [selectedStudent, setSelectedStudent] = useState(null);
  const toggleStudent = (student) => {
    setSelectedStudent(student);
  }

  const toggleStudentBills = (student) =>{
    setSelectedStudentBills(student);
  }
  
  const years = ["2021","2022","2023"];

  function setCurrentLevelPath(structs){
    let path = '';
    structs&&structs.map((struct, index)=>{
      path+=struct.name;
      ((index+1)!==structs.length)&&(path+="/");
    });
    setLevelFullPath(path);
  }

  function getSchoolName(){
    let foundIndex = levels?.findIndex(l=>l._id===currentLevelId);
    let level = levels[foundIndex]?levels[foundIndex]:null;
    console.log(level);
    return level&&level._schoolid?level._schoolid.name:'';
  }

  useEffect(() => {
    Promise.all([
      Api.getEnrollmentsBilling(currentLevelId, currentYear),
    ]).then(([billing]) => {
      //reset students object
      setPostInvoicesVisible(false);
      setStudentsObject({});
      setLevels(billing.allLevels);
      setCurrentCharges(getLevelCharges());
      setEnrollments(billing.enrollmentsInLevel?billing.enrollmentsInLevel:[]);
      setCurrentLevelFees(billing.levelFees?billing.levelFees:[]);
      setCurrentLevelPath(billing.levelStructures?billing.levelStructures.reverse():[]);
      setLevelSchoolName(getSchoolName());
      billing.enrollmentsInLevel?.map((enrollment, key)=>{
        setStudentsObject(old=>({...old, [enrollment._studentid._id]: {
          student: enrollment._studentid,
          selected: false,
          accounts: enrollment._studentid.accounts,
          enrollments: old[enrollment._studentid._id]&&
                       old[enrollment._studentid._id].enrollments?
                       [...old[enrollment._studentid._id].enrollments, enrollment]:
                       [enrollment]
        }}));
      });
      setWeightedTuitionPayments(billing.weightedTutionPayments);
      
    }).catch((e)=>{
      console.log(e);
    });
  }, [currentLevelId, currentYear]);

  const toggleReceivePayment = (_studentid) =>{
    setReceivePaymentVisible((prev)=>{
      return {
        ...prev,
        [_studentid]: !receivePaymentVisible[_studentid]
      }
    });
  }

  function getLevelCharges(){
    let levelIndex = levels.findIndex(level=>level._id===currentLevelId);
    return levelIndex!==-1?levels[levelIndex].fee_charges:[]
  }

  function togglePostInvoices(){
    deselectAllCharges();
    setPostInvoicesVisible((prev)=>{
      return !prev
    });
  }

  function toggleManageCharges(){
    setManageChargesVisible((prev)=>{
      return !prev
    });
  }

  const receiveStudentPayment = (_studentid, amount) =>{
    Api.receiveStudentPayment(_studentid, amount).then((res)=>{
      toggleReceivePayment(_studentid);
      updateStudentAccounts(_studentid);
    }).catch((e)=>console.log(e));
  }

  function updateStudentAccounts(_studentid){
    Api.getStudentAccounts(_studentid).then((accounts)=>{
      setStudentsObject(old=>({...old, [_studentid]: {
        student: {...old[_studentid].student, accounts: accounts},
        selected: old[_studentid].selected,
        accounts: accounts,
        enrollments: old[_studentid].enrollments
      }}));
      //update selected student accounts
      if(selectedStudent && (_studentid === selectedStudent._id)){
        setSelectedStudent(old=>({...old, accounts:accounts}));
      }
    }).catch((e)=>console.log(e));
  }

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      Object.entries(studentsObject).map(([key, value])=>{
        selectStudent(value.student._id);
      });
    }else{
      Object.entries(studentsObject).map(([key, value])=>{
        deselectStudent(value.student._id);
      });
    }
  }

  const handleSelectChange = (event, _studentid) => {
    if (event.target.checked)
      selectStudent(_studentid);
    else
      deselectStudent(_studentid);
  }

  function selectStudent(_studentid){
    setStudentsObject(old=>({...old, [_studentid]: {
      student: old[_studentid].student,
      selected: true,
      accounts: old[_studentid].accounts,
      enrollments: old[_studentid].enrollments
    }}));
  }

  function deselectStudent(_studentid){
    setStudentsObject(old=>({...old, [_studentid]: {
      student: old[_studentid].student,
      selected: false,
      accounts: old[_studentid].accounts,
      enrollments: old[_studentid].enrollments
    }}));
  }

  function getSelectedStudents(){
    return Object.entries(studentsObject).filter(([key, value]) => value.selected===true);
  }

  function deselectAllCharges() {
    let newCurrCharges = [...currentCharges];
    for(let i=0; i<newCurrCharges.length; i++){
      newCurrCharges[i].selected = false;
    }
    setCurrentCharges(newCurrCharges);
  }
  
  return (
    <Container maxWidth="lg">
        {manageChargesVisible?
        
        <ChargesView 
        currentLevelId={currentLevelId}
        toggleManageCharges={toggleManageCharges}
        setCurrentCharges={setCurrentCharges} 
        />
        :
        selectedStudent?
        <StudentView
        toggleStudent={toggleStudent}
        selectedStudent={selectedStudent}
        updateStudentAccounts={updateStudentAccounts}

        />
        :
        <Fragment>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Student Billing{levelFullPath!==''&&' - '+levelFullPath} {levelSchoolName!==''&&' - '+levelSchoolName}
            </Typography>
          </Grid>
         <Grid item xs={6}>
         </Grid>
        </Grid>
        
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={6}>
          <FormControl style={{width: '100%'}}>
            <InputLabel id="levelLabel">Level</InputLabel>
            <Select
              name="level"
              id="level"
              labelId="levelLabel"
              fullWidth
              displayEmpty
              value={currentLevelId||"Unenrolled"}
              onChange={(e)=>setCurrentLevelId(e.target.value)}
            >
              <MenuItem value={"Unenrolled"}>
                Unenrolled
              </MenuItem>
              {levels.map((l, index) => {
                return (
                  <MenuItem key={index} value={l._id} style={{color:l.discontinued?'#ff0000':'#000000'}}>
                    {/* {l&&l._schoolid&&(l._schoolid.name+" / ")} */}
                    {/* ({l.ancestors_text}) */} {l.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          </Grid>

          <Grid item xs={6}>
          <FormControl style={{width: '100%'}}>
          <InputLabel id="yearLabel">Year</InputLabel>
            <Select
              name="year"
              id="year"
              labelId="yearLabel"
              fullWidth
              displayEmpty
              defaultValue={"2021"}
              onChange={(e)=>setCurrentYear(e.target.value)}
            >
              
              {years.map((y, index) => {
                return (
                  <MenuItem value={y} key={index}>
                    {y}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          </Grid>

        </Grid>
         <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item xs={6}>{Object.keys(studentsObject).length} found</Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
            <Grid item xs={6}>
            <Button 
              variant="outlined" 
              color="primary"
              onClick={(e)=>togglePostInvoices()}
              disabled={getSelectedStudents().length>0?false:true}>
                Post Invoices {getSelectedStudents().length>0&&'('+getSelectedStudents().length+')'}
            </Button>
            </Grid>
            <Grid item xs={6}>
            <Button 
              variant="outlined" 
              color="primary"
              onClick={(e)=>setManageChargesVisible(true)} > Manage Level Charges
            </Button>
            </Grid>
        </Grid>

        {postInvoicesVisible&& 
        <PostInvoicesView 
        getSelectedStudents={getSelectedStudents}
        currentYear={currentYear} 
        togglePostInvoices={togglePostInvoices} 
        deselectStudent={deselectStudent}
        updateStudentAccounts={updateStudentAccounts}
        currentCharges={currentCharges}
        setCurrentCharges={setCurrentCharges} 
        deselectAllCharges={deselectAllCharges}
        /> }
       
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
              <Checkbox
                indeterminate={getSelectedStudents().length > 0 && getSelectedStudents().length < Object.keys(studentsObject).length}
                checked={getSelectedStudents().length > 0 && getSelectedStudents().length === Object.keys(studentsObject).length}
                onChange={(e)=>handleSelectAllChange(e)}
                inputProps={{ 'aria-label': 'Select all students' }}
              />
              </TableCell>
              <TableCell>
                <b>Student Name</b>
              </TableCell>
              <TableCell>
                <b>Net Student Balance</b>
              </TableCell>
              <TableCell>
                <b>Payments</b>
              </TableCell>
              <TableCell>
                <b>Revenues</b>
              </TableCell>
              <TableCell>
                <b>VAT Liability</b>
              </TableCell>
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentsObject &&
              Object.entries(studentsObject).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>
                  <Checkbox
                    checked={value.selected}
                    onChange={(e)=>handleSelectChange(e, value.student._id)}
                    inputProps={{ 'aria-label': 'Select Student' }}
                  />
                  </TableCell>
                  <TableCell>
                    <Button onClick={()=>toggleStudent(value.student)}>{value.student.name}</Button>
                  </TableCell>
                 {/*  <TableCell>{value.enrollments.length}</TableCell> */}
                  <TableCell>
                    {value.accounts&&value.accounts["Net Student Balance"] || 0}
                  </TableCell>
                  <TableCell>
                    {value.accounts&&value.accounts['Student Payables'] || 0}
                  </TableCell>
                  <TableCell>
                    {value.accounts&&value.accounts['Revenue from Student'] || 0}
                    {/* <Tooltip title="Post an Invoice" aria-label="post">
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={()=>earnRevenue(value.student._id, 1000)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip> */}
                  </TableCell>
                  <TableCell>
                    {value.accounts&&value.accounts['VAT Payable on Revenue'] || 0}
                  </TableCell>
                  <TableCell>
                  {receivePaymentVisible&&!receivePaymentVisible[value.student._id]&&
                  <IconButton
                  title="Receive Payment"
                  aria-label="Receive Payment"
                  color="primary"
                  onClick={() => toggleReceivePayment(value.student._id)}
                  >
                  <PaymentIcon />
                  </IconButton>}
                  {receivePaymentVisible&&receivePaymentVisible[value.student._id]&& 
                    <ReceivePaymentView 
                      student={value.student}
                      accounts={value.accounts} 
                      toggleReceivePayment={toggleReceivePayment}
                      receiveStudentPayment={receiveStudentPayment}
                    />}
                  </TableCell>
                  {/* <TableCell>
                    {weightedTuitionPayments?.length} Bills
                    <Tooltip title="Edit Bills" aria-label="edit">
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={()=>toggleStudentBills(value.student)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell> */}

                </TableRow>
              ))}
          </TableBody>
        </Table>
        </Fragment>}

    {selectedStudentBills&& 
    <StudentBillsView student={selectedStudentBills} weightedTuitionPayments={weightedTuitionPayments} currentLevelFees={currentLevelFees[0]} 
    /> 
    }

</Container>
  );
}