import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Api } from "../../../services/Api";
import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  FormControl,
  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(0),
  },
  checkboxGrid: {
    border: `${fade(theme.palette.text.primary, 0.2)} 1px solid`,
    marginTop: "5px",
    padding: "10px",
  },
}));

const ViewProduct = (props) => {
  const classes = useStyles();
  const productId = props.id;

  const initialState = {
    entity: {
      _id: -1,
      category: "",
      sub_category: "",
      short_name: "",
      meta: {
      },
      default_dep_years: 0,
      default_salvage_value:0,
      purchase_tax: 0,
      description: "",
    },
    metas: [],
    categories: [],
    loading: true,
    error: undefined,
  };

  const [state, setState] = useState(initialState);
  useEffect(()=>{
    Promise.all([
      Api.getCapexProductById(productId),
      Api.getCapexCategories(),
    ]).then(([product, categories]) => {
      let customFields = []
      console.log(product);
      product.meta&&Object.keys(product.meta).forEach((m, idex) => {
        const obj = {};
        obj["key"] = m;
        obj["value"] = product.meta[m];
        obj["isEditMode"] = false;
        obj.id = (Date.now() + Math.random()).toString();
        customFields.push(obj);
      });
      setState({
        entity: product,
        categories: categories,
        loading: false,
        error: undefined,
        metas: customFields,
      });

    });
  }, [productId]);

  const categoryName = (_categoryid) =>{
    let name = 'Unassigned';
    let found = state.categories.findIndex(c=>c._id===_categoryid);
    if (found>=0) name=state.categories[found].name;
    return name;
  }
 
  const { entity, loading } = state;

  return (
    <div style={{ padding: "15px" }}>
      {loading && <CircularProgress />}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Product Detail
      </Typography>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              className={classes.margin}
            >
              <TextField
                name="short_name"
                label="Short Name"
                variant="outlined"
                fullWidth
                disabled
                value={entity.short_name}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              className={classes.margin}
            >
              <TextField
                name="Category"
                label="Category"
                variant="outlined"
                fullWidth
                disabled
                value={categoryName(entity._capexproductcategoryid)}
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl
              fullWidth
              className={classes.margin}
            >
              <TextField
                name="SalvageValue"
                variant="outlined"
                fullWidth
                disabled
                value={entity.default_salvage_value}
                label="Salvage Value"
              ></TextField>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl
              fullWidth
              className={classes.margin}
            >
              <TextField
                name="dep_years"
                label="Depreciation Years"
                variant="outlined"
                value={entity.default_dep_years}
                disabled
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl
              fullWidth
              className={classes.margin}
            >
              <TextField
                name="purchase_tax"
                label="Purchase Tax (%)"
                variant="outlined"
                fullWidth
                disabled
                value={entity.purchase_tax}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              className={classes.margin}
            >
              {/* <TextField
                multiline
                name="description"
                label="Description"
                variant="outlined"
                fullWidth
                rows={3}
                disabled
                value={entity.description}
              ></TextField> */}
              {entity.description}
            </FormControl>
          </Grid>
        </Grid>
        <MetaRow {...{ meta: state.metas }} />
      
      </form>
    </div>
  );
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
}));

function MetaRow({ meta }) {
  const classes = useStyles1();
  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Field</TableCell>
            <TableCell align="left">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meta.map((row, idx) => (
            <TableRow key={row.id}>
              <TableCell align="left" className={classes.tableCell}>
                <span>{row.key}</span>
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                <span>{row.value}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default ViewProduct;