import { useCallback, useEffect, useReducer, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { IconButton, TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/FileCopy";

import Tooltip from "@material-ui/core/Tooltip";
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Select, MenuItem } from "@material-ui/core";
import { 
  Button,
  Container,
  Chip,
} from "@material-ui/core";
import FloatingActionButton from "../../../screens/FloatingActionButton";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Api } from "../../../services/Api";

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from '@material-ui/core/InputAdornment';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import CategoriesView from './Categories';

import Moment from 'moment';
import moment from "moment";

export default function CapExListView(props) {
  const newName = "CapEx Products";
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [capexProducts, setCapexProducts] = useState([]);
  const [searchedCapexProducts, setSearchedCapexProducts] = useState([]);
  const [currentShown, setCurrentShown] = useState(10);
  const showAtaTime = 10;
  const [capexItemCount, setCapexItemCount] = useState({});

  const [searchState, setSearchState] = useState({
    phrase: "",
    _capexproductcategoryid: "all",
  });

  const [manageCategoriesVisible, setManageCategoriesVisible] = useState(false);

  function toggleManageCategories(){
    setManageCategoriesVisible((prev)=>{
      return !prev
    });
  }
  useEffect(() => {
    Promise.all([
      Api.getCapexProducts(),
      Api.getCapexCategories(),
    ]).then(([products, categories]) => {
      setCapexProducts(products.reverse());
      setSearchedCapexProducts(products);
      setCategories(categories);
      products.map((val, key)=>{
        Api.getCapexItemCount(val._id).then((res)=>{
          setCapexItemCount(old=>({...old, [val._id]: res.count}))
        }).catch((e)=>console.log(e));
      })
    }).catch((e)=>{
      console.log(e);
    });
  }, []);

  useEffect(() => {
    let result = capexProducts.filter(function (el) {
      let searchPhraseCondition = new RegExp(searchState.phrase, "i");
      let filters = [];
      if(searchState.phrase !=='')
        filters.push(searchPhraseCondition.test(el.short_name))
      if (searchState._capexproductcategoryid !== 'all'){
        if(searchState._capexproductcategoryid === 'unassigned')
          filters.push(!el._capexproductcategoryid||el._capexproductcategoryid===null)
        else
          filters.push(el._capexproductcategoryid===searchState._capexproductcategoryid)
      }
      let filter = true;
      filters.forEach((f)=>{
        filter = filter && f 
      });
      return filter; 
    });
    setSearchedCapexProducts(result);
  }, [searchState, capexProducts]);

  const categoryName = (_categoryid) =>{
    let name = 'Unassigned';
    let found = categories.findIndex(c=>c._id===_categoryid);
    if (found>=0) name=categories[found].name;
    return name;
  }

  const handleNewClick = (e) => {
    history.push(`${url}/add`, {
      ...props.state,
    });
  };

  const handlePhraseChange = useCallback((e) => {
    setSearchState((prev) => {
      return {
        ...prev,
        phrase: e.target.value,
      };
    });
  }, []);

  const handleCategoryChange = useCallback((e) => {
    setSearchState((prev) => {
      return {
        ...prev,
        _capexproductcategoryid: e.target.value,
      };
    });
  }, []);

  const showMore = useCallback(()=>{
    setCurrentShown((prev) => {
      return prev + showAtaTime
    });
  },[]);

  const productCount = (_capexproductid) => {
    return capexItemCount[_capexproductid]
  }

  const testAccumulatedDep = (_capexproductid) => {
    const receivedDate = "2022-01-03";
    const daysSinceReceived = moment(new Date().toISOString()).diff(receivedDate, 'days');
    const salvage_value = 100;
    const depYears = 10;
    const purchase_value = 120000;

    const depPerYear = (purchase_value-salvage_value)/depYears;

    const depPerDay = depPerYear/365;

    return 'DaysSince ' + daysSinceReceived + ' ' + (depPerDay * daysSinceReceived);

  }
  
  return (
    <Container maxWidth="xl">
      {manageCategoriesVisible?
        
        <CategoriesView 
        toggleManageCategories={toggleManageCategories}
        setCategories={setCategories} 
        /> :
      <>
        <Grid
          container
          direction="row"
          style={{ margin: "0px" }}
          xs={12}
        >
          <Grid container xs={6} spacing={3} alignItems="center" justify="flex-start">
            <Grid item>
              <Typography component="h2" variant="h6" color="primary">
                {newName}
              </Typography>
            </Grid>
            <Grid item>
              <FloatingActionButton
                title="Create new product"
                variant="contained"
                color="primary"
                handleClick={handleNewClick}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} justify="flex-end">
            <Grid item>
            <Button 
                variant="outlined" 
                color="primary"
                onClick={(e)=>setManageCategoriesVisible(true)} > Manage Categories
              </Button>
            </Grid>
          </Grid>
          
        </Grid>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={6}>
            <FormControl style={{width: '100%'}}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                label="Search Product Name"
                value={searchState.phrase}
                onChange={handlePhraseChange}
              >
  
            </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
          <FormControl style={{width: '100%'}}>
            <InputLabel id="categoryLabel">Category</InputLabel>
            <Select
              name="category"
              id="category"
              labelId="categoryLabel"
              fullWidth
              value={searchState._capexproductcategoryid}
              displayEmpty
              onChange={handleCategoryChange}
            >
              <MenuItem value="all">
                All
              </MenuItem>
              <MenuItem value="unassigned">
                Unassigned
              </MenuItem>
              {categories.map((c, index) => {
                return (
                  c.active&&
                  <MenuItem value={c._id} key={index}>
                    {c.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          </Grid>
        <Grid item xs={6}>{searchedCapexProducts.length} found</Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Category</b>
              </TableCell>
              <TableCell>
                <b>Count</b>
              </TableCell>
              <TableCell>
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedCapexProducts &&
              searchedCapexProducts.map((obj, index) => (
                (index<currentShown) && 
                  <TableRow key={index}>
                  <TableCell>{obj.short_name}</TableCell>
                  <TableCell>{categoryName(obj._capexproductcategoryid)}</TableCell>
                  <TableCell>{productCount(obj._id)}</TableCell>
                  <TableCell>
                  <Tooltip title="Clone Product" aria-label="Clone Product">
                      <IconButton
                        variant="contained"
                        color="primary"
                        data-id={obj._id}
                        onClick={(e) => history.push(`${url}/clone/${obj._id}`)}
                      >
                        <CopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Edit Product"
                      aria-label="Edit Product"
                    >
                      <IconButton
                        variant="contained"
                        color="primary"
                        data-id={obj._id}
                        onClick={(e) =>
                          history.push(`${url}/edit/${obj._id}`, {
                            ...props.state,
                          })
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {(currentShown < searchedCapexProducts.length)&&
                 <TableRow>
                 <TableCell colSpan={3}>
                 <Tooltip
                      title="Show More Products..."
                      aria-label="Show More"
                    >
                 <IconButton
                        variant="contained"
                        color="primary"
                        onClick={showMore}
                      >
                        <MoreHorizIcon />
                </IconButton>
                </Tooltip>
                  
                 </TableCell>
                 </TableRow>
              }
          </TableBody>
        </Table>
      </>}
    </Container>
  );
}