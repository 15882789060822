import GLAccounts from "./Accounts";
import GLSnapshots from "./Snapshots"
import { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";

function Index() {
  const { params, url } = useRouteMatch();
  return <Switch>
    <Route path={`${url}/`} exact render={(props) => <GLAccounts {...params} />} />
    <Route path={`${url}/snapshots`} exact render={(props) => <GLSnapshots {...params} />} />
    {/* <Route
      path={[`${url}/add`, `${url}/edit/:id`]}
      render={(props) => <ManageEmployee {...params} />}
    ></Route> */}
    <Route path="*" render={(props) => <Redirect to={`/404`} />}></Route>
  </Switch>;
}

export default Index;
