import {Fragment, useCallback, useEffect, useReducer, useState } from "react";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Api } from "../../services/Api";
import Typography from "@material-ui/core/Typography";
import { 
  Box,
  IconButton, 
  TextField, 
  Button,
  Container, 
  MenuItem,
  Select,
  InputLabel,
  Switch, 
  Chip, 
  FormControl, 
  FormControlLabel, 
  InputAdornment, 
  TableContainer,
  Tabs,
  Tab,
  Card,
  CardContent
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";

import FloatingActionButton from "../../screens/FloatingActionButton";
import ReceiptIcon from '@material-ui/icons/Receipt';
import Checkbox from "@material-ui/core/Checkbox";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SearchIcon from "@material-ui/icons/Search"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PurchaseOrderGrn from "../PurchaseOrder/Grn";
import QRCode from 'qrcode.react';

import ReactDOMServer from 'react-dom/server';
import FileDownload from 'js-file-download';
function roundAmount(amount){
  let frac = 100;
  return Math.round((amount+Number.EPSILON)*frac) / frac;
}

function getMonthName(month){
  return month===0?"Jan":month===1?"Feb":month===2?"Mar":month===3?"Apr":month===4?"May":month===5?"Jun":month===6?"Jul":month===7?"Aug":month===8?"Sep":month===9?"Oct":month===10?"Nov":month===11?"Dec":"";
}

const SupplierInvoicesView = (props) =>{
  const invoices = props.invoices;
  const invoicePayments = props.invoicePayments;
  const voidInvoice = props.voidInvoice;
  //const setSelectedProduct = props.setSelectedProduct;
  //const updateNewCharge = props.updateNewCharge;
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  //const [selectedInvoicePayment, setSelectedInvoicePayment] = useState(null);
  const supplier = props.supplier;

  function goToPurchaseOrder(_purchaseorderid){
    window.open("/purchaseorder/view/"+_purchaseorderid, '_blank', 'noopener,noreferrer');
  }

  return (/* selectedInvoice?
        <StudentOfficialInvoiceView 
        student={student} 
        invoice={selectedInvoice} 
        invoicePayment={selectedInvoicePayment}
        setSelectedInvoice={setSelectedInvoice} />
      : */
       <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Issue Date</b>
              </TableCell>
              <TableCell>
                <b>Expense Amount</b>
              </TableCell>
              <TableCell>
                <b>Purchase Amount</b>
              </TableCell>
              <TableCell>
                <b>VAT Amount</b>
              </TableCell>
              <TableCell>
                <b>Net Amount</b>
              </TableCell>
              <TableCell>
                <b>Paid Amount</b>
              </TableCell>
              <TableCell>
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices&&invoices.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>
                  <Button style={obj.voided?{color: '#ff1111'}:{color: '#000000'}}
                  onClick={() => {setSelectedInvoice(obj);
                  /* setSelectedInvoicePayment(invoicePayments[obj._id]||null) */}}>
                    {obj.issuedOn }
                  </Button>
                    </TableCell>
                    <TableCell>
                    {(!obj._purchaseorderid&&obj.cost_amount) || 0}
                  </TableCell>
                  <TableCell>
                    {(obj._purchaseorderid&&obj.cost_amount) || 0}
                  </TableCell>
                  <TableCell>
                    {obj.vat_amount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.net_amount || 0}
                  </TableCell>
                  <TableCell>
                    {invoicePayments[obj._id]&&invoicePayments[obj._id].paid_amount || 0}
                  </TableCell>
                  <TableCell>
                    {!obj.voided?<Button onClick={()=> voidInvoice(obj)}>Void</Button>:<Typography style={obj.voided?{color: '#ff1111'}:{color: '#000000'}}>VOIDED</Typography>}
                    {obj._purchaseorderid&&
                      <Button onClick={()=>goToPurchaseOrder(obj._purchaseorderid)}>PO</Button>
                    }
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
  )
}

const SupplierPaymentsView = (props) =>{
  const payments = props.payments;
  const supplier = props.supplier;
  const [selectedPayment, setSelectedPayment] = useState(null);
  return (
       /* selectedPayment?
        <StudentPaymentReceiptView 
        student={student} 
        payment={selectedPayment} 
        setSelectedPayment={setSelectedPayment} />
       : */
       <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Payment Date</b>
              </TableCell>
              <TableCell>
                <b>Amount</b>
              </TableCell>
              <TableCell>
                <b>Receipt</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments&&payments.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>{obj.paidOn || ''}</TableCell>
                  <TableCell>
                    {obj.paid_amount || 0}
                  </TableCell>
                  <TableCell>
                  <IconButton
                  title="View Receipt"
                  aria-label="View Receipt"
                  color="primary"
                  onClick={() => setSelectedPayment(obj)}
                  >
                  <ReceiptIcon />
                  </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
  )
}

const SupplierRefundsView = (props) =>{
  const refunds = props.refunds;
  const issueRefundVisible = props.issueRefundVisible;
  const setIssueRefundVisible = props.setIssueRefundVisible;
  const issueSupplierRefund = props.issueSupplierRefund;
  const supplier = props.supplier;

  return (
    <Fragment>
     
      {!issueRefundVisible&&
        <IconButton
        title="Issue Refund"
        aria-label="Issue Refund"
        color="primary"
        onClick={() => setIssueRefundVisible(true)}
        >
        <AddIcon />
        </IconButton>
        }

        {issueRefundVisible&& 
          <IssueRefundView 
            setIssueRefundVisible={setIssueRefundVisible}
            issueSupplierRefund={issueSupplierRefund}
          />
          }
          {((supplier.accounts&&supplier.accounts["Net Supplier Balance"]&&
          supplier.accounts["Net Supplier Balance"]>=0)||(!supplier.accounts)||(!supplier.accounts["Net Supplier Balance"]))&&
            <Typography>Suppliers with a positive balance cannot refund you money.</Typography>
          }
         
           <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Refund Date</b>
              </TableCell>
              <TableCell>
                <b>Amount</b>
              </TableCell>
              <TableCell>
                <b>Debit Note</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {refunds&&refunds.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>{obj.refundedOn || ''}</TableCell>
                  <TableCell>
                    {obj.refund_amount || 0}
                  </TableCell>
                  <TableCell>
                    
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

    </Fragment>
      )
}

const IssueRefundView = (props) =>{
  const defaultAmount = 0.01;
  const [amount, setAmount] = useState(defaultAmount);
  const [reason, setReason] = useState("");
  const [method, setMethod] = useState("Cash");

  return(
      <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
        <Grid item> 
        <TextField
        variant="outlined"
        label="Amount to Refund"
        defaultValue={defaultAmount}
        onChange={(e)=>setAmount(parseFloat(e.target.value.trim()))}
        onInput={(e) => {          
          if(!isNaN(e.target.value) && parseFloat(e.target.value)<0) e.target.value=defaultAmount;
          if(isNaN(e.target.value)) e.target.value=defaultAmount;
        }}
      ></TextField>
      </Grid>
      <Grid item> 
      <TextField 
        variant="outlined"
        label="Reason"
        defaultValue={"General Refund"}
        onChange={(e)=>setReason(e.target.value.trim())}
      ></TextField>
      </Grid>
      <Grid item> 
      <IconButton 
       title="Confirm Issue Refund"
       onClick={()=>props.issueSupplierRefund(amount, reason, method)}>
        <DoneIcon />
      </IconButton>
      </Grid>
      <Grid item> 
      <IconButton 
       onClick={()=>props.setIssueRefundVisible(false)}>
        <CloseIcon />
      </IconButton>
      </Grid>
      </Grid>  
      )
}

const SupplierStatementView = (props) =>{
  const refunds = props.refunds;
  const payments = props.payments;
  const invoices = props.invoices;
  const debitNotes = props.debitNotes;

  const [supplierStatementAccounts, setSupplierStatementAccounts] = useState([]);

  useEffect(() => {
    const supplierAccounts = [];
    refunds.map((refund, key)=>{
      let obj = {
        type: "Refund",
        acc_type: "Credit",
        transDate: refund.refundedOn,
        amount: refund.refund_amount
      };
      supplierAccounts.push(obj);
    });
    payments.map((payment, key)=>{
      let obj = {
        type: "Payment",
        acc_type: "Debit",
        transDate: payment.paidOn,
        amount: payment.paid_amount,
      };
      supplierAccounts.push(obj);
    });
    invoices.map((invoice, key)=>{
      let obj = {
        type: "Invoice",
        acc_type: "Credit",
        transDate: invoice.issuedOn,
        amount: invoice.net_amount,
      };
      supplierAccounts.push(obj);
    });

    debitNotes.map((dn, key)=>{
      let obj = {
        type: "Debit Note",
        acc_type: "Debit",
        transDate: dn.voidedOn,
        amount: dn.supplier_payable,
      };
      supplierAccounts.push(obj);
    });

    supplierAccounts.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.transDate) - new Date(b.transDate);
    });

    let carryingBalance = 0;
    supplierAccounts.map((obj, key) => {
      obj.acc_type==="Debit"?carryingBalance-=obj.amount:carryingBalance+=obj.amount;
      obj.carryingBalance = roundAmount(carryingBalance);
    });

    setSupplierStatementAccounts(supplierAccounts);

  }, [refunds, payments, invoices]);
  
  return (
    <Fragment>
      <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell>
                <b>Type</b>
              </TableCell>
              <TableCell>
                <b>Debit</b>
              </TableCell>
              <TableCell>
                <b>Credit</b>
              </TableCell>
              <TableCell>
                <b>Balance</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {supplierStatementAccounts&&supplierStatementAccounts.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>{obj.transDate || ''}</TableCell>
                  <TableCell>{obj.type || ''}</TableCell>
                  <TableCell>
                    {obj.acc_type==="Debit" && obj.amount}
                  </TableCell>
                  <TableCell>
                    {obj.acc_type==="Credit" && obj.amount}
                  </TableCell>
                  <TableCell>
                    {obj.carryingBalance || 0}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

    </Fragment>
      )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function Supplier(props) {
  const [loading, setLoading] = useState(false);
  const [refundError, setRefundError] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [invoicePayments, setInvoicePayments] = useState({});

  const [payments, setPayments] = useState([]);
  const [debitNotes, setDebitNotes] = useState([]);
  const [refunds, setRefunds] = useState([]);
  
  const [issueRefundVisible, setIssueRefundVisible] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const supplier = props.selectedSupplier;
  const toggleSupplier = props.toggleSupplier;
  const updateSupplierAccounts = props.updateSupplierAccounts;
  const updateAllInvoices = props.updateAllInvoices;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  useEffect(() => {
    console.log("effect on supplier");
    supplier&&Promise.all([
      Api.getSupplierInvoices(supplier),
      Api.getSupplierPayments(supplier),
      Api.getSupplierRefunds(supplier)
    ]).then(([invoices, payments, refunds]) => {
      setInvoices(invoices);
      setPayments(payments);
      setRefunds(refunds);
      /* let dns = [];
      invoices.map((pr, key)=>{
        pr.voided&&dns.push(pr);
      });
      setDebitNotes(dns); */
    }).catch((e)=>{
      console.log(e);
    });
  }, [supplier]);

  useEffect(() =>{
    let remaining_amount =0;
    let invoicePaymentsObject = {};
    payments.map((payment, key)=>{
      remaining_amount += payment.paid_amount;
    });
    refunds.map((refund, key)=>{
      remaining_amount -= refund.refund_amount;
    });

    let invoiceIndex = 0;
    while(remaining_amount>0&&invoiceIndex<invoices.length){
      if(!invoices[invoiceIndex].voided){
        if(invoices[invoiceIndex].net_amount>=remaining_amount){
          invoicePaymentsObject[invoices[invoiceIndex]._id] = {
            paid_amount: roundAmount(remaining_amount),
            due_amount: roundAmount(invoices[invoiceIndex].net_amount) - roundAmount(remaining_amount)
          }
          remaining_amount=0;
        }else{
          invoicePaymentsObject[invoices[invoiceIndex]._id] = {
            paid_amount: roundAmount(invoices[invoiceIndex].net_amount),
            due_amount: 0
          }
          remaining_amount -= invoices[invoiceIndex].net_amount;
        }
      }
      invoiceIndex++;
    }
   
    setInvoicePayments(invoicePaymentsObject);
   
  }, [invoices, payments, refunds])
  
  const issueSupplierRefund = (amount, reason, method) =>{
    Api.issueSupplierRefund(supplier,amount,reason,method).then((res)=>{
      setIssueRefundVisible(false);
      Api.getSupplierRefunds(supplier).then((refunds)=>{
        setRefunds(refunds);
        updateSupplierAccounts(supplier._id);
      });
    }).catch((err)=>{
      setRefundError("Cannot refund amount because the supplier balance is 0 or negative. Refunds can only be issued on positive supplier balance");
    })
  }

  const voidInvoice = (invoice) => {
    Api.voidSupplierInvoice(invoice).then((res)=>{
      console.log(res);
      updateSupplierAccounts(supplier._id);
      updateAllInvoices();
    }).catch((e)=>{
      console.log(e);
    });
  }

  return (
      <Fragment>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
          <IconButton
            aria-label="Grn"
            onClick={() => toggleSupplier()}>
            <CloseIcon />
          </IconButton>
          </Grid>
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Supplier Accounts
            </Typography>
          </Grid>
         <Grid item xs={6}>
         </Grid>
        </Grid>

        <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Grid container alignItems="center" justify="center">
            <Grid item xs={6}>
              <Typography variant="h5" component="div">
                {supplier.name}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="primary">
                
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" component="div">
                Balance: {supplier.accounts&&supplier.accounts["Net Supplier Balance"]?
                supplier.accounts["Net Supplier Balance"]:0}
              </Typography>
            </Grid>
            
          </Grid>
          
          
        </CardContent>
        </Card>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Invoices" {...a11yProps(0)} />
            <Tab label="Payments" {...a11yProps(1)} />
            <Tab label="Refunds" {...a11yProps(2)} />
            <Tab label="Statement" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <SupplierInvoicesView invoicePayments={invoicePayments} voidInvoice={voidInvoice} invoices={invoices}  supplier={supplier} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <SupplierPaymentsView 
          student={supplier} 
          payments={payments}  />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <SupplierRefundsView refunds={refunds} 
          supplier={supplier} 
          setIssueRefundVisible={setIssueRefundVisible} 
          issueRefundVisible = {issueRefundVisible}
          issueSupplierRefund = {issueSupplierRefund} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <SupplierStatementView refunds={refunds} invoices={invoices} payments={payments} debitNotes={debitNotes} />
        </TabPanel>
      </Fragment>
    );
}