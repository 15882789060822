import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { DRAWERWIDTH } from "../shared/constants";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, useHistory, useLocation } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";

import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PieChartIcon from '@material-ui/icons/PieChart';
import AttachmentIcon from '@material-ui/icons/Attachment';

import GroupIcon from "@material-ui/icons/Group";
import LockIcon from "@material-ui/icons/Lock";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  notificationsContainer: {
    minWidth: "500px",
    maxWidth: "500px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  notificationsRoot: {
    width: "100%",
    maxWidth: "55ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  nested: {
    paddingLeft: "50px",
  }
}));

const ListItemLink = (props) => {
  const { icon, primary, to, selected, setActiveLoc, styles, state } = props;
  const CustomLink = useMemo(
    () =>
      React.forwardRef((linkProps, ref) => {
        return <Link to={to} ref={ref} {...linkProps} />;
      }),
    [to]
  );
  return (
    <li>
      <ListItem
        className={styles}
        button
        component={CustomLink}
        selected={selected}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};

export default function DrawerMenu({
  classes,
  updateUserInformation,
  open,
  callback,
  currentLanguage,
  getTranslatedText
}) {

  const selfClasses = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const [_open, setOpen] = useState(true);
  const titleRef = React.useRef(false);
/*   const [rtl, setRtl] = useState(false);
 */  useEffect(function () {
    titleRef.current = true;
  }, []);

  /* useEffect(function () {
    currentLanguage==="ar"?setRtl(true):setRtl(false);
  }, [setRtl, currentLanguage]); */

  let isOpen = !open ? false : !titleRef.current ? open : _open;

  const handleClick = () => {
    isOpen = !isOpen;
    console.log(currentLanguage);
    if(!open) {
      callback();
    }
    setOpen(isOpen);
  };
  

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={callback}>
          {currentLanguage==="ar"?<ChevronRightIcon />:<ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <div>
          <ListItemLink
            to="/"
            primary={currentLanguage!=="en"?getTranslatedText("Dashboard")[currentLanguage]:"Dashboard"}
            icon={<DashboardIcon />}
            selected={pathname === "/" ? true : false}
          />
           <ListItemLink
            to="/billing"
            primary={currentLanguage!=="en"?getTranslatedText("Student Billing")[currentLanguage]:"Student Billing"}
            //primary="Student Billing"
            icon={<MoneyIcon />}
            selected={pathname === "/billing" ? true : false}
          />
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <CardTravelIcon />
            </ListItemIcon>
            <ListItemText 
            primary={currentLanguage!=="en"?getTranslatedText("Product Catalogue")[currentLanguage]:"Product Catalogue"} 
            /* primary="Product Catalogue" */ />
            {isOpen ? <ExpandLess style={{paddingLeft: '10px'}} /> : <ExpandMore style={{paddingLeft: '10px'}} />}
          </ListItem>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemLink
                to="/products/capex"
                primary="Capex"
                icon={<EmojiTransportationIcon />}
                styles={selfClasses.nested}
                state="capex_products"
                selected={pathname === "/products/capex" ? true : false}
              />
              <ListItemLink
                to="/products/inventory"
                primary="Inventory"
                icon={<ShoppingCartIcon />}
                styles={selfClasses.nested}
                state="inventory_products"
                selected={pathname === "/products/inventory" ? true : false}
              />
              <ListItemLink
                to="/products/usage"
                primary="Usage"
                icon={<PieChartIcon />}
                styles={selfClasses.nested}
                state="usage_products"
                selected={pathname === "/products/usage" ? true : false}
              />
            </List>
          </Collapse>
          <ListItemLink
            to="/purchaseorder"
            primary={currentLanguage!=="en"?getTranslatedText("Purchase Orders")[currentLanguage]:"Purchase Orders"}
            //primary="Purchase Orders"
            icon={<AttachmentIcon />}
            selected={pathname === "/purchaseorder" ? true : false}
          />
          <ListItemLink
            to="/supplier"
            primary={currentLanguage!=="en"?getTranslatedText("Suppliers")[currentLanguage]:"Suppliers"}
            //primary="Suppliers"
            icon={<PersonIcon />}
            selected={pathname === "/supplier" ? true : false}
          />

          <ListItemLink
            to="/supplier/billing"
            primary={currentLanguage!=="en"?getTranslatedText("Supplier Billing")[currentLanguage]:"Supplier Billing"}
            //primary="Supplier Billing"
            icon={<MoneyIcon />}
            selected={pathname === "/supplier/billing" ? true : false}
          />
          <ListItemLink
            to="/employee"
            primary={currentLanguage!=="en"?getTranslatedText("Employees")[currentLanguage]:"Employees"}
            //primary="Supplier Billing"
            icon={<PersonIcon />}
            selected={pathname === "/employee" ? true : false}
          />
          <ListItemLink
            to="/employee/accounts"
            primary={currentLanguage!=="en"?getTranslatedText("Employees Accounts")[currentLanguage]:"Employee Accounts"}
            //primary="Supplier Billing"
            icon={<MoneyIcon />}
            selected={pathname === "/employee/accounts" ? true : false}
          />
           <ListItemLink
            to="/gl"
            primary={currentLanguage!=="en"?getTranslatedText("General Ledger")[currentLanguage]:"General Ledger"}
            //primary="Supplier Billing"
            icon={<MoneyIcon />}
            selected={pathname === "/gl" ? true : false}
          />
          <ListItem
            className={classes.nested}
            button
            onClick={() => {
              updateUserInformation({});
              history.push("/");
            }}
          >
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </div>
      </List>
    </Drawer>
  );
}
