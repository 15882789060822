import React, { useCallback, useEffect, useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Api } from "../../services/Api";
import {
  Typography,
  Grid,
  Button,
  MenuItem,
  Select,
  CircularProgress,
  FormControl,
  FormControlLabel,
  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Switch,
  ListItem,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import { useHistory, useParams } from "react-router-dom";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import Moment from 'moment';
import ChipInput from 'material-ui-chip-input';
import { AddAPhotoOutlined } from "@material-ui/icons";
import localData from '../../services/localData';
import Autocomplete from '@material-ui/lab/Autocomplete';

/* const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  
})); */

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
  extendedIcon: {
    marginRight: theme.spacing(0),
  },
  checkboxGrid: {
    border: `${fade(theme.palette.text.primary, 0.2)} 1px solid`,
    marginTop: "5px",
    padding: "10px",
  },
}));

const defaultValues = {
  name: "",
  nickname: "",
  position: "",
  tags: [],
  activeEmployee: false,
  activeInstructor: false,
  nationality: null
};

const initialState = {
  tags: [],
  loading: true,
  error: undefined,
};

const ManageEmployee = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const employeeId = parseInt(id);
  const history = useHistory();
  const tIsAdd = isNaN(parseInt(id)) || id <= 0;
  const [state, setState] = useState(initialState);
  const [employee, setEmployee] = useState({});
  const [customSelectKey, setCustomSelectKey] = useState(1);
  const [activeDepartments, setActiveDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);


  useEffect(() => {
    if (id) {
      Promise.all([
        Api.getAllDepartments(),
        Api.getEmployeeById(id),
      ]).then(([departments, res1]) => {
        setEmployee(res1);
        setActiveDepartments(departments);
        let foundDepartemntIndex = departments.findIndex(d=>d._id===res1._departmentid);
        foundDepartemntIndex>-1&&setSelectedDepartment(departments[foundDepartemntIndex]);
        //setSelectedDepartmentId(res1._departmentid);
        const employee = res1;
        const empDOB = employee.dateofbirth&&employee.dateofbirth.split("T")[0]? employee.dateofbirth.split("T")[0]:"";
        const empDOJ = employee.joinedOn&&employee.joinedOn.split("T")[0]? employee.joinedOn.split("T")[0]: "";
        setValue("_id", employee._id);
        setValue("nickname", employee.nickname);
        setValue("name", employee.name);
        setValue("position", employee.position);
        setValue("joinedOn", empDOJ);
        setValue("dateofbirth", empDOB);
        setValue("email", employee.email||'');
        setValue("mobile", employee.mobile||'');
        setValue("nationality", employee.nationality||null);
        setValue("nationalid", employee.nationalid||'');
        setValue("passportid", employee.passportid||'');
        setValue("activeEmployee", employee.active);
        setValue("activeInstructor", (employee._instructorid&&employee._instructorid.active) || false);
        if(employee.payroll){
          setValue("working_days", employee.payroll.working_days||'');
          setValue("paid_leave_days", employee.payroll.paid_leave_days||'');
          setValue("base_compensation", employee.payroll.base_compensation||'');
          setValue("overtime_hourly_rate", employee.payroll.overtime_hourly_rate||'');
          setValue("ss_company_deduction_rate", employee.payroll.ss_company_deduction_rate||'');
          setValue("ss_employee_deduction_rate", employee.payroll.ss_employee_deduction_rate||'');
        }
       


        setCustomSelectKey(Date.now()+Math.random())
        setState((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
    } else {
      Promise.all([
        Api.getAllDepartments(),
      ]).then(([departments]) => {
        setActiveDepartments(departments);
      });

      setState((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
    return () => console.log("Manage Employee Disposed");
  }, [employeeId, id]);

  const validPhoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  const validationSchema = Yup.object().shape({
    activeEmployee: Yup.bool(),
    activeInstructor: Yup.bool(),
    name: Yup.string().required("Name is required"),
    nickname: Yup.string().required("Nickname is required"),
    position: Yup.string().required("Position is required"),
    dateofbirth: Yup.string().test("age", "Valid birth date (at least 16 years of age). yyyy-mm-dd", (dateofbirth)=>employeeIsOfApproperiateAge(dateofbirth)),
    joinedOn: Yup.string().test("beforeToday", "Valid join date. yyyy-mm-dd", (joinedOn)=>validJoinDate(joinedOn)),
    email: Yup.string().required("Email is required").email("Valid email"),
    mobile: Yup.string().required("Mobile is required").matches(validPhoneRegExp, "Must be an international phone number like +97333393339"),
    nationality: Yup.object().required("Nationality is required").nullable(),
    nationalid: Yup.string().required("National ID is required"),
    passportid: Yup.string().required("Passport ID is required"),
    working_days: Yup.number()
    .typeError('You must specify a number')
    .min(15, 'Min value 15')
    .max(30, 'Max value 31.'),
    paid_leave_days: Yup.number()
    .typeError('You must specify a number')
    .min(1, 'Min value 1')
    .max(5, 'Max value 5.'),
    base_compensation: Yup.number()
    .typeError('You must specify a number'),
    overtime_hourly_rate: Yup.number()
    .typeError('You must specify a number'),
    ss_company_deduction_rate: Yup.number()
    .typeError('You must specify a number'),
    ss_employee_deduction_rate: Yup.number()
    .typeError('You must specify a number'),
  });

  const employeeIsOfApproperiateAge = (dob) => {
    let checkedDate = dob.split("T")[0];
    if(dob.trim()==="" || !Moment(checkedDate?checkedDate:dob,"YYYY-MM-DD", true).isValid())
      return false;
    let months = Moment().diff(dob, "months", true);
    let years = months/12;
    if(years < 16 || years > 100){
      return false;
    }
    else{
      return true;
    }
  }

  const validJoinDate = (joinedOn) => {
    let checkedDate = joinedOn.split("T")[0];
    if(joinedOn.trim()==="" || !Moment(checkedDate?checkedDate:joinedOn,"YYYY-MM-DD", true).isValid())
      return false;
    else
      return true;
  }

  const {
    handleSubmit,
    reset,
    errors: fieldsErrors,
    control,
    setValue,
    getValues,
    register
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  function onSubmit(data) {
    if (!tIsAdd) data._id = id;
    data._departmentid = selectedDepartment?._id;
    console.log(tIsAdd, data);
    Api.saveEmployee(data).then((res)=>{
      console.log(res);
      history.push(`/employee`);
    }).catch((e)=>{
      console.log("Cannot Add or Save Employee", e);
    })
  }

  const { loading } = state;
  
  return (
    <div>
      {loading && <CircularProgress />}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {tIsAdd ? "Add " : "Edit "} Employee
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
      <Grid container spacing={3}>
          <Grid item xs={6}>
          <FormControlLabel
            control={
              <Controller
                control={control}
                inputRef={register}
                name='activeEmployee'
                render={({onChange, value}) => (
                  <Checkbox
                    onChange={e => onChange(e.target.checked)}
                    checked={value}
                  />
                )}
              />
            }
            label="Active Employee"
          />
          </Grid>
          
          <Grid item xs={6}>
          <FormControlLabel
            control={
              <Controller
                control={control}
                inputRef={register}
                name='activeInstructor'
                render={({onChange, value}) => (
                  <Checkbox
                    onChange={e => onChange(e.target.checked)}
                    checked={value}
                  />
                )}
              />
            }
            label="Active Instructor"
          />
          </Grid>

          <Grid item xs={6}>
          <Autocomplete
              id="employeeDepartment"
              options={activeDepartments}
              value={selectedDepartment?selectedDepartment:null}
              onChange={(e, newVal) => {
                setSelectedDepartment(newVal);

              }}
              getOptionLabel={(option) => option.name? option.name:''}
              renderOption={(option) => (
                <React.Fragment>
                  {option.name}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Department"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'newpass'
                  }}
                  fullWidth
                  /* helperText={
                    fieldsErrors.nationality ? fieldsErrors.nationality.message : null
                  } */
                />
              )}
              getOptionSelected={(option, value) => {
                return value._id == option._id
              }}
            />
          </Grid>
         
        </Grid>
        
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="name"
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.name ? true : false}
                    helperText={
                      fieldsErrors.name ? fieldsErrors.name.message : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="nickname"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="nickname"
                    id="outlined-basic"
                    label="Nickname"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.nickname ? true : false}
                    helperText={
                      fieldsErrors.nickname
                        ? fieldsErrors.nickname.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="position"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="position"
                    id="outlined-basic"
                    label="Position"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.position ? true : false}
                    helperText={
                      fieldsErrors.position
                        ? fieldsErrors.position.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="dateofbirth"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="dateofbirth"
                    id="outlined-basic"
                    label="Date of Birth"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.dateofbirth ? true : false}
                    helperText={
                      fieldsErrors.dateofbirth
                        ? fieldsErrors.dateofbirth.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="joinedOn"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="joinedOn"
                    id="outlined-basic"
                    label="Joining Date"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.joinedOn ? true : false}
                    helperText={
                      fieldsErrors.joinedOn ? fieldsErrors.joinedOn.message : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="email"
                    id="outlined-basic"
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.email ? true : false}
                    helperText={
                      fieldsErrors.email ? fieldsErrors.email.message : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="mobile"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="mobile"
                    id="outlined-basic"
                    label="Mobile Number"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.mobile ? true : false}
                    helperText={
                      fieldsErrors.mobile ? fieldsErrors.mobile.message : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
          <Controller
                name="nationality"
                control={control}
                rules={{ required: true }}
                render={(props) => (
            <Autocomplete
              key={customSelectKey}
              options={localData.countries}
              defaultValue={employee&&employee&&employee.nationality?employee.nationality:null}
              onChange={(e, newVal) => {
                props.onChange(newVal?newVal:null);
                return newVal?newVal:null;
              }}
              getOptionLabel={(option) => option.Name? option.Name:''}
              renderOption={(option) => (
                <React.Fragment>
                  {option.Name} ({option.Code})
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  label="Nationality"
                  variant="outlined"
                  error={fieldsErrors.nationality ? true : false}
                  {...params}
                  fullWidth
                  helperText={
                    fieldsErrors.nationality ? fieldsErrors.nationality.message : null
                  }
                />
              )}
              getOptionSelected={(option, value) => {
                return value.Code === option.Code
              }}
            />)} />
      
        </Grid>
        <Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="nationalid"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="nationalid"
                    id="outlined-basic"
                    label="National ID"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.nationalid ? true : false}
                    helperText={
                      fieldsErrors.nationalid
                        ? fieldsErrors.nationalid.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="passportid"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="passportid"
                    id="outlined-basic"
                    label="Passport ID"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.passportid ? true : false}
                    helperText={
                      fieldsErrors.passportid
                        ? fieldsErrors.passportid.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
          
        
        </Grid>
        
        <Grid container spacing={3}>
        <Grid item xs={12}>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Payroll Information
        </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="working_days"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="working_days"
                    id="outlined-basic"
                    label="Working Days"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.working_days ? true : false}
                    helperText={
                      fieldsErrors.working_days
                        ? fieldsErrors.working_days.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
        <Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="paid_leave_days"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="paid_leave_days"
                    id="outlined-basic"
                    label="Paid Leave Days"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.paid_leave_days ? true : false}
                    helperText={
                      fieldsErrors.paid_leave_days
                        ? fieldsErrors.paid_leave_days.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
        <Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="base_compensation"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="base_compensation"
                    id="outlined-basic"
                    label="Base Compensation"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.base_compensation ? true : false}
                    helperText={
                      fieldsErrors.base_compensation
                        ? fieldsErrors.base_compensation.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
        <Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="overtime_hourly_rate"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="overtime_hourly_rate"
                    id="outlined-basic"
                    label="Overtime Hourly Rate"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.overtime_hourly_rate ? true : false}
                    helperText={
                      fieldsErrors.overtime_hourly_rate
                        ? fieldsErrors.overtime_hourly_rate.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
        <Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="ss_employee_deduction_rate"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="ss_employee_deduction_rate"
                    id="outlined-basic"
                    label="Employee Social Security Rate"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.ss_employee_deduction_rate ? true : false}
                    helperText={
                      fieldsErrors.ss_employee_deduction_rate
                        ? fieldsErrors.ss_employee_deduction_rate.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
        <Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="ss_company_deduction_rate"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="ss_company_deduction_rate"
                    id="outlined-basic"
                    label="Company Social Security Rate"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.ss_company_deduction_rate ? true : false}
                    helperText={
                      fieldsErrors.ss_company_deduction_rate
                        ? fieldsErrors.ss_company_deduction_rate.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
        
        </Grid>
        

        <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button onClick={(e) => history.push(`/employee`)}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {tIsAdd ? "Add " : "Save "} Employee
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ManageEmployee;