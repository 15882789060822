import { Fragment, useCallback, useEffect, useReducer, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Api } from "../../services/Api";
import Typography from "@material-ui/core/Typography";
import { 
  IconButton, 
  TextField, 
  Button,
  Container, 
  MenuItem,
  Select,
  InputLabel,
  Switch, 
  Chip, 
  FormControl, 
  FormControlLabel, 
  InputAdornment, 
  TableContainer 
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { Grid, ButtonGroup } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";

import FloatingActionButton from "../../screens/FloatingActionButton";

import Checkbox from "@material-ui/core/Checkbox";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SearchIcon from "@material-ui/icons/Search"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PurchaseOrderGrn from "../PurchaseOrder/Grn";

export default function Snapshots(props) {
  const [loading, setLoading] = useState(false);
  const [snapshots, setSnapshots] = useState([]);
  const [visibleSnapshots, setVisibleSnapshots] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const toggleStatements = props.toggleStatements;
  const getGlAccountsByType = props.getGlAccountsByType;
  const snapshotsPerPage = 5;

  useEffect(() => {
    Promise.all([
      Api.getGLSnapshots(),
    ]).then(([res]) => {
      let snaps = res.reverse();
      let tempVisibleSnapshots = [];
      for(let i=0; i<snapshotsPerPage; i++)
         snaps[i]&&tempVisibleSnapshots.push(snaps[i]);
      setSnapshots(snaps);
      setVisibleSnapshots(tempVisibleSnapshots);
      
    }).catch((e)=>{
      console.log(e);
    });
  }, []);

  function nextSnapshot(){
    if((currentIndex+snapshotsPerPage+1)<=snapshots.length){
      let tempSnapshots = [...snapshots];
      let tempVisibleSnapshots = [];
      for(let i=currentIndex+1; i<(snapshotsPerPage+currentIndex+1);i++)
        tempVisibleSnapshots.push(tempSnapshots[i]);
      setCurrentIndex(currentIndex+1);
      setVisibleSnapshots(tempVisibleSnapshots);
    }
    
  }

  function prevSnapshot(){
    if(currentIndex>0){
      let tempSnapshots = [...snapshots];
      let tempVisibleSnapshots = [];
      for(let i=currentIndex-1; i<(snapshotsPerPage+currentIndex-1);i++)
        tempVisibleSnapshots.push(tempSnapshots[i]);
      setCurrentIndex(currentIndex-1);
      setVisibleSnapshots(tempVisibleSnapshots);
    }
  }

  function dateOnly(date){
    return date.split("T")[0];
  }
  
  return (
        <Fragment>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
          <IconButton
            aria-label="Grn"
            onClick={() => toggleStatements()}>
            <CloseIcon />
          </IconButton>
          </Grid>
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Financial Statements
            </Typography>
          </Grid>
         <Grid item xs={6}>
         </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="space-between"
          alignItems="center">
         
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
            Balance Sheet
            </Typography>
          </Grid>
          <Grid item>
            <ButtonGroup size="small" aria-label="Page Control">
              <Button onClick={()=>prevSnapshot()}>&lt; Previous</Button>
              <Button onClick={()=>nextSnapshot()}>Next &gt;</Button>
            </ButtonGroup>
          </Grid>
        </Grid>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Account</b>
              </TableCell>
              {visibleSnapshots&&visibleSnapshots.map((obj, key)=>(
                  <TableCell key={key}>
                  <b>{dateOnly(obj.closing_date)}</b>
                  </TableCell>
              ))}
             
            </TableRow>
          </TableHead>
          <TableBody>
                {getGlAccountsByType('Asset').map((account, key)=>(
                  <TableRow key={key}>
                    <TableCell>
                      {account.name}
                    </TableCell>
                    {visibleSnapshots&&visibleSnapshots.map((obj, skey)=>(
                      <TableCell key={skey}>
                      {obj.accounts[account.name]?obj.accounts[account.name]:0}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                {getGlAccountsByType('Liability').map((account, key)=>(
                  <TableRow key={key}>
                    <TableCell>
                      {account.name}
                    </TableCell>
                    {visibleSnapshots&&visibleSnapshots.map((obj, skey)=>(
                      <TableCell key={skey}>
                      {obj.accounts[account.name]?obj.accounts[account.name]:0}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                {getGlAccountsByType('Equity').map((account, key)=>(
                  <TableRow key={key}>
                    <TableCell>
                      {account.name}
                    </TableCell>
                    {visibleSnapshots&&visibleSnapshots.map((obj, skey)=>(
                      <TableCell key={skey}>
                      {obj.accounts[account.name]?obj.accounts[account.name]:0}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
          </TableBody>
          </Table>

          <Typography component="h2" variant="h6" style={{marginTop: 15}}
color="primary">
              Income Statement
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Account</b>
              </TableCell>
              {visibleSnapshots&&visibleSnapshots.map((obj, key)=>(
                  <TableCell key={key}>
                  <b>{dateOnly(obj.closing_date)}</b>
                  </TableCell>
              ))}
             
            </TableRow>
          </TableHead>
          <TableBody>
                {getGlAccountsByType('Revenue').map((account, key)=>(
                  <TableRow key={key}>
                    <TableCell>
                      {account.name}
                    </TableCell>
                    {visibleSnapshots&&visibleSnapshots.map((obj, skey)=>(
                      <TableCell key={skey}>
                      {obj.accounts[account.name]?obj.accounts[account.name]:0}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                {getGlAccountsByType('Expense').map((account, key)=>(
                  (account.name!=='Depreciation Expense')&&
                  <TableRow key={key}>
                    <TableCell>
                      {account.name}
                    </TableCell>
                    {visibleSnapshots&&visibleSnapshots.map((obj, skey)=>(
                      <TableCell key={skey}>
                      {obj.accounts[account.name]?obj.accounts[account.name]:0}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                
                <TableRow>
                    <TableCell>
                      Closed Depreciation Expense
                    </TableCell>
                    {visibleSnapshots&&visibleSnapshots.map((obj, skey)=>(
                      <TableCell key={skey}>
                      {obj.accounts['Closed Depreciation Expense']?obj.accounts['Closed Depreciation Expense']:0}
                      </TableCell>
                    ))}
                  </TableRow>
          </TableBody>
          </Table>
      </Fragment>
    );
}