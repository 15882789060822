import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { /* createContext, */ useState/* , useContext */ } from "react";
import SignIn from "./components/SignIn";
import Application from "./components/Main/Application";
import { Api } from "./services/Api";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

/* import { Redirect, Route } from "react-router-dom";
import AppStyles from "./shared/applicationstyle";
import { CssBaseline } from "@material-ui/core";
import Header from "./screens/Header"; */

const availableLangs = [{
  name:"English",
  value:"en"
},{
  name:"Arabic",
  value:"ar"
}]

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  // const classes = AppStyles();
  // <div className={classes.root}>
  //   <CssBaseline />
  //   <Header classes={classes} open={open} callback={handleDrawerOpen}></Header>
  // </div>;
  
  const tIsLoggedIn = Api.isLoggedIn();
  if (!tIsLoggedIn) Api.signIn({ token: "HELLO" });
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("@user")));
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const defaultTheme = createMuiTheme({direction: 'ltr'})
  const [currentTheme, setCurrentTheme] = useState(defaultTheme);

  const updateUserInformation = (user) => {
    setUser(user);
    Api.signOut();
    localStorage.setItem("@user", JSON.stringify(user));
  };

  const translations = {
    "Dashboard": {
      "ar":"الرئيسية"
    },
    "Student Billing": {
      "ar":"فوترة الطلبة"
    },
    "Product Catalogue": {
      "ar":"سجل المنتجات"
    },
    "Purchase Orders": {
      "ar":"طلبات الشراء"
    },
    "Suppliers": {
      "ar":"الموردون"
    },
    "Supplier Billing": {
      "ar":"فوترة المورد"
    },
    "Employees": {
      "ar":"الموظفون"
    }
  }

  const getTranslatedText = (text)=>{
    return translations[text]?translations[text]:text;
  }

  return (
    <div>
      {!user.token ? (
        <SignIn updateUserInformation={updateUserInformation} />
      ) : (
        
          <StylesProvider jss={jss}>
          <ThemeProvider theme={currentTheme}>
            <Application 
              availableLangs={availableLangs}
              currentLanguage={currentLanguage} 
              setCurrentLanguage={setCurrentLanguage}
              setCurrentTheme={setCurrentTheme}
              updateUserInformation={updateUserInformation}
              getTranslatedText={getTranslatedText}
              user={user} />
          </ThemeProvider>
          </StylesProvider>
      )}
    </div>
  );
}
export default App;

const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */





// function AuthButton() {
//   let history = useHistory();
//   let auth = useAuth();

//   return auth.user ? (
//     <p>
//       Welcome!{" "}
//       <button
//         onClick={() => {
//           auth.signout(() => history.push("/"));
//         }}
//       >
//         Sign out
//       </button>
//     </p>
//   ) : (
//     <p>You are not logged in.</p>
//   );
// }
