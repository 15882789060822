import React, { useCallback, useEffect, useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Api } from "../../services/Api";
import {
  Typography,
  Grid,
  Button,
  MenuItem,
  Select,
  CircularProgress,
  FormControl,
  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Chip,
  ListItem,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import { useHistory, useParams } from "react-router-dom";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import ChipInput from 'material-ui-chip-input';
import { AddAPhotoOutlined } from "@material-ui/icons";

/* const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  
})); */

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    minWidth: 130,
    height: 40,
  },
  input: {
    width: '100%',
    height: 40,
  },
  extendedIcon: {
    marginRight: theme.spacing(0),
  },
  checkboxGrid: {
    border: `${fade(theme.palette.text.primary, 0.2)} 1px solid`,
    marginTop: "5px",
    padding: "10px",
  },
}));

const defaultValues = {
  name: "",
  short_name: "",
  contacts: [],
  tags: [],
  isApproved: false,
};

const initialState = {
  tags: [],
  loading: true,
  error: undefined,
};

const ManageSupplier = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const supplierId = parseInt(id);
  const history = useHistory();
  const tIsAdd = isNaN(parseInt(id)) || id <= 0;
  const [state, setState] = useState(initialState);
  const [supplier, setSupplier] = useState({});
  useEffect(() => {
    if (id) {
      Promise.all([
        Api.getSupplierById(id),
        Api.getSupplierTags(),
      ]).then(([res1, res2]) => {
        setSupplier(res1);
        const supplier = res1;
        const tags = res2;
        setValue("_id", supplier._id);
        setValue("short_name", supplier.short_name);
        setValue("name", supplier.name);
        supplier.contacts?.forEach((element) => {
          append(element);
        });
        setValue("contacts", supplier.contats);
        setValue("isApproved", supplier.isApproved);
        setState((prev) => {
          return {
            ...prev,
            loading: false,
            tags: tags,
          };
        });
      });
    } else {
      setState((prev) => {
        return {
          ...prev,
          loading: false,
          tags: tags,
        };
      });
    }
    return () => console.log("Manage Supplier Disposed");
  }, [supplierId, id]);

  const validationSchema = Yup.object().shape({
    contacts: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        mobile: Yup.string().required("Mobile is required"),
        email: Yup.string()
          .required("Email is required")
          .email("Invalid email"),
      })
    ),
    tags: Yup.array().transform((value) => {
      return value;
    }),
    /* _id: Yup.number(), */
    name: Yup.string().required("Name is required"),
    short_name: Yup.string().required("Short name is required"),
  });

  const {
    handleSubmit,
    reset,
    errors: fieldsErrors,
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "contacts",
  });

  function onSubmit(data) {
    //debugger;
    if (!tIsAdd) data._id = id;
    console.log(tIsAdd, data);
    data.tags = supplier.tags;
    Api.saveSupplier(data).then((res)=>{
      console.log(res);
      history.push(`/supplier`);
    }).catch((e)=>{
      console.log("Cannot Add or Save Supplier", e);
    })
    //Api.ManageSupplier(tIsAdd, data);
    //history.push(`/supplier`);
    // // display form data on success
    // setState({ ...state, loading: true });
    // const clone = { ...state.entity.meta };
    // const newMeta = {};
    // // state.metas.forEach((m) => {
    // //   newMeta[m.key] = m.value;
    // // });
    // data.metas.forEach((m) => {
    //   newMeta[m.key] = m.value;
    // });
    // entity.meta = newMeta;
    // entity.category = data.category;
    // //entity.meta.manufacturer = data.manufacturer;
    // entity.selling_price = data.selling_price;
    // entity.sub_category = data.sub_category;
    // entity.short_name = data.short_name;
    // entity.purchase_tax = parseInt(data.purchase_tax);
    // entity.description = data.description || "";
    // if (data.sales_tax) entity.sales_tax = parseInt(data.sales_tax);
    // else entity.sales_tax = 0;
    // Api.saveProductCode(entity).then(() => {
    //   setState({ ...state, loading: false });
    //   history.push(`/products/${parentType}`);
    // });
  }

  const handleTagsChange = (chips) =>{
    setSupplier({...supplier, tags: chips});
  }

  const { loading, tags } = state;
  
  return (
    <div>
      {loading && <CircularProgress />}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {tIsAdd ? "Add " : "Edit "} Supplier
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="name"
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.name ? true : false}
                    helperText={
                      fieldsErrors.name ? fieldsErrors.name.message : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="short_name"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                as={
                  <TextField
                    name="short_name"
                    id="outlined-basic"
                    label="Short Name"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.short_name ? true : false}
                    helperText={
                      fieldsErrors.short_name
                        ? fieldsErrors.short_name.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6}>
            <InputLabel id="tagsLabel"></InputLabel>
            <ChipInput
              variant="outlined" 
              label="Tags" 
              defaultValue={supplier.tags} 
              fullWidth 
              onChange={(chips) => handleTagsChange(chips)}/>
            
          </Grid>
          <Grid item xs={6}>
            {supplier&&supplier.isApproved===true&&
              <Chip color="primary" label="Approved"></Chip>
            }
            {supplier&&supplier.isApproved===false&&
              <Chip color="secondary" label="Not Approved"></Chip>
            }
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item>
            <IconButton
              title="Click here to add contact detail"
              aria-label="done"
              onClick={() => append({ name: "", mobile: "", email: "" })}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Paper className={classes.root}>
          <Table className={classes.table} aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell align="left" />
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Mobile</TableCell>
                <TableCell align="left">Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((row, idx) => (
                <CustomRow
                  remove={remove}
                  classes={classes}
                  entry={row}
                  key={row.id}
                  control={control}
                  fieldsErrors={fieldsErrors}
                  index={idx}
                ></CustomRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button onClick={(e) => history.push(`/supplier`)}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {tIsAdd ? "Add " : "Save "} Supplier
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const CustomTableCell = ({
  name,
  defaultValue,
  idx,
  control,
  fieldsError,
  classes,
  controlType,
  ...rest
}) => {
  const hasError = fieldsError && fieldsError[idx] && fieldsError[idx][name];
  let errorMsg = "";
  if (hasError) {
    errorMsg = fieldsError[idx][name].message;
  }
  const key = `contacts.${idx}.${name}`;
  return (
    <TableCell align="left" className={classes.tableCell}>
      <Controller
        name={key}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: true }}
        as={
          <TextField
            type={controlType}
            className={classes.input}
            error={hasError ? true : false}
            helperText={errorMsg ? errorMsg : null}
            {...rest}
          />
        }
      ></Controller>
    </TableCell>
  );
};

const CustomRow = ({
  entry,
  remove,
  classes,
  control,
  index,
  fieldsErrors,
  ...rest
}) => {
  return (
    <TableRow>
      <TableCell className={classes.selectTableCell}>
        <IconButton aria-label="remove" onClick={() => remove(index)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
      <CustomTableCell
        classes={classes}
        control={control}
        idx={index}
        name="name"
        fieldsError={fieldsErrors["contacts"]}
        defaultValue={entry.name}
        controlType="text"
      />
      <CustomTableCell
        classes={classes}
        control={control}
        idx={index}
        name="mobile"
        fieldsError={fieldsErrors["contacts"]}
        defaultValue={entry.mobile}
        controlType="text"
      />
      <CustomTableCell
        classes={classes}
        control={control}
        idx={index}
        name="email"
        fieldsError={fieldsErrors["contacts"]}
        defaultValue={entry.email}
        controlType="email"
      />
    </TableRow>
  );
};

export default ManageSupplier;