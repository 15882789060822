import { Fragment, useCallback, useEffect, useReducer, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Api } from "../../services/Api";
import Typography from "@material-ui/core/Typography";
import { 
  IconButton, 
  TextField, 
  Button, 
  Container, 
  MenuItem,
  Select,
  InputLabel,
  Switch, Chip, FormControl, FormControlLabel, InputAdornment, TableContainer } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add"

import FloatingActionButton from "../../screens/FloatingActionButton";

import Checkbox from "@material-ui/core/Checkbox";
import PaymentIcon from "@material-ui/icons/Payment";
import DescriptionIcon from "@material-ui/icons/Description";
import DoneIcon from "@material-ui/icons/Done"
import SearchIcon from "@material-ui/icons/Search"
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import EmployeeView from './Employee';

function roundAmount(amount){
  let frac = 100;
  return Math.round((amount+Number.EPSILON)*frac) / frac;
}

const MakePaymentView = (props) =>{
  const defaultAmount = 0.01;
  const [amount, setAmount] = useState(defaultAmount);

  return(
    <Fragment>
      <TextField
        id="outlined-basic"
        variant="outlined"
        label="Amount Paid"
        defaultValue={defaultAmount}
        onChange={(e)=>setAmount(parseFloat(e.target.value.trim()))}
        onInput={(e) => {          
          if(!isNaN(e.target.value) && parseFloat(e.target.value)<0) e.target.value=defaultAmount;
          if(isNaN(e.target.value)) e.target.value=defaultAmount;
        }}
      ></TextField>
      <IconButton
       onClick={()=>props.makeSupplierPayment(props.supplier._id, amount)}>
        <DoneIcon />
      </IconButton>
      <IconButton
       onClick={()=>props.toggleMakePayment(props.supplier._id)}>
        <CloseIcon />
      </IconButton>
    </Fragment>
  )
}

const PostPayrollView  = (props) =>{
 
  const years = [2021,2022,2023,2024,2025];
  const months = [0,1,2,3,4,5,6,7,8,9,10,11];

  const selectedEmployees = props.getSelectedEmployees();
  const allPayrolls = props.allPayrolls;

  const [totalPayroll, setTotalPayroll] = useState(0);
  const [overtimeHours, setOvertimeHours] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const getMonthName = props.getMonthName;

  const updateEmployeeAccounts = props.updateEmployeeAccounts;
  const updateAllPayrolls = props.updateAllPayrolls;


  function postEmployeePayroll(){
    console.log("post");
    let newPayrolls = [];
    selectedEmployees.map((employee,key)=>{
      if(isNotPostedYet(employee)){
        let employee_payable = employee.payroll.base_compensation - getSSAmount(employee.payroll.ss_employee_deduction_rate,employee.payroll.base_compensation);
        employee_payable += overtimeHours[employee._id]?overtimeHours[employee._id]*employee.payroll.overtime_hourly_rate:0
        let ss_employee_deduction = getSSAmount(employee.payroll.ss_employee_deduction_rate,employee.payroll.base_compensation);
        let ss_company_deduction = getSSAmount(employee.payroll.ss_company_deduction_rate,employee.payroll.base_compensation);
        let ss_payable = ss_employee_deduction + ss_company_deduction;
        let newPayroll = {
          _employeeid: employee._id,
          month: selectedMonth,
          year: selectedYear,
          base_compensation: employee.payroll.base_compensation,
          overtime_hours: overtimeHours[employee._id] || 0,
          overtime_hourly_rate: employee.payroll.overtime_hourly_rate,
          ss_employee_deduction: ss_employee_deduction,
          ss_company_deduction: ss_company_deduction,
          ss_payable: ss_payable,
          employee_payable: employee_payable
        }
        newPayrolls.push(newPayroll);
      }
      
    });
    console.log(newPayrolls);
    if(newPayrolls.length>0){
      Api.postEmployeePayrolls(newPayrolls).then((res)=>{
        console.log(res);
        selectedEmployees.map(employee=>{
          updateEmployeeAccounts(employee._id);
        });
        updateAllPayrolls();
        props.togglePostPayroll();
      }).catch((e)=>{
        console.log(e);
      });
    }else{
      props.togglePostPayroll();
    }
    
  }

  function getSSAmount(rate, comp){
    return roundAmount(rate*comp);
  }

  function isNotPostedYet(employee){
    let foundIndex = allPayrolls&&allPayrolls.findIndex(p=>p._employeeid===employee._id&&p.month===selectedMonth&&p.year===selectedYear&&p.voided===false);
    if(foundIndex>-1){
      return false
    }else{
      return true;
    }
  }

  const handleOvertimeFocus = (e) =>{
    e.target.setAttribute("data-init", e.target.value);
  }

  function updateEmployeeOvertimeHours(_employeeid, e){
    let parsedVal =parseFloat(e.target.value);
    const originalVal = e.target.getAttribute("data-init");
    const maxHours = 50;
    if(e.target.value!==''){
      if(isNaN(parsedVal)||parsedVal<0||parsedVal>maxHours){
        parsedVal = parseFloat(originalVal);
        e.target.value=originalVal;
      }
    }else{
      parsedVal = 0;
    }
      
    setOvertimeHours((prev)=>{
      return {
        ...prev,
        [_employeeid]: parsedVal
      }
    })
  }

  function getEmployeeNetAmount(employee){
    let netAmount = employee.payroll.base_compensation - getSSAmount(employee.payroll.ss_employee_deduction_rate,employee.payroll.base_compensation);
    netAmount += overtimeHours[employee._id]?overtimeHours[employee._id]*employee.payroll.overtime_hourly_rate:0
    return roundAmount(netAmount);
  }
  
  return (
      <Fragment>
         <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
          <IconButton
            aria-label="Grn"
            onClick={() => props.togglePostPayroll()}>
            <CloseIcon />
          </IconButton>
          </Grid>
          <Grid item>
          <Typography component="h2" variant="h6" color="primary">
          Post Employees Payroll: {selectedEmployees.length} Employees
          </Typography>
          </Grid>
       
        </Grid>
      
      <Grid container spacing={3}>
      <Grid item xs={6}>
          <FormControl style={{width: '100%'}}>
          <InputLabel id="yearLabel">Year</InputLabel>
            <Select
              name="year"
              id="year"
              labelId="yearLabel"
              fullWidth
              displayEmpty
              value={selectedYear}
              onChange={(e)=>setSelectedYear(parseInt(e.target.value))}>
              {years.map((y, index) => {
                return (
                  <MenuItem value={y} key={index}>
                    {y}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          </Grid>
        <Grid item xs={6}>
          <FormControl style={{width: '100%'}}>
          <InputLabel id="monthLabel">Month</InputLabel>
            <Select
              name="month"
              id="month"
              labelId="monthLabel"
              fullWidth
              displayEmpty
              value={selectedMonth}
              onChange={(e)=>setSelectedMonth(parseInt(e.target.value))}>
              {months.map((m, index) => {
                return (
                  <MenuItem value={m} key={index}>
                    {getMonthName(m)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          </Grid>
      
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
        Total Payroll Expenses: {totalPayroll}
        </Grid>
      </Grid>
       <Table>
      <TableHead>
        <TableRow>
          <TableCell><b>ID</b></TableCell>
          <TableCell><b>Name</b></TableCell>
          <TableCell><b>Base</b></TableCell>
          <TableCell><b>SSE</b></TableCell>
          <TableCell><b>SSC</b></TableCell>
          <TableCell><b>Overtime Hours</b></TableCell>
          <TableCell><b>Net Payable</b></TableCell>
          <TableCell><b>SS Payable</b></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedEmployees.map((employee, key)=>(
          allPayrolls&&isNotPostedYet(employee)&&
          <TableRow key={key}>
            <TableCell>
              {employee._id}
            </TableCell>
            <TableCell>
              {employee.name}
            </TableCell>
            <TableCell>
              {employee&&employee.payroll&&employee.payroll.base_compensation || 0}
            </TableCell>
            <TableCell>
              {employee&&employee.payroll&&getSSAmount(employee.payroll.ss_employee_deduction_rate,employee.payroll.base_compensation)}
            </TableCell>
            <TableCell>
              {employee&&employee.payroll&&getSSAmount(employee.payroll.ss_company_deduction_rate,employee.payroll.base_compensation)}
            </TableCell>
            <TableCell>
              <TextField
                defaultValue={0}
                variant="outlined"
                fullWidth
                type="number"
                InputProps={{
                  inputProps: { 
                      min: 0,
                  },
                  'data-init': '0'
                }}
                onFocus={(e)=>handleOvertimeFocus(e)}
                onChange={(e)=>updateEmployeeOvertimeHours(employee._id, e)}
              />
            </TableCell>
            <TableCell>
              {employee&&employee.payroll&&getEmployeeNetAmount(employee)}
            </TableCell>
            <TableCell>
              {employee&&employee.payroll&&getSSAmount(employee.payroll.ss_company_deduction_rate,employee.payroll.base_compensation)+
              getSSAmount(employee.payroll.ss_employee_deduction_rate,employee.payroll.base_compensation)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      </Table>
      <Grid container spacing={3} style={{marginTop:10}}
        direction="row"
        justify="flex-end"
        alignItems ="center">
          <Grid item>
            <Button onClick={(e)=>props.togglePostPayroll()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button 
            variant="contained" 
            onClick={(e)=>postEmployeePayroll()}
            color="primary"
            >
              Post Employee Payroll
            </Button>
          </Grid>

      </Grid>
      </Fragment>
  )
}

const PayrollPaymentView = (props)  =>{
  const selectedPayrolls = props.selectedPayrolls;
  const employees = props.employees;
  const getMonthName = props.getMonthName;
  const payAllEmployees = props.payAllEmployees;
  const getEmployeeName = (_employeeid) =>{
    return employees[employees.findIndex(e=>e._id===_employeeid)].name;
  }

  return (
    <Fragment>
    <Grid
        container
        spacing={3}
        direction="row"
        justify="flex-start"
        alignItems="center">
        <Grid item>
        <IconButton
          aria-label="Grn"
          onClick={() => props.togglePayrollPayments()}>
          <CloseIcon />
        </IconButton>
        </Grid>
        <Grid item>
        <Typography component="h2" variant="h6" color="primary">
        Payroll Payments ({getMonthName(selectedPayrolls[0].month)} {selectedPayrolls[0].year})
        </Typography>
        </Grid>
    
      </Grid>
      
      <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Employee Name</b>
              </TableCell>
              <TableCell>
                <b>Employee Payable</b>
              </TableCell>
              <TableCell>
                <b>Paid Amount</b>
              </TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
          {selectedPayrolls.map((payroll, key)=>{
            return(
              <TableRow key={key}>
                <TableCell>
                  {getEmployeeName(payroll._employeeid)}
                </TableCell>
                <TableCell>
                  {payroll.employee_payable}
                </TableCell>
                <TableCell>
                  {payroll._paymentid?payroll.employee_payable:0}
                </TableCell>
              </TableRow>
            )  
          })}
          </TableBody>
          </Table>
          <Button onClick={()=>payAllEmployees(selectedPayrolls)}>Pay All Employees</Button>
      </Fragment>
  );
}

const SSPaymentView = (props)  =>{
  
}

const ExistingPayrollsView = (props) =>{
  const allPayrolls = props.allPayrolls;
  const employees = props.employees;
  const getMonthName = props.getMonthName;

  const [payrollPaymentVisible, setPayrollPaymentVisible] = useState(false);
  const [selectedPayrolls, setSelectedPayrolls] = useState([]);
  const [ssPaymentVisible, setSsPaymentVisible] = useState(false);

  const [uniqueMonths, setUniqueMonths] = useState([]);
  const [uniqueYears, setUniqueYears] = useState([]);
  
  function getPayrollsInMonthYear(month, year){
    let payrolls = allPayrolls.filter((p)=>p.month===month&&p.year===year&&p.voided===false);
    return payrolls;
  }

  function getPayrollsTotals(payrolls){
    let totalEmployeeExpense = 0;
    let totalSSExpense = 0;
    payrolls.map((payroll, key)=>{
      totalEmployeeExpense += payroll.employee_payable;
      totalSSExpense += payroll.ss_payable;
    });

    let totalsObject = {};
    totalsObject['EmpExp'] = totalEmployeeExpense;
    totalsObject['SSExp'] = totalSSExpense;
    return totalsObject;
  }

  function togglePayrollPayments(month, year){
    setPayrollPaymentVisible(!payrollPaymentVisible);
    setSelectedPayrolls(getPayrollsInMonthYear(month, year));
  }

  function toggleSSPayments(){

  }

  useEffect(() => {
    setUniqueMonths([... new Set(allPayrolls.map(p=>p.month))]);
    setUniqueYears([... new Set(allPayrolls.map(p=>p.year))]);
  }, [allPayrolls]);

  function payAllEmployees(selectedPayrolls){
    Api.issueEmployeePayments(selectedPayrolls).then((res)=>{
      console.log(res);
    }).catch((e)=>{
      console.log(e);
    });
  }

  return (
    
      payrollPaymentVisible?
      <PayrollPaymentView 
        selectedPayrolls={selectedPayrolls} 
        getMonthName={getMonthName}
        togglePayrollPayments={togglePayrollPayments} 
        employees={employees} 
        payAllEmployees={payAllEmployees} />
      :
      <Fragment>
      <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
          <IconButton
            aria-label="Grn"
            onClick={() => props.toggleExistingPayrolls()}>
            <CloseIcon />
          </IconButton>
          </Grid>
          <Grid item>
          <Typography component="h2" variant="h6" color="primary">
          Posted Employee Payrolls
          </Typography>
          </Grid>
       
        </Grid>
      
      <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Period</b>
              </TableCell>
              <TableCell>
                <b>Employee Count</b>
              </TableCell>
              <TableCell>
                <b>Employee Expenses</b>
              </TableCell>
              <TableCell>
                <b>SS Expenses</b>
              </TableCell>
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uniqueYears.map((year, key)=>(
              uniqueMonths.map((month, key)=>(
                getPayrollsInMonthYear(month, year).length>0&&
                <TableRow key={key}>
                  <TableCell>
                  {getMonthName(month)} {year}
                  </TableCell>
                  <TableCell>
                  {getPayrollsInMonthYear(month, year).length}
                  </TableCell>
                  <TableCell>
                    {getPayrollsTotals(getPayrollsInMonthYear(month, year))['EmpExp']}
                  </TableCell>
                  <TableCell>
                    {getPayrollsTotals(getPayrollsInMonthYear(month, year))['SSExp']}
                  </TableCell>
                  <TableCell>
                  <IconButton
                  title="Issue Employee Payments"
                  aria-label="Issue Employee Payment"
                  color="primary"
                  onClick={() => togglePayrollPayments(month, year)}
                  >
                  <PaymentIcon />
                  </IconButton>
                  <IconButton
                  title="Issue SS Payments"
                  aria-label="Issue SS Payment"
                  color="default"
                  onClick={() => toggleSSPayments()}
                  >
                  <PaymentIcon />
                  </IconButton>
                   
                  </TableCell>
                </TableRow>
              ))
            ))}
          </TableBody>
      </Table>
    </Fragment>
  )
  
}

export default function EmployeeAccounts() {
  const [employees, setEmployees] = useState([]);  
  const [currentYear, setCurrentYear] = useState("2021");

  const [makePaymentVisible, setMakePaymentVisible] = useState({});
  const [postPayrollVisible, setPostPayrollVisible] = useState(false);
  const [existingPayrollsVisible, setExistingPayrollsVisible] = useState(false);


  const [searchedEmployees, setSearchedEmployees] = useState([]);
  const [searchPhrase, setSearchPhrase] = useState("");

  const [allPayrolls, setAllPayrolls] = useState([]);

  const showAtaTime = 10;

  const years = ["2021","2022", "2023"];

  function getMonthName(month){
    return month===0?"Jan":month===1?"Feb":month===2?"Mar":month===3?"Apr":month===4?"May":month===5?"Jun":month===6?"Jul":month===7?"Aug":month===8?"Sep":month===9?"Oct":month===10?"Nov":month===11?"Dec":"";
  }
  
  useEffect(() => {
    Promise.all([
      Api.getEmployees(),
      Api.getAllEmployeePayrolls()
    ]).then(([employees, allPayrolls]) => {
      setEmployees(employees.reverse());
      employees.forEach((emp)=>{
        emp.selected=false;
      })
      setSearchedEmployees(employees);
      setAllPayrolls(allPayrolls);
    }).catch((e)=>{
      console.log(e);
    });
  }, []);

  const updateAllPayrolls = () =>{
    employees.length>0&&Promise.all([
      Api.getAllEmployeePayrolls()
    ]).then(([allPayrolls]) => {
      console.log('searched emp', searchedEmployees);
      setAllPayrolls(allPayrolls);
    }).catch((e)=>{
      console.log(e);
    });
  }

  const toggleMakePayment = (_supplierid) =>{
    setMakePaymentVisible((prev)=>{
      return {
        ...prev,
        [_supplierid]: !makePaymentVisible[_supplierid]
      }
    });
  }

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const toggleEmployee = (employee) => {
    setSelectedEmployee(employee);
  }

  function updateEmployeeAccounts(_employeeid){
    let employeeIndex = searchedEmployees.findIndex(employee=>employee._id===_employeeid);
    Api.getEmployeeAccounts(_employeeid).then((accounts)=>{
      let tempArr = [...searchedEmployees];
      tempArr[employeeIndex].accounts = accounts;
      setSearchedEmployees(tempArr);
      //update selected student accounts
      if(selectedEmployee && (_employeeid === selectedEmployee._id)){
        setSelectedEmployee(old=>({...old, accounts:accounts}));
      }
    }).catch((e)=>console.log(e));

  }

  function togglePostPayroll(){
    setPostPayrollVisible((prev)=>{
      return !prev
    });
  }

  function toggleExistingPayrolls(){
    setExistingPayrollsVisible((prev)=>{
      return !prev
    });
  }

  const handlePhraseChange = useCallback((e) => {
    setSearchPhrase(e.target.value);
  }, []);

  useEffect(() => {
    let result = employees.filter(function (el) {
      let searchPhraseCondition = new RegExp(searchPhrase, "i");
      let filters = [];
      let tagstext = el.tags?.join('/');

      if(searchPhrase !==''){
        filters.push(searchPhraseCondition.test(el.name)
        || searchPhraseCondition.test(el.nickname) 
        || searchPhraseCondition.test(tagstext));
      }

      let filter = true;
      filters.forEach((f)=>{
        filter = filter && f 
      });

      return filter;
    });
    setSearchedEmployees(result);
  }, [searchPhrase, employees]);

  function getSelectedEmployees() {
    return searchedEmployees.filter((e)=>e.selected);
  }

  const handleSelectAllChange = (e) => {
    let tempEmps = [];
    if (e.target.checked) {
      searchedEmployees.map((emp, key)=>{
        let tempEmp = {...emp};
        tempEmp.selected = true;
        tempEmps.push(tempEmp);
      });
      setSearchedEmployees(tempEmps);
    }else{
      searchedEmployees.map((emp, key)=>{
        let tempEmp = {...emp};
        tempEmp.selected = false;
        tempEmps.push(tempEmp);
      });
      setSearchedEmployees(tempEmps);
    }
    
  }

  const handleSelectChange = (e, employee) => {
    let tempEmployees = [...searchedEmployees];
    let tempEmployeeIndex = tempEmployees.findIndex((emp)=>emp._id===employee._id);
    
    if (e.target.checked)
      tempEmployees[tempEmployeeIndex].selected = true;
    else
      tempEmployees[tempEmployeeIndex].selected = false;

    setSearchedEmployees(tempEmployees)
  }
 
  return (
    <Container maxWidth="lg">
        {existingPayrollsVisible?
        <ExistingPayrollsView 
          employees={employees}
          toggleExistingPayrolls={toggleExistingPayrolls}
          allPayrolls={allPayrolls}
          getMonthName={getMonthName}
        />
        : postPayrollVisible?
        <PostPayrollView 
          getSelectedEmployees={getSelectedEmployees}
          togglePostPayroll={togglePostPayroll}
          allPayrolls={allPayrolls}
          getMonthName={getMonthName}
          updateEmployeeAccounts={updateEmployeeAccounts}
          updateAllPayrolls={updateAllPayrolls}
        />
        :
        selectedEmployee?
        <EmployeeView
        toggleEmployee={toggleEmployee}
        selectedEmployee={selectedEmployee}
        updateEmployeeAccounts={updateEmployeeAccounts}
        updateAllPayrolls={updateAllPayrolls}
        />
        :
        <Fragment>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Employee Accounts
            </Typography>
          </Grid>
         <Grid item xs={6}>
         </Grid>
        </Grid>
        
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
          <FormControl style={{width: '100%'}}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                label="Search Employee Name"
                value={searchPhrase}
                onChange={handlePhraseChange}
              />
          </FormControl>
          </Grid>

{/*           <Grid item xs={6}>
          <FormControl style={{width: '100%'}}>
          <InputLabel id="yearLabel">Year</InputLabel>
            <Select
              name="year"
              id="year"
              labelId="yearLabel"
              fullWidth
              displayEmpty
              defaultValue={"2021"}
              onChange={(e)=>setCurrentYear(e.target.value)}
            >
              
              {years.map((y, index) => {
                return (
                  <MenuItem value={y} key={index}>
                    {y}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          </Grid> */}

        </Grid>
         <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item xs={6}>{searchedEmployees.length} found</Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
            <Grid item xs={6}>
            <Button 
              variant="outlined" 
              color="primary"
              onClick={(e)=>togglePostPayroll()}
              disabled={getSelectedEmployees().length>0?false:true}>
                Post Payroll {getSelectedEmployees().length>0&&'('+getSelectedEmployees().length+')'}
            </Button>
            </Grid>
            <Grid item xs={6}>
            <Button 
              variant="outlined" 
              color="primary"
              onClick={(e)=>toggleExistingPayrolls()}>
                Posted Payrolls
            </Button>
            </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
              <Checkbox
                indeterminate={getSelectedEmployees().length > 0 && getSelectedEmployees().length < searchedEmployees.length}
                checked={getSelectedEmployees().length > 0 && getSelectedEmployees().length === searchedEmployees.length}
                onChange={(e)=>handleSelectAllChange(e)}
                inputProps={{ 'aria-label': 'Select all employees' }}
              />
              </TableCell>
              <TableCell>
                <b>Employee Name</b>
              </TableCell>
              <TableCell>
                <b>Net Employee Balance</b>
              </TableCell>
              <TableCell>
                <b>Payments</b>
              </TableCell>
              <TableCell>
                <b>Payroll Expenses</b>
              </TableCell>
              <TableCell>
                <b>SS Expenses</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedEmployees.map((employee, key) => (
                <TableRow key={key}>
                   <TableCell>
                  <Checkbox
                    checked={employee.selected}
                    onChange={(e)=>handleSelectChange(e, employee)}
                    inputProps={{ 'aria-label': 'Select Employee' }}
                  />
                  </TableCell>
                  <TableCell>
                  <Button onClick={()=>toggleEmployee(employee)}>{employee.name}</Button>
                  </TableCell>
                  <TableCell>
                    {employee.accounts&&employee.accounts["Net Employee Balance"] || 0}
                  </TableCell>
                  <TableCell>
                    {employee.accounts&&employee.accounts['Employee Receivables'] || 0}
                  </TableCell>
                  <TableCell>
                    {employee.accounts&&employee.accounts['Employee Payroll Expense'] || 0}
                  </TableCell>
                  <TableCell>
                    {employee.accounts&&employee.accounts['Employee Social Security Expense'] || 0}
                  </TableCell>
                 {/*  <TableCell>
                  
                  <IconButton
                  title="Post Invoice"
                  aria-label="Post Invoice"
                  color="primary"
                  onClick={() => {    setSelectedSupplier(supplier);
                    togglePostInvoice(supplier);}}
                  >
                    <DescriptionIcon />
                  </IconButton>                  

                  {makePaymentVisible&&!makePaymentVisible[supplier._id]&&
                  <IconButton
                  title="Issue Payment"
                  aria-label="Issue Payment"
                  color="primary"
                  onClick={() => toggleMakePayment(supplier._id)}
                  >
                  <PaymentIcon />
                  </IconButton>}
                  {makePaymentVisible&&makePaymentVisible[supplier._id]&& 
                    <MakePaymentView 
                      supplier={supplier}
                      accounts={supplier.accounts} 
                      toggleMakePayment={toggleMakePayment}
                      makeSupplierPayment={makeSupplierPayment}
                    />}
                  </TableCell> */}
                 
                </TableRow>
              ))}
          </TableBody>
        </Table>
        </Fragment>}
      </Container>
  );
}