import { DATA } from "./data";
class Db {
  data;
  constructor(DATA) {
    this.data = DATA;
  }
  getData = () => {
    //console.log(this.data);
    return this.data;
  };

  updateProduct(entry) {
    let existingRecord = this.data.capex_products.find(
      (product) => product._id === entry._id
    );
    if (!existingRecord)
      existingRecord = this.data.inventory_products.find(
        (product) => product._id === entry._id
      );
    if (!existingRecord)
      existingRecord = this.data.usage_products.find(
        (product) => product._id === entry._id
      );
    existingRecord.category = entry.category;
    existingRecord.sub_category = entry.sub_category;
    //existingRecord.meta.manufacturer = entry.meta.manufacturer;
    existingRecord.selling_price = entry.selling_price;
    existingRecord.meta = entry.meta;
    existingRecord.short_name = entry.short_name;
    existingRecord.purchase_tax = entry.purchase_tax;
    existingRecord.sales_tax = entry.sales_tax;
    existingRecord.description = entry.description;
  }

  addProduct(entry) {
    console.log("adding entry",entry);
    this.data[entry.product_type].push(entry);
  }
  createNewPurchaseOrder(order) {
    order._supplierid = parseInt(order._supplierid);
    if(!order.line_items)
      order.line_items = [];
    this.data.purchase_orders.push(order);
  }
  updatePurchaseOrder(order) {
    //we can make ajax calls and get data
    const entry = this.data.purchase_orders.find((po) => po._id === order._id);
    entry.line_items.length = 0;
    order.line_items?.forEach((li) => entry.line_items.push(li));
    entry._supplierid = parseInt(order._supplierid);
    entry.created = order.created;
    entry.number = order.number;
    entry.description = order.description
    entry.status = order.status;
  }
}

const db = new Db(DATA);

export { db as Db };
