import React, { useCallback, useEffect, useState } from "react";
import moment from 'moment';
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import { 
  Grid,
  Tooltip,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  InputAdornment,
  Select,
  Container
} from "@material-ui/core";

import FloatingActionButton from "../../screens/FloatingActionButton";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Api } from "../../services/Api";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DescriptionIcon from "@material-ui/icons/DescriptionOutlined";

import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";

const Orders = (props) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [searchText, setSearchText] = useState("");
  const [orders, setOrders] = useState([]);
  const [searchedOrders, setSearchedOrders] = useState([]);
  const [suppliersObject, setSuppliersObject] = useState({});
  const [searchState, setSearchState] = useState({
    phrase: "",
    status: 'All',
    history: 'All'
  });
  const search = useCallback((search) => {
    const so = orders.filter((a) =>
      search && search.trim()
        ? a._id.toLowerCase().indexOf(search.toLowerCase()) !== -1
        : true
    );
    setSearchedOrders(so);
  }, []);

  const [currentShown, setCurrentShown] = useState(10);
  const showAtaTime = 10;

  const showMore = useCallback(()=>{
    setCurrentShown((prev) => {
      return prev + showAtaTime
    });
  },[]);

  useEffect(() => {
    Promise.all([
      Api.getPurchaseOrders(),
      Api.getSuppliers()
    ]).then(([orders, suppliers]) => {
      setOrders(orders.reverse());
      setSearchedOrders(orders);
      suppliers.forEach((supplier)=>{
        setSuppliersObject(old=>({...old, [supplier._id]: supplier}));
      });
    }).catch((e)=>{
      console.log(e);
    });
  }, []);

  useEffect(() => {
    let result = orders.filter(function (el) {
      let searchPhraseCondition = new RegExp(searchState.phrase, "i");
      let filters = [];

      if(searchState.phrase !==''){
        filters.push(searchPhraseCondition.test(suppliersObject[el._supplierid]?.short_name)
        ||filters.push(searchPhraseCondition.test(suppliersObject[el._supplierid]?.name)));
      }
        
      if (searchState.status !== 'All'){
        filters.push(el.status===searchState.status);
      }

      if (searchState.history !== 'All'){
        filters.push(getDatePastDays(moment(el.addedOn))<=searchState.history);
      }

      let filter = true;
      filters.forEach((f)=>{
        filter = filter && f 
      });

      return filter; 
    });
    setSearchedOrders(result);
  }, [searchState, orders]);

  function getDatePastDays(elDate, referenceDate = moment(new Date()).utc(true)){
    let days = referenceDate.diff(elDate, "days", true);
    return days;
  }

  function roundAmount(amount){
    let frac = 100;
    return Math.round((amount+Number.EPSILON)*frac) / frac;
  }

  function getTotalCost(line_items){
    let totalCost = 0.0;
    line_items?.forEach((item)=>{
      totalCost += item.total_cost;
    });
    return roundAmount(totalCost);
  }

  const handleKeyDown = useCallback(
    (e) => {
      //alert(e.target.value);
      if (e.which || e.keyCode) {
        const val = e.which || e.keyCode;
        if (val && val === 13) {
          e.preventDefault();
          search(searchText);
          return;
        } else if (val && val === 8 && !e.target.value) {
          e.preventDefault();
          search(searchText);
          return;
        }
      }
    },
    [searchText, search]
  );

  const handlePhraseChange = useCallback((e) => {
    setSearchState((prev) => {
      return {
        ...prev,
        phrase: e.target.value,
      };
    });
  }, []);

  const handleTimeChange = useCallback((e) => {
    setSearchState((prev) => {
      return {
        ...prev,
        history: e.target.value,
      };
    });
  }, []);

  const handleStatusChange = useCallback((e) => {
    setSearchState((prev) => {
      return {
        ...prev,
        status: e.target.value,
      };
    });
  }, []);

  return (
      <Container maxWidth="xl">
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center"
          style={{ margin: "0px" }}>
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Purchase Orders
            </Typography>
          </Grid>
          <Grid item>
            <FloatingActionButton
              title="Add a new purchase order"
              variant="contained"
              color="primary"
              handleClick={(e) => history.push(`${url}/add`)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={4}>
            <FormControl style={{width: '100%'}}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                label="Search Supplier Name"
                value={searchState.phrase}
                onChange={handlePhraseChange}
              >
  
            </TextField>
            </FormControl>
          </Grid>
          
          <Grid item xs={4}>
          <FormControl style={{width: '100%'}}>
            <InputLabel id="searchTimeLabel">History</InputLabel>
            <Select
              name="searchTime"
              id="searchTime"
              labelId="searchTimeLabel"
              fullWidth
              value={searchState.history}
              displayEmpty
              onChange={handleTimeChange}
            >
              <MenuItem value="All">
                All Time
              </MenuItem>
              <MenuItem value={1}>
                Past Day
              </MenuItem>
              <MenuItem value={3}>
                Past 3 Days
              </MenuItem>
              <MenuItem value={7}>
                Past 7 Days
              </MenuItem>
              <MenuItem value={14}>
                Past 14 Days
              </MenuItem>
              <MenuItem value={30}>
                Past Month
              </MenuItem>
              <MenuItem value={90}>
                Past 3 Months
              </MenuItem>
              <MenuItem value={182}>
                Past 6 Months
              </MenuItem>
              <MenuItem value={365}>
                Past Year
              </MenuItem>
              {/* {categories.map((c, index) => {
                return (
                  <MenuItem value={c._id} key={index}>
                    {c.name}
                  </MenuItem>
                );
              })} */}
            </Select>
          </FormControl>
          </Grid>
          
          <Grid item xs={4}>
          <FormControl style={{width: '100%'}}>
            <InputLabel id="statusLabel">Status</InputLabel>
            <Select
              name="searchStatus"
              id="searchStatus"
              labelId="statusLabel"
              fullWidth
              value={searchState.status}
              displayEmpty
              onChange={handleStatusChange}
            >
              <MenuItem value="All">
                All
              </MenuItem>
              <MenuItem value="New">
                New
              </MenuItem>
              <MenuItem value="Approved">
                Approved
              </MenuItem>
              <MenuItem value="Cancelled">
                Cancelled
              </MenuItem>
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={6}>{searchedOrders.length} found</Grid>
          </Grid>
        
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>PO Date</b>{" "}
              </TableCell>
              <TableCell>
                <b>Supplier</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>Tot. Cost</b>
              </TableCell>
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedOrders &&
              searchedOrders.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>{obj.addedOn}</TableCell>
                  <TableCell>{suppliersObject&&suppliersObject[obj._supplierid]&&suppliersObject[obj._supplierid].short_name}</TableCell>
                  <TableCell>{obj.status}</TableCell>
                  <TableCell>{getTotalCost(obj.line_items) }</TableCell>
                  <TableCell>
                    {obj.status === "New" && (
                      <Tooltip
                        title="Edit Order"
                        aria-label="edit"
                      >
                        <IconButton
                          variant="contained"
                          color="primary"
                          onClick={(e) =>
                            history.push(`${url}/edit/${obj._id}`)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                    <Tooltip
                      title="View Order"
                      aria-label="View"
                    >
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={(e) => history.push(`${url}/view/${obj._id}`)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      title="View Goods Received Note"
                      aria-label="grn"
                    >
                      <IconButton
                        variant="contained"
                        color="primary"
                        onClick={(e) => history.push(`${url}/grn/${obj._id}`)}
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Container>
  );
};
export default Orders;