import List from "./List";
import ManageSupplier from "./Manage";
import SupplierAccounts from "./Accounts";
import { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";

function Index() {
  const { params, url } = useRouteMatch();
  return <Switch>
    <Route path={`${url}/`} exact render={(props) => <List {...params} />} />
    <Route path={`${url}/billing`} exact render={(props) => <SupplierAccounts {...params} />} />
    <Route
      path={[`${url}/add`, `${url}/edit/:id`]}
      render={(props) => <ManageSupplier {...params} />}
    ></Route>
    <Route path="*" render={(props) => <Redirect to={`/404`} />}></Route>
  </Switch>;
}

export default Index;
