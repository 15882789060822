import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsIconActive from '@material-ui/icons/NotificationsActive';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  notificationsContainer: {
    minWidth: "500px",
    maxWidth: "500px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  notificationsRoot: {
    width: "100%",
    maxWidth: "55ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

export default function NotificationsPane(props) {
  const isMounted = React.useRef(null);
  const classes = useStyles();
  const messages = props.messages;
  const newMessageCount = props.newMessageCount;
  const setNewMessageCount = props.setNewMessageCount;

  React.useEffect(() => {
    isMounted.current = true;
    console.log('mounting notification pane')
    return (()=>{
      isMounted.current=false;
      setNewMessageCount(0);
    })
  }, [setNewMessageCount]);

  return (
    <Container className={classes.notificationsContainer}>
      <List className={classes.notificationsRoot}>
      {messages.slice(0).reverse().map((obj, key) => (
        <React.Fragment>
        <ListItem alignItems="flex-start">
        {key<newMessageCount? 
         <ListItemIcon>
         <NotificationsIconActive />
       </ListItemIcon>:
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>}
          
          <ListItemText
            primary={obj.error? "Error": "Success"}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {obj.msg}
                </Typography>
                {" — "+obj.ts}
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
        </React.Fragment>
     
      ))}
      </List>
    </Container>
  );
}
