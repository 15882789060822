import { Db } from "./Db";
//import flatten from "ramda/src/flatten";

import axios from 'axios';
import { KeyboardArrowUp } from "@material-ui/icons";
//import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
const apiUri = "https://cloudibs.com:8083"
export const Api = {
  /**Product Catalogue - Inventory*/
  getInventoryCategories: () => {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/categories/inventory").then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get inventory categories", e);
        reject(e);
      });
    });
  },
  addInventoryCategory: (entry) => {
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/categories/inventory", {state: entry}).then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("added inventory category", e);
        reject(e);
      });
    });
  },
  updateInventoryCategory: (entry) => {
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/categories/inventory/"+entry._id, {state: entry}).then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("updated inventory category", e);
        reject(e);
      });
    });
  },
  getInventoryProducts: () => {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/products/inventory").then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get inventory products", e);
        reject(e);
      });
    });
  },
  getInventoryProductById: (id) => {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/products/inventory/"+id)
      .then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get inventory product", e);
        reject(e)
      });
    });
  },
  saveProduct: (data) => {
    return new Promise((resolve, reject) => {
      const entry = { ...data };
      console.log("SAVE", entry);
      let url = apiUri+"/accounting/products/inventory";
      url = entry._id?url+"/"+ entry._id: url;
      axios.post(url, {state: entry})
      .then((response)=>{
        console.log("RESPONSE", response);
        resolve(response);
      }).catch((e) =>{
        console.log("cannot save or add product", e);
        reject(e);
      });   
      //setTimeout(() => resolve(), 1000);
    });
  },
  getInventoryItemCount: (_inventoryProductid) =>{
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/products/inventory/count/"+_inventoryProductid)
      .then((response)=>{
        console.log("Product Count", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get inventory product count", e);
        reject(e)
      });
    });
  },
  getAvailableItemsForSale: (_inventoryProductid) =>{
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/products/inventory/available/"+_inventoryProductid)
      .then((response)=>{
        console.log("Product Items Available", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get inventory product items", e);
        reject(e)
      });
    });
  },

  /**Product Catalogue - Capex*/
  getCapexCategories: () => {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/categories/capex").then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get capex categories", e);
        reject(e);
      });
    });
  },
  addCapexCategory: (entry) => {
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/categories/capex", {state: entry}).then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("added capex category", e);
        reject(e);
      });
    });
  },
  updateCapexCategory: (entry) => {
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/categories/capex/"+entry._id, {state: entry}).then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("updated capex category", e);
        reject(e);
      });
    });
  },
  getCapexProducts: () => {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/products/capex").then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get capex products", e);
        reject(e);
      });
    });
  },
  getCapexProductById: (id) => {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/products/capex/"+id)
      .then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get capex product", e);
        reject(e)
      });
    });
  },
  saveCapexProduct: (data) => {
    return new Promise((resolve, reject) => {
      const entry = { ...data };
      console.log("SAVE", entry);
      let url = apiUri+"/accounting/products/capex";
      url = entry._id?url+"/"+ entry._id: url;
      axios.post(url, {state: entry})
      .then((response)=>{
        console.log("RESPONSE", response);
        resolve(response);
      }).catch((e) =>{
        console.log("cannot save or add product", e);
        reject(e);
      });   
    });
  },
  getCapexItemCount: (_capexproductid) =>{
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/products/capex/count/"+_capexproductid)
      .then((response)=>{
        console.log("Product Count", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get capex product count", e);
        reject(e)
      });
    });
  },
  getCapexProductItems: (_capexproductid) =>{
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/products/capex/all/"+_capexproductid)
      .then((response)=>{
        console.log("Product Items", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get capex product items", e);
        reject(e)
      });
    });
  },

  /**Product Catalogue - Usage*/
  getUsageCategories: () => {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/categories/usage").then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get usage categories", e);
        reject(e);
      });
    });
  },
  addUsageCategory: (entry) => {
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/categories/usage", {state: entry}).then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("added usage category", e);
        reject(e);
      });
    });
  },
  updateUsageCategory: (entry) => {
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/categories/usage/"+entry._id, {state: entry}).then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("updated usage category", e);
        reject(e);
      });
    });
  },
  getUsageProducts: () => {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/products/usage").then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get usage products", e);
        reject(e);
      });
    });
  },
  getUsageProductById: (id) => {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/products/usage/"+id)
      .then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get usage product", e);
        reject(e)
      });
    });
  },
  saveUsageProduct: (data) => {
    return new Promise((resolve, reject) => {
      const entry = { ...data };
      console.log("SAVE", entry);
      let url = apiUri+"/accounting/products/usage";
      url = entry._id?url+"/"+ entry._id: url;
      axios.post(url, {state: entry})
      .then((response)=>{
        console.log("RESPONSE", response);
        resolve(response);
      }).catch((e) =>{
        console.log("cannot save or add product", e);
        reject(e);
      });   
    });
  },
  getUsageItemCount: (_usageproductid) =>{
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/products/usage/count/"+_usageproductid)
      .then((response)=>{
        console.log("Product Count", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get usage product count", e);
        reject(e)
      });
    });
  },
  getUsageProductItems: (_usageproductid) =>{
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/products/usage/all/"+_usageproductid)
      .then((response)=>{
        console.log("Product Items", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get usage product items", e);
        reject(e)
      });
    });
  },
  postRemainingItems: (_usageproductid, noi) =>{
    return new Promise((resolve, reject) => {
      let url = apiUri+"/accounting/products/usage/remaining/"+_usageproductid;
      axios.post(url, {noi: noi})
      .then((response)=>{
        console.log("RESPONSE", response);
        resolve(response);
      }).catch((e) =>{
        console.log("cannot post remaining items", e);
        reject(e);
      });   
    });
  },

  /**Purchase Orders */
  getPurchaseOrders: () => {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/purchasing/orders").then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get purchase orders", e);
        reject(e);
      });
    });
  },
  getPurchaseOrder: (id) => {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/purchasing/orders/"+id)
      .then((response)=>{
        console.log("PO RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get purchase order", e);
        reject(e)
      });
    });
  },
  savePurchaseOrder: (data) => {

    return new Promise((resolve, reject) => {
      const entry = { ...data };
      let url = apiUri+"/accounting/purchasing/orders";
      url = entry._id?url+"/"+ entry._id: url;
      console.log("SAVE", entry, url);
      axios.post(url, {state: entry})
      .then((response)=>{
        console.log("RESPONSE", response);
        resolve(response);
      }).catch((e) =>{
        console.log("cannot save or add order", e);
        reject(e);
      });   
    });
  },
  getPurchaseOrderCommands: () =>{
    return new Promise((resolve, reject) => {
      let url = apiUri+"/accounting/purchasing/commands/orders";
      console.log("GET Commands", url);
      axios.get(url)
      .then((response)=>{
        console.log("Command RESPONSE", response);
        resolve(response.data);
      }).catch((e) =>{
        console.log("cannot execute command at this time", e);
        reject(e);
      });   
    });
  },
  getPurchaseOrderStatus: (id) =>{
    return new Promise((resolve, reject) => {
      let url = apiUri+"/accounting/purchasing/orders/"+id+"/status";
      console.log("GET Status", url);
      axios.get(url)
      .then((response)=>{
        console.log("Command RESPONSE", response);
        resolve(response.data);
      }).catch((e) =>{
        console.log("cannot execute command at this time", e);
        reject(e);
      });   
    });
  },
  executePurchaseOrderCommand: (commandhuman, _poid) =>{
    return new Promise((resolve, reject) => {
      let url = apiUri+"/accounting/purchasing/commands/orders/"+_poid;
      console.log("Command", url);
      axios.post(url, {'commandhuman': commandhuman})
      .then((response)=>{
        console.log("Command RESPONSE", response);
        resolve(response);
      }).catch((e) =>{
        console.log("cannot execute command at this time", e);
        reject(e);
      });   
    });
  },
  getPurchaseOrderReceivedItems: (_poid) =>{
    return new Promise((resolve, reject) => {
      let url = apiUri+"/accounting/purchasing/orders/"+_poid+"/grn";
      console.log("GET Grn", url);
      axios.get(url)
      .then((response)=>{
        console.log("Grn RESPONSE", response);
        resolve(response.data);
      }).catch((e) =>{
        console.log("cannot get items in store at this time", e);
        reject(e);
      });   
    });
  },
  receivePurchaseOrderItems: (_poid, product, noi) =>{
    return new Promise((resolve, reject) => {
      let url = apiUri+"/accounting/purchasing/orders/"+_poid+"/grn/receive";
      console.log("Grn Test", url);
      axios.post(url, {'product': product, 'noi':noi},)
      .then((response)=>{
        console.log("Grn RESPONSE", response);
        resolve(response.data);
      }).catch((e) =>{
        console.log("cannot post grn at this time", e);
        reject(e);
      }); 
    });
  },
  returnPurchaseOrderItem: (_poid, item) =>{
    return new Promise((resolve, reject) => {
      let url = apiUri+"/accounting/purchasing/orders/"+_poid+"/grn/return";
      axios.post(url, {'_itemid': item._id},)
      .then((response)=>{
        console.log("Grn Return RESPONSE", response);
        resolve(response.data);
      }).catch((e) =>{
        console.log("cannot post grn at this time", e);
        reject(e);
      }); 
    });
  },
  getPurchaseOrderInvoices: (_poid) =>{
    return new Promise((resolve, reject) => {
      let url = apiUri+"/accounting/purchasing/invoices/orders/"+_poid;
      console.log("GET invoices", url);
      axios.get(url)
      .then((response)=>{
        console.log("Grn RESPONSE", response);
        resolve(response.data);
      }).catch((e) =>{
        console.log("cannot get items in store at this time", e);
        reject(e);
      });   
    });
  },
  addInvoiceToPo(line_items, _poid){
    /* po.line_items.forEach((item)=>{
      item.invoiced_quantity = 5;
    }); */
    return new Promise((resolve, reject) => {
      let url = apiUri+"/accounting/purchasing/invoices/orders/"+_poid;
      axios.post(url, {'line_items': line_items})
      .then((response)=>{
        console.log("TEST INVOICE RESPONSE", response);
        resolve(response.data);
      }).catch((e) =>{
        console.log("cannot test invoice", e);
        reject(e);
      });
    });
  },
  invoiceDraftTransactions(_invoiceid){
    return new Promise((resolve, reject) => {
      let url = apiUri+"/accounting/purchasing/invoices/transactions/provisional";
      axios.post(url, {'_invoiceid': _invoiceid})
      .then((response)=>{
        console.log("TEST INVOICE Transactions", response);
        resolve(response.data);
      }).catch((e) =>{
        console.log("cannot test invoice transactions", e);
        reject(e);
      });
    });
  },

  /**Suppliers */
  getSuppliers() {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/suppliers").then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get suppliers", e);
        reject(e);
      });
    });
  },
  getApprovedSuppliers() {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/suppliers").then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get suppliers", e);
        reject(e);
      });
    });
    /* return Promise.resolve(
      Db.getData().suppliers.filter((sup) => sup.isApproved)
    ); */
  },
  getSupplierTags() {
    return Promise.resolve(["stationary supplier", "book supplier"]);
  },
  getSupplierById(id) {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/suppliers/"+id)
      .then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get supplier", e);
        reject(e)
      });
    });
  },
  saveSupplier(data) {
    return new Promise((resolve, reject) => {
      const entry = { ...data };
      let url = apiUri+"/accounting/suppliers";
      url = entry._id?url+"/"+ entry._id: url;
      console.log("SAVE", entry, url);
      axios.post(url, {state: entry})
      .then((response)=>{
        console.log("RESPONSE", response);
        resolve(response);
      }).catch((e) =>{
        console.log("cannot save or add order", e);
        reject(e);
      });   
    });
  },
  approveSupplier(_supplierid){
    return new Promise((resolve, reject) => {
      let url = apiUri+"/accounting/suppliers/approval/"+_supplierid;
      axios.post(url, {'command': 'approve'})
      .then((response)=>{
        console.log("RESPONSE", response);
        resolve(response);
      }).catch((e) =>{
        console.log("cannot approve supplier", e);
        reject(e);
      });   
    });
  },
  rejectSupplier(_supplierid){
    return new Promise((resolve, reject) => {
      let url = apiUri+"/accounting/suppliers/approval/"+_supplierid;
      axios.post(url, {'command': 'reject'})
      .then((response)=>{
        console.log("RESPONSE", response);
        resolve(response);
      }).catch((e) =>{
        console.log("cannot reject supplier", e);
        reject(e);
      });   
    });
  },
  getSupplierCharges(_supplierid){
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/suppliers/billing/charges/"+_supplierid).then((response)=>{
        console.log("Charges Response ", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get charges", e);
        reject(e);
      });
    });

  },
  updateSupplierCharges(_supplierid, charges){
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/suppliers/billing/charges/"+_supplierid, {charges:charges}).then((response)=>{
        console.log("Charges Response ", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get charges", e);
        reject(e);
      });
    });
  },
  makeSupplierPayment(_supplierid, amount){
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/purchasing/payments/issue",{
        "_supplierid": _supplierid,
        "paid_amount": amount
      }).then((response)=>{
        console.log("Supplier Payment Posted", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot make payment", e);
        reject(e);
      });
    });
  },
  getSupplierAccounts(_supplierid){
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/purchasing/accounts/suppliers/"+_supplierid).then((response)=>{
        console.log("accounts Response ", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get supplier accounts", e);
        reject(e);
      });
    });
  },
  postSupplierInvoice(_supplierid, charges){
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/suppliers/billing/invoices/post",{
        "_supplierid": _supplierid,
        "charges": charges
      }).then((response)=>{
        console.log("Supplier Invoices Posted", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot post invoice", e);
        reject(e);
      });
    });
  },

  getSupplierInvoices(supplier) {
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/suppliers/billing/invoices/"+supplier._id).then((response)=>{
        console.log("Supp Invoices Response ", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get invoices", e);
        reject(e);
      });
    });

  },
  getSupplierPayments(supplier){
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/suppliers/billing/payments/"+supplier._id).then((response)=>{
        console.log("Supp Payments Response ", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get payments", e);
        reject(e);
      });
    });
  },
  getSupplierRefunds(supplier){
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/suppliers/billing/refunds/"+supplier._id).then((response)=>{
        console.log("Supp Refunds Response ", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get refunds", e);
        reject(e);
      });
    });
  },
  voidSupplierInvoice(invoice){

  },

  issueSupplierRefund(supplier, amount, reason, method){
    let refundObject = {
      "_supplierid": supplier._id,
      "refund_amount": amount,
      "refund_reason": reason,
      "refund_method": method
    };
    console.log(refundObject);
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/suppliers/billing/refunds/"+supplier._id, refundObject).then((response)=>{
        console.log("Supplier Refund Issued", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot issue refund", e);
        reject(e);
      });
    });
  },


  
  /**Billing */
  getEnrollmentsBilling(_levelid, year) {
    return new Promise((resolve, reject) => {
      //if(!_levelid || _levelid==="Unenrolled") reject("NE");
      axios.get(apiUri+"/accounting/billing/tuition/"+year+"/"+_levelid).then((response)=>{
        console.log("RESPONSE", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get billing", e);
        reject(e);
      });
    });
  },
  getStudentAccounts(_studentid){
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/accounts/students/"+_studentid).then((response)=>{
        console.log("Student Accounts Response", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get student accounts", e);
        reject(e);
      });
    });
  },
  receiveStudentPayment(_studentid, amount){
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/billing/payments/receive",{
        "_studentid": _studentid,
        "paid_amount": amount
      }).then((response)=>{
        console.log("Student Payment Posted", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot receive payment", e);
        reject(e);
      });
    });
  },
  postStudentInvoices(students, year){
    
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/billing/invoices/post",{
        "students": students, "year": parseInt(year), "issuedOn": Date.now()
      }).then((response)=>{
        console.log("Student Invoices Posted", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot post invoices", e);
        reject(e);
      });
    });
  },
  getLevelCharges(_levelid){
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/billing/charges/"+_levelid).then((response)=>{
        console.log("Charges Response ", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get charges", e);
        reject(e);
      });
    });
  },
  updateLevelCharges(_levelid, charges){
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/billing/charges/"+_levelid, {charges:charges}).then((response)=>{
        console.log("Charges Response ", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get charges", e);
        reject(e);
      });
    });
  },
  getStudentInvoices(student){
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/billing/invoices/all/"+student._id).then((response)=>{
        console.log("Student Invoices Response ", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get invoices", e);
        reject(e);
      });
    });
  },
  getStudentPayments(student){
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/billing/payments/all/"+student._id).then((response)=>{
        console.log("Student Payments Response ", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get payments", e);
        reject(e);
      });
    });
  },
  getStudentRefunds(student){
    return new Promise((resolve, reject) => {
      axios.get(apiUri+"/accounting/billing/refunds/all/"+student._id).then((response)=>{
        console.log("Student Refunds Response ", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot get refunds", e);
        reject(e);
      });
    });
  },
  issueStudentRefund(student, amount, reason, method){
    let refundObject = {
      "_studentid": student._id,
      "refund_amount": amount,
      "refund_reason": reason,
      "refund_method": method
    };
    console.log(refundObject);
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/billing/refunds/issue", refundObject).then((response)=>{
        console.log("Student Refund Issued", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot issue refund", e);
        reject(e);
      });
    });
  },
  downloadStudentInvoiceWK(invoicehtml){
    //call the pdf service
    return new Promise((resolve, reject) => {
      axios.post("https://cloudibs.com:8585/invoice", {invoicehtml:invoicehtml},{responseType: "blob"}).then((response)=>{
        resolve(response.data);
      }).catch((e)=>{
        reject(e);
      });
    });
  },
  downloadStudentInvoice(invoicehtml){
    //call the pdf service
    let params = new URLSearchParams();
    console.log(invoicehtml);
    //let sampleHTML = "<html><head><title>SAMPLE</title></head><body style='font-family:NotoSansArabic'><h1>العربية الجميلة</h1><img src='https://cacticonsult.com/assets/images/logo-small.png' style='max-width:150px'/></body></html>"
    params.append('invoicehtml', invoicehtml);
    return new Promise((resolve, reject) => {
      axios.post("https://cloudibs.com:8989/Jpdf-0.0.1-SNAPSHOT/pdfservice", params,{responseType: "blob", headers: { "Content-Type": "application/x-www-form-urlencoded" }}).then((response)=>{
        resolve(response.data);
      }).catch((e)=>{
        reject(e);
      });
    });
  },
  voidStudentInvoice(invoice){
    return new Promise((resolve, reject) => {
      axios.post(apiUri+"/accounting/billing/invoices/void", {"_invoiceid":invoice._id}).then((response)=>{
        console.log("Invoice Voided ", response);
        resolve(response.data);
      }).catch((e)=>{
        console.log("cannot void invoice", e);
        reject(e);
      });
    });
  },
  
/**Employees */
getEmployees() {
  return new Promise((resolve, reject) => {
    axios.get(apiUri+"/accounting/employees").then((response)=>{
      console.log("RESPONSE", response);
      resolve(response.data);
    }).catch((e)=>{
      console.log("cannot get employees", e);
      reject(e);
    });
  });
},
getEmployeeById(id) {
  return new Promise((resolve, reject) => {
    axios.get(apiUri+"/accounting/employees/"+id)
    .then((response)=>{
      console.log("RESPONSE", response);
      resolve(response.data);
    }).catch((e)=>{
      console.log("cannot get employee", e);
      reject(e)
    });
  });
},
saveEmployee(data) {
  return new Promise((resolve, reject) => {
    const entry = { ...data };
    let url = apiUri+"/accounting/employees";
    url = entry._id?url+"/"+ entry._id: url;
    entry.payroll = {
      working_days: entry.working_days,
      paid_leave_days: entry.paid_leave_days,
      base_compensation: entry.base_compensation,
      overtime_hourly_rate: entry.overtime_hourly_rate,
      ss_company_deduction_rate: entry.ss_company_deduction_rate,
      ss_employee_deduction_rate: entry.ss_employee_deduction_rate
    }
    console.log("SAVE", entry, url);
    axios.post(url, {state: entry})
    .then((response)=>{
      console.log("RESPONSE", response);
      resolve(response);
    }).catch((e) =>{
      console.log("cannot save or add employee", e);
      reject(e);
    });   
  });
},
postEmployeePayrolls(payrolls){
  let url = apiUri+"/accounting/payroll/post";
  return new Promise((resolve, reject) => {
  axios.post(url, {payrolls: payrolls})
    .then((response)=>{
      console.log("Payroll Post Response", response);
      resolve(response);
    }).catch((e) =>{
      console.log("cannot post employee payrolls", e);
      reject(e);
    });
  });
},
getAllEmployeePayrolls(month, year){
  let url = apiUri+"/accounting/payroll/get/all";
  return new Promise((resolve, reject) => {
  axios.post(url, {})
    .then((response)=>{
      console.log("Get All Payrolls", response);
      resolve(response.data);
    }).catch((e) =>{
      console.log("cannot get employee payrolls", e);
      reject(e);
    });
  });
},
getEmployeePayrolls(employee){
  let url = apiUri+"/accounting/payroll/employees/"+employee._id;
  return new Promise((resolve, reject) => {
  axios.post(url, {})
    .then((response)=>{
      console.log("Get "+employee._id+" Payrolls", response);
      resolve(response.data);
    }).catch((e) =>{
      console.log("cannot get employee payrolls", e);
      reject(e);
    });
  });
},
getEmployeeAccounts(_employeeid){
  return new Promise((resolve, reject) => {
    axios.post(apiUri+"/accounting/accounts/employees/"+_employeeid).then((response)=>{
      console.log("accounts Response ", response);
      resolve(response.data);
    }).catch((e)=>{
      console.log("cannot get employee accounts", e);
      reject(e);
    });
  });
},
voidEmployeePayroll(payroll){
  return new Promise((resolve, reject) => {
    axios.post(apiUri+"/accounting/payroll/void",{_payrollid:payroll._id}).then((response)=>{
      console.log("void Response ", response);
      resolve(response.data);
    }).catch((e)=>{
      console.log("cannot void employee payroll", e);
      reject(e);
    });
  });
},
issueEmployeePayments(payrolls){
  return new Promise((resolve, reject) => {
    axios.post(apiUri+"/accounting/payroll/payments/issue",{
      "payrolls": payrolls,
    }).then((response)=>{
      console.log("Employee Payments Posted", response);
      resolve(response.data);
    }).catch((e)=>{
      console.log("cannot post payments", e);
      reject(e);
    });
  });
},
getEmployeePayments(employee){
  let url = apiUri+"/accounting/payroll/payments/retrieve";
  return new Promise((resolve, reject) => {
  axios.post(url, {_employeeid:employee._id})
    .then((response)=>{
      console.log("Get "+employee._id+" Payments", response);
      resolve(response.data);
    }).catch((e) =>{
      console.log("cannot get employee payments", e);
      reject(e);
    });
  });
},
getAllDepartments(){
  return new Promise((resolve, reject) => {
    axios.get(apiUri+"/accounting/departments/all",).then((response)=>{
      console.log("departments Response ", response);
      resolve(response.data);
    }).catch((e)=>{
      console.log("cannot get departments", e);
      reject(e);
    });
  });
},

/**
 * GL
 */
 getGLSnapshots(){
  return new Promise((resolve, reject) => {
    axios.get(apiUri+"/accounting/gl/snapshot",).then((response)=>{
      console.log("snapshot Response ", response);
      resolve(response.data);
    }).catch((e)=>{
      console.log("cannot get snapshots", e);
      reject(e);
    });
  });
},
getGLAccounts(){
  return new Promise((resolve, reject) => {
    axios.get(apiUri+"/accounting/gl/transactions/accounts",).then((response)=>{
      console.log("gl Response ", response);
      resolve(response.data);
    }).catch((e)=>{
      console.log("cannot get gl", e);
      reject(e);
    });
  });
},
createGlSnapshot(closingDate){
  return new Promise((resolve, reject) => {
    axios.post(apiUri+"/accounting/gl/snapshot",{closingDate: closingDate}).then((response)=>{
      console.log("create snapshot Response ", response);
      resolve(response.data);
    }).catch((e)=>{
      console.log("cannot create snapshot", e);
      reject(e);
    });
  });
},

  updateProductCategory: (id, category, type) => {
    const product = Db.getData()[type].find((p) => p._id === id);
    product.category = category;
  },
  updateProductSubCategory: (id, sub_category, type) => {
    const product = Db.getData()[type].find((p) => p._id === id);
    product.sub_category = sub_category;
  },
  isLoggedIn: () => {
    if (!localStorage.getItem("user")) return false;
    return true;
  },
  signIn: (user) => {
    localStorage.setItem("@user", JSON.stringify(user));
  },
  signOut: () => {
    localStorage.setItem("@user", JSON.stringify({ token: "" }));
  },
  
 

  /* updateOrderStatus: (id, status) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const order = Db.getData().purchase_orders.find(
          (po) => po._id === parseFloat(id)
        );
        order.status = status;
        resolve();
      }, 1000);
    });
  }, */

  /* getCapexProductTypeId: () => {
    return Db.getData().product_types.filter(pt => pt.name === "capex_products")[0]._id;
  },

  getInventoryProductTypeId: () => {
    return Db.getData().product_types.filter(pt => pt.name === "inventory_products")[0]._id;
  },

  getUsageProductTypeId: () => {
    return Db.getData().product_types.filter(pt => pt.name === "usage_products")[0]._id;
  },

  getAllCategories: () => {
    return Db.getData().categories;
  },

  getProductCategoriesById: (id) => {
    return Db.getData().categories.filter((c) => c.product_type === id);
  },
 */
 /*  getProductCategoriesByIdPromise: (id) => {
    const records = Db.getData()
      .categories.filter((c) => c.product_type === id)
      .map((a) => a.name);
    return Promise.resolve(records);
  },

  getAllSubCategories: () => {
    return Db.getData().sub_categories;
  },

  getSubCategoriesByCategoryById: (id) => {
    return Db.getData().sub_categories.filter((c) => c.category === id);
  }, */

  /* getSubCategoriesByCategoryByName: (name) => {
    const category = Db.getData().categories.find((c) => c.name === name);
    return Db.getData().sub_categories.filter(
      (c) => c.category === category._id
    );
  }, */

 /*  updateLineItemComment(order_id, line_item_id, comment) {
    const order = Db.getData().purchase_orders.find((p) => p._id === order_id);
    const lineItem = order.line_items.find((li) => li._id === line_item_id);
    lineItem.description = comment;
  }, */
};
