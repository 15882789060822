import { Fragment, useEffect, useState } from "react";
import { Api } from "../../../services/Api";
import Typography from "@material-ui/core/Typography";
import { 
  IconButton, 
  TextField, 
  Button,
  Chip,
  Tooltip,
  setRef,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from "@material-ui/icons/Done";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";

import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";

export default function InventoryCategories(props) {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [refresh, setRefresh] = useState(Date.now());
  const [addCategoryVisible, setAddCategoryVisible] = useState(false);
  const [newCategory, setNewCategory] = useState({});
  const toggleManageCategories = props.toggleManageCategories;
  const setOriginalCategories = props.setCategories;

  useEffect(() => {
    Promise.all([
      Api.getInventoryCategories(),
    ]).then(([cats]) => {
      setCategories(cats);
    }).catch((e)=>{
      console.log(e);
    });
  }, [refresh]);


  function toggleAddCategory(){
    setAddCategoryVisible((prev)=>{
      return !prev
    });
  }

  function updateNewCategory(key, value){
    setNewCategory((prev)=>{
      return {
        ...prev,
        [key]:value,
      }
    });
  }

  function addNewCategory(){
    if(newCategory.name && newCategory.name.length>0
      && newCategory.short_name && newCategory.short_name.length > 0
      && newCategory.unit_name && newCategory.unit_name.length > 0){
      /* let tempNewCategory = {...newCategory};
      let tempCategories = [...categories];
      tempCategories.push(tempNewCategory);
      setCategories(tempCategories);
      toggleAddCategory(); */
      Api.addInventoryCategory(newCategory).then((res)=>{
        console.log(res);
        toggleAddCategory();
        setNewCategory({});
        //setRefresh(Date.now());
        let tempCategories = [...categories];
        let tempCategory = {...newCategory};
        tempCategory._id = res._id;
        tempCategory.active = true;
        tempCategories.push(tempCategory);
        setCategories(tempCategories);
        setOriginalCategories(tempCategories);
      }).catch((e)=>{
        console.log(e);
      });
    }
  }

  function activateCategory(category, key){
    let entry = {...category};
    entry.active = true;
    Api.updateInventoryCategory(entry).then((res)=>{
      console.log(res);
      let tempCategories = [...categories];
      tempCategories[key].active = true;
      setCategories(tempCategories);
      setOriginalCategories(tempCategories);
    }).catch((e)=>{
      console.log(e);
    })
  }

  function deactivateCategory(category, key){
    let entry = {...category};
    entry.active = false;
    Api.updateInventoryCategory(entry).then((res)=>{
      console.log(res);
      let tempCategories = [...categories];
      tempCategories[key].active = false;
      setCategories(tempCategories);
      setOriginalCategories(tempCategories);

    }).catch((e)=>{
      console.log(e);
    });
  }

  /* function saveCategories(){
    Api.updateInventoryCategories(categories).then((res)=>{
      console.log(res);
      setOriginalCategories(categories);
      toggleManageCategories();
    }).catch((e)=>{
      console.log(e);
    })
  } */
  
  return (
        <Fragment>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
          <IconButton
            aria-label="Grn"
            onClick={() => toggleManageCategories()}>
            <CloseIcon />
          </IconButton>
          </Grid>
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Manage Inventory Categories
            </Typography>
          </Grid>
         <Grid item xs={6}>
         </Grid>
        </Grid>

         <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item xs={6}>{categories.length} found</Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {!addCategoryVisible&&
          <Grid item>
            <IconButton
              title="Add Charge"
              aria-label="done"
              onClick={() => toggleAddCategory()}
            >
              <AddIcon />
            </IconButton>
          
          </Grid>}

          {addCategoryVisible&&
          <Grid item>
          <Table><TableBody><TableRow>
            <TableCell>
            <TextField
            label="Category Name"
            variant="outlined"
            onChange={(e)=>updateNewCategory("name", e.target.value.trim())}
            />
            </TableCell>
            <TableCell>
            <TextField
            label="Category Short Name"
            variant="outlined"
            onChange={(e)=>updateNewCategory("short_name", e.target.value.trim())}
            />
            </TableCell>
            <TableCell>
            <TextField
            label="Category Unit"
            variant="outlined"
            onChange={(e)=>updateNewCategory("unit_name", e.target.value.trim())}
            />
            </TableCell>
            <TableCell>
            <IconButton
              title="Add Category"
              aria-label="done"
              onClick={() => addNewCategory()}
            >
              <DoneIcon />
            </IconButton>
            </TableCell>
            <TableCell>
            <IconButton
              title="Cancel"
              aria-label="cancel"
              onClick={() => toggleAddCategory()}
            >
              <CloseIcon />
            </IconButton>
            </TableCell>
          </TableRow></TableBody></Table>
          </Grid>
          } 
        </Grid>

        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell></TableCell> */}
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Short Name</b>
              </TableCell>
              <TableCell>
                <b>Unit Name</b>
              </TableCell>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories&&categories.map((obj, key) => (
                <TableRow key={key}>
                  {/* <TableCell>
                    <IconButton aria-label="remove" onClick={() => disableCategory(key)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell> */}
                  <TableCell>{obj.name || ''}</TableCell>
                  <TableCell>
                    {obj.short_name || ''}
                  </TableCell>
                  <TableCell>
                    {obj.unit_name || ''}
                  </TableCell>
                  <TableCell>
                    {obj.active ? (
                      <Chip color="primary" label="Active"></Chip>
                    ) : (
                      <Chip color="secondary" label="Not Active"></Chip>
                    )}
                  </TableCell>
                  <TableCell>
                    {!obj.active && (
                      <>
                        <Tooltip
                          title="Activate"
                          aria-label="activate"
                        >
                          <IconButton
                        variant="contained"
                        color="primary"
                        onClick={(e) => activateCategory(obj, key)}>

                          <ThumbUpIcon />
                        </IconButton>
                          
                        </Tooltip>
                      </>
                    )}
                    {obj.active && (
                      <Tooltip
                        title="Deactivate"
                        aria-label="deactivate"
                      >
                        <IconButton
                        variant="contained"
                        color="primary"
                        onClick={(e) => deactivateCategory(obj, key)}>
                        <ThumbDownIcon/>
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Grid container spacing={3} style={{marginTop:10}}
        direction="row"
        justify="flex-end"
        alignItems ="center">
          <Grid item>
            <Button onClick={(e)=>toggleManageCategories()}>
              Cancel
            </Button>
          </Grid>
          {/* <Grid item>
            <Button 
            variant="contained" 
            onClick={(e)=>saveCategories()}
            color="primary"
            >
              Save Categories
            </Button>
          </Grid> */}

      </Grid>
      </Fragment>
    );
}