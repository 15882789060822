import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import DrawerMenu from "../../screens/DrawerMenu";
import Header from "../../screens/Header";
import { DRAWERWIDTH } from "../../shared/constants";
import MainDash from "../Dashboard/Main";
//import ProductCodeListView from "components/Assignment/List";
import ManagePurchaseOrder from "../PurchaseOrder/Manage";
//import ManageProductCode from "components/Assignment/Manage";
//import { List, Manage } from "components/PurchaseOrder";
import Orders from "../PurchaseOrder/List";
import ViewPurchaseOrder from "../PurchaseOrder/View";
import PurchaseOrderGrn from "../PurchaseOrder/Grn";
import Supplier from "../Supplier";
import Billing from "../Billing";
import Employee from "../Employee";
import GeneralLedger from "../GL";

import { default as CapexList } from "../Product/Capex/List";
import { default as CapexManage } from "../Product/Capex/Manage";
import { default as InventoryList } from "../Product/Inventory/List";
import { default as InventoryManage } from "../Product/Inventory/Manage";
import { default as UsageList } from "../Product/Usage/List";
import { default as UsageManage } from "../Product/Usage/Manage";

//ManagePurchaseOrder

// const ManagePurchaseOrder = React.lazy(() =>
//   import("components/PurchaseOrder/Manage")
// );

// const ProductCodeListView = React.lazy(() =>
//   import("components/Assignment/List")
// );
//const MainDash = React.lazy(() => import("components/Dashboard/Main"));
// const ManageProductCode = React.lazy(() =>
//   import("components/Assignment/Manage")
// );
//const DrawerMenu = React.lazy(() => import("screens/DrawerMenu"));
//const Header = React.lazy(() => import("screens/Header"));
//const Orders = React.lazy(() => import("components/PurchaseOrder/List"));

//const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWERWIDTH,
    width: `calc(100% - ${DRAWERWIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: DRAWERWIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    padding: "12px",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  notificationsContainer: {
    minWidth: "500px",
    maxWidth: "500px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  notificationsRoot: {
    width: "100%",
    maxWidth: "55ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const [error, setError] = React.useState("");
  const isMounted = React.useRef(null);
  const propsUpdateUser = props.updateUserInformation;
  const propsUser = props.user;
  const propsLanguage = props.currentLanguage;
  const propsAvailableLangs = props.availableLangs;
  const propsSetLanguage = props.setCurrentLanguage;
  const propsSetCurrentTheme = props.setCurrentTheme;

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      // executed when unmount
      isMounted.current = false;
    };
  }, [propsUpdateUser, propsUser]);

  React.useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        if (error !== "") {
          setError("");
        }
      }
    }, 3000);
  }, [error]);

  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleDrawerClose = useCallback(() => {
    if (!open) setOpen(true);
    else setOpen(false);
  }, [open, setOpen]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header
        classes={classes}
        open={open}
        callback={handleDrawerOpen}
        availableLangs={propsAvailableLangs}
        currentLanguage={propsLanguage}
        setCurrentLanguage={propsSetLanguage}
        setCurrentTheme={propsSetCurrentTheme}
      ></Header>
      <React.Suspense fallback={<div>Loading</div>}>
        <Router>
          <GetDrawer
            classes={classes}
            updateUserInformation={props.updateUserInformation}
            open={open}
            currentLanguage={propsLanguage}
            getTranslatedText={props.getTranslatedText}
            callback={handleDrawerClose}
          />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Route exact path="/">
              <MainDash
                path="/"
                updateUserInformation={props.updateUserInformation}
                user={props.user}
                error={error}
                setError={setError}
              />
            </Route>
            <Route
              path="/products"
              render={({ match: { url }, location: { state }, ...rest }) => {
                return (
                  <>
                    <Route
                      path={`${url}/`}
                      exact
                      render={() => <Redirect to={`${url}/capex`} />}
                    />
                    <Route
                      path={[`${url}/capex`]}
                      render={({ match: { url, params, path } }) => {
                        return (
                          <>
                            <Route
                              path={`${url}/`}
                              exact
                              render={({ match: { url } }) => {
                                return <CapexList {...{ url, params, path }} />;
                              }}
                            ></Route>
                            <Route
                              path={[`${url}/edit/:id`, `${url}/add`, `${url}/clone/:id`]}
                              render={({ match: { url, params, path } }) => {
                                let isClone = url.indexOf("clone")<0?false:true;
                                return (
                                  <CapexManage {...{ url, params, path, isClone }} />
                                );
                              }}
                            ></Route>
                          </>
                        );
                      }}
                    ></Route>
                    <Route
                      path={[`${url}/inventory`]}
                      render={({ match: { url, params, path } }) => {
                        return (
                          <>
                            <Route
                              path={`${url}/`}
                              exact
                              render={({ match: { url } }) => {
                                return (
                                  <InventoryList {...{ url, params, path }} />
                                );
                              }}
                            ></Route>
                            <Route
                              path={[`${url}/edit/:id`, `${url}/add`, `${url}/clone/:id`]}
                              render={({ match: { url, params, path } }) => {
                                let isClone = url.indexOf("clone")<0?false:true;
                                return (
                                  <InventoryManage {...{ url, params, path, isClone }} />
                                );
                              }}
                            ></Route>
                          </>
                        );
                      }}
                      // component={ProductCodeListView}
                    ></Route>
                    <Route
                      path={[`${url}/usage`]}
                      render={({ match: { url, params, path } }) => {
                        return (
                          <>
                            <Route
                              path={`${url}/`}
                              exact
                              render={({ match: { url } }) => {
                                return <UsageList {...{ url, params, path }} />;
                              }}
                            ></Route>
                            <Route
                              path={[`${url}/edit/:id`, `${url}/add`, `${url}/clone/:id`]}
                              render={({ match: { url, params, path } }) => {
                                let isClone = url.indexOf("clone")<0?false:true;
                                return (
                                  <UsageManage {...{ url, params, path, isClone }} />
                                );
                              }}
                            ></Route>
                          </>
                        );
                      }}
                    ></Route>
                  </>
                );
              }}
            ></Route>
            <Route
              path="/purchaseorder"
              render={({ match: { url } }) => (
                <>
                  <Route path={`${url}/`} exact component={Orders} />
                  <Route
                    path={[`${url}/view/:id`, `${url}/edit/:id`, `${url}/grn/:id`, `${url}/add`]}
                    render={({ match: { url, params, path } }) => {
                      const isView = url.indexOf("view") !== -1;
                      const isGrn = url.indexOf("grn") !== -1;
                      if(isView) return <ViewPurchaseOrder {...{ url, params, path }} />
                      else if(isGrn) return <PurchaseOrderGrn {...{ url, params, path }} />
                      else return <ManagePurchaseOrder {...{ url, params, path }} />
                    }}
                  ></Route>
                </>
              )}
            ></Route>
            <Route
              path="/supplier"
              render={(props) => <Supplier {...props} />}
            ></Route>
            <Route
              path="/billing"
              render={(props) => <Billing {...props} />}
            ></Route>
            <Route
              path="/employee"
              render={(props) => <Employee {...props} />}
            ></Route>
             <Route
              path="/gl"
              render={(props) => <GeneralLedger {...props} />}
            ></Route>
          </main>
        </Router>
        {/* </ProvideAuth> */}
      </React.Suspense>
    </div>
  );
}

const GetDrawer = (props) => {
  const { classes, open, updateUserInformation, callback, currentLanguage, getTranslatedText } = props;
  const demo = React.useMemo(() => {
    console.log("get drawer called");
    return (
      <DrawerMenu
        classes={classes}
        updateUserInformation={updateUserInformation}
        currentLanguage={currentLanguage}
        getTranslatedText={getTranslatedText}
        open={open}
        callback={callback}
      ></DrawerMenu>
    );
  }, [open,currentLanguage]);
  return demo;
};
