import { Fragment, useCallback, useEffect, useReducer, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Api } from "../../services/Api";
import Typography from "@material-ui/core/Typography";
import { IconButton, TextField, Switch, Chip, FormControl, FormControlLabel, InputAdornment } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { Grid, Container } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import FloatingActionButton from "../../screens/FloatingActionButton";

import Checkbox from "@material-ui/core/Checkbox";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SearchIcon from "@material-ui/icons/Search"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

export default function Suppliers() {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [searchedSuppliers, setSearchedSuppliers] = useState([]);
  const [searchState, setSearchState] = useState({
    phrase: "",
    status: true,
  });
  const [currentShown, setCurrentShown] = useState(10);
  const showAtaTime = 10;

  useEffect(() => {
    Promise.all([
      Api.getSuppliers(),
    ]).then(([suppliers]) => {
      setSuppliers(suppliers.reverse());
      setSearchedSuppliers(suppliers);
    }).catch((e)=>{
      console.log(e);
    });
  }, []);

  useEffect(() => {
    let result = suppliers.filter(function (el) {
      let searchPhraseCondition = new RegExp(searchState.phrase, "i");
      let filters = [];
      let tagstext = el.tags?.join('/');
      //console.log(tagstext);

      if(searchState.phrase !==''){
        filters.push(searchPhraseCondition.test(el.short_name)
        || searchPhraseCondition.test(el.name) 
        || searchPhraseCondition.test(tagstext));
      }
      
      if (searchState.status === true){
        filters.push(el.isApproved&&el.isApproved===true)
      }

      let filter = true;
      filters.forEach((f)=>{
        filter = filter && f 
      });

      return filter; 
    });
    setSearchedSuppliers(result);
  }, [searchState, suppliers]);

  const handlePhraseChange = useCallback((e) => {
    setSearchState((prev) => {
      return {
        ...prev,
        phrase: e.target.value,
      };
    });
  }, []);

  const handleCheckChange = (e) => {
    let nextValue = (prev) => {
      return {
        ...prev,
        status: e.target.checked,
      };
    };
    setSearchState(nextValue);
  };

  const approveSupplier = (supplier) => {
    Api.approveSupplier(supplier._id).then((res)=>{
      toggleSupplierApproval(supplier._id, res.data.isApproved);
    }).catch((e)=>console.log(e));
  };

  const rejectSupplier = (supplier) => {
    Api.rejectSupplier(supplier._id).then((res)=>{
      toggleSupplierApproval(supplier._id, res.data.isApproved);
    }).catch((e)=>console.log(e));
  };

  const toggleSupplierApproval = (_supplierid, isApproved) =>{
    const supplierIndex = suppliers.findIndex(e=>e._id===_supplierid);
    let tempSuppliers = [...suppliers];
    let supp = {...tempSuppliers[supplierIndex]};
    supp.isApproved = isApproved;
    tempSuppliers[supplierIndex] = supp;
    setSuppliers(tempSuppliers);
  }

  const showMore = useCallback(()=>{
    setCurrentShown((prev) => {
      return prev + showAtaTime
    });
  },[]);

  return (
    <Container maxWidth="xl">
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center"
          style={{ margin: "0px" }}>
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Suppliers
            </Typography>
          </Grid>
          <Grid item>
            <FloatingActionButton
              variant="contained"
              color="primary"
              handleClick={(e) => history.push(`${url}/add`)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={6}>
            <FormControl style={{width: '100%'}}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                label="Search Supplier Name or Tags"
                value={searchState.phrase}
                onChange={handlePhraseChange}
              >
  
            </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
          <FormControl style={{width: '100%'}}>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckChange} checked={searchState.status} color="primary" name="approvedCheckbox" />}
              label="Show Approved Only"
            />
          </FormControl>
          </Grid>

        <Grid item xs={6}>{searchedSuppliers.length} found</Grid>
        </Grid>
        
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Short Name</b>
              </TableCell>
              <TableCell>
                <b> Name</b>
              </TableCell>
              <TableCell>
                <b>Tags</b>
              </TableCell>
              <TableCell>
                <b>Approved?</b>
              </TableCell>
              <TableCell>
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedSuppliers &&
              searchedSuppliers.map((obj, index) => (
                (index<currentShown) &&
                <TableRow key={index}>
                  <TableCell>{obj.short_name}</TableCell>
                  <TableCell>{obj.name}</TableCell>
                  <TableCell>{obj.tags&&obj.tags.join("/")}</TableCell>
                  <TableCell>
                    {obj.isApproved ? (
                      <Chip color="primary" label="Approved"></Chip>
                    ) : (
                      <Chip color="secondary" label="Not Approved"></Chip>
                    )}
                  </TableCell>
                  <TableCell>
                    {!obj.isApproved && (
                      <>
                        <Tooltip
                          title="Approve Supplier"
                          aria-label="approve"
                        >
                          <IconButton
                        variant="contained"
                        color="primary"
                        onClick={(e) => approveSupplier(obj)}>

                          <ThumbUpIcon />
                        </IconButton>
                          
                        </Tooltip>
                      </>
                    )}
                    {obj.isApproved && (
                      <Tooltip
                        title="Reject Supplier"
                        aria-label="reject"
                      >
                        <IconButton
                        variant="contained"
                        color="primary"
                        onClick={(e) => rejectSupplier(obj)}>
                        <ThumbDownIcon/>
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Edit Supplier" aria-label="edit">
                      <IconButton
                        variant="contained"
                        color="primary"
                        data-id={obj._id}
                        onClick={(e) => history.push(`${url}/edit/${obj._id}`)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {(currentShown < searchedSuppliers.length)&&
                 <TableRow>
                 <TableCell colSpan={5}>
                 <Tooltip
                      title="Show More Suppliers..."
                      aria-label="Show More"
                    >
                 <IconButton
                        variant="contained"
                        color="primary"
                        onClick={showMore}
                      >
                        <MoreHorizIcon />
                </IconButton>
                </Tooltip>
                  
                 </TableCell>
                 </TableRow>
              }
          </TableBody>
        </Table>
    </Container>
  );
}