import React from "react";
import clsx from "clsx";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
import LanguageIcon from "@material-ui/icons/Language";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Popover from "@material-ui/core/Popover";
import { DRAWERWIDTH } from "../shared/constants";
import NotificationsPane from "./NotificationPane";
import useSocket from 'use-socket.io-client';
import props from "ramda/src/props";

//const drawerWidth = 240;

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  notificationsContainer: {
    minWidth: '500px',
    maxWidth: "500px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  notificationsRoot:{
    width: '100%',
    maxWidth: '55ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Header({classes, open, callback, currentLanguage, setCurrentLanguage, setCurrentTheme}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMounted = React.useRef(null);
  const handleNotifcationsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleNotificationsClose = () => {
    setAnchorEl(null);
  };
  const handleLanguageSwitch = () => {
    if(currentLanguage==="en"){
      document.body.dir = "rtl";
      setCurrentLanguage("ar");
      setCurrentTheme(createMuiTheme({
        typography: {
          fontFamily: 'Tajawal',
          fontWeight: 'bolder'
        },
        direction:'rtl'}))

    }else{
      document.body.dir = "ltr";
      setCurrentLanguage("en");
      setCurrentTheme(createMuiTheme({direction:'ltr'}));
    }
  }
  const notificationsOpen = Boolean(anchorEl);
  const notificationsId = notificationsOpen ? "simple-popover" : undefined;
  
  const [connected, setConnected] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const [newMessageCount, setNewMessageCount] = React.useState(0);
  const apiUri = "wss://cloudibs.com:8083";
  const [socket] = useSocket(apiUri+"/user-notifications", {
    transports: ['websocket'],
    autoConnect: false,
  });

  socket.connect();
  
  React.useEffect(() => {
    
    isMounted.current = true;
    socket.on("disconnect",() => {
      if(isMounted){
        console.log('disconnect event');
        setConnected(false);
      }
    });

    socket.on("connect",() => {      
      if(isMounted){
        console.log('connect event');
        setConnected(true);
        socket.emit('JoinRoom', 'api_user');
      }
    });
    
    socket.on("SuccessNotification", (msg)=>{
      let newMsg = {msg: msg, error:false, ts: new Date()};
      setMessages(oldMsgs => [...oldMsgs, newMsg]);
      setNewMessageCount(n=>n+1);
    });
    
    socket.on("ErrorNotification", (msg)=>{
      let newMsg = {msg: msg, error:true, ts: new Date()};
      setMessages(oldMsgs => [...oldMsgs, newMsg]);
      setNewMessageCount(n=>n+1);
      console.log(newMessageCount);
    });

    return(()=>{
      console.log('unmounted!!');
      isMounted.current = false;
      socket.close();
    })
  }, []);

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={callback}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          Cacti Software - Accountant App
        </Typography>
        <IconButton color="inherit" onClick={handleLanguageSwitch}>
            <LanguageIcon />
        </IconButton>
        <IconButton color="inherit" onClick={handleNotifcationsClick}>
          <Badge badgeContent={newMessageCount>0?newMessageCount:null} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Popover
          id={notificationsId}
          open={notificationsOpen}
          anchorEl={anchorEl}
          onClose={handleNotificationsClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <NotificationsPane messages={messages} newMessageCount={newMessageCount} setNewMessageCount={setNewMessageCount}/>
        </Popover>
      </Toolbar>
    </AppBar>
  );
}