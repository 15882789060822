import { Fragment, useCallback, useEffect, useReducer, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Api } from "../../services/Api";
import Typography from "@material-ui/core/Typography";
import { 
  IconButton, 
  TextField, 
  Button, 
  Container, 
  MenuItem,
  Select,
  InputLabel,
  Switch, Chip, FormControl, FormControlLabel, InputAdornment, TableContainer } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add"

import FloatingActionButton from "../../screens/FloatingActionButton";

import Checkbox from "@material-ui/core/Checkbox";
import PaymentIcon from "@material-ui/icons/Payment";
import DescriptionIcon from "@material-ui/icons/Description";
import DoneIcon from "@material-ui/icons/Done"
import SearchIcon from "@material-ui/icons/Search"
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import ChargesView from './Charges';
import SupplierView from './Supplier';

const MakePaymentView = (props) =>{
  const defaultAmount = 0.01;
  const [amount, setAmount] = useState(defaultAmount);

  return(
    <Fragment>
      <TextField
        id="outlined-basic"
        variant="outlined"
        label="Amount Paid"
        defaultValue={defaultAmount}
        onChange={(e)=>setAmount(parseFloat(e.target.value.trim()))}
        onInput={(e) => {          
          if(!isNaN(e.target.value) && parseFloat(e.target.value)<0) e.target.value=defaultAmount;
          if(isNaN(e.target.value)) e.target.value=defaultAmount;
        }}
      ></TextField>
      <IconButton
       onClick={()=>props.makeSupplierPayment(props.supplier._id, amount)}>
        <DoneIcon />
      </IconButton>
      <IconButton
       onClick={()=>props.toggleMakePayment(props.supplier._id)}>
        <CloseIcon />
      </IconButton>
    </Fragment>
  )
}

const PostInvoiceView  = (props) =>{
  const selectedSupplier = props.selectedSupplier;
  const updateSupplierCharges = props.updateSupplierCharges;
  

  const [currentCharges, setCurrentCharges] = useState(selectedSupplier.charges);
  const [selectedCharges, setSelectedCharges] = useState([]);

  const [manageChargesVisible, setManageChargesVisible] = useState(false);

  useEffect(() => {
    updateSupplierCharges(selectedSupplier._id, currentCharges);
  }, [currentCharges]);

  function toggleManageCharges(){
    setManageChargesVisible((prev)=>{
      return !prev
    });
  }

  function toggleCharge (e, charge, key){
    let newCharge = {...charge, selected:e.target.checked?true:false};
    let newCurrCharges = [...currentCharges];
    newCurrCharges[key] = newCharge;
    setCurrentCharges(newCurrCharges);
    setSelectedCharges(newCurrCharges.filter((c)=>c.selected===true));
  }

  function postSupplierInvoice(){
    let tempCharges = [...selectedCharges];
    Api.postSupplierInvoice(selectedSupplier._id, tempCharges).then((res)=>{
      props.togglePostInvoice();
      props.updateSupplierAccounts(selectedSupplier._id);
    }).catch((e)=>console.log(e));
  }

  function getTotalChargesForInvoice(){
    let total = 0;
    selectedCharges.map((charge, key)=>{
      total = total + charge.amount + charge.vat_amount;
    });
    return total;
  }

  return (
   
      manageChargesVisible? 
        <ChargesView 
        toggleManageCharges={toggleManageCharges}
        setCurrentCharges={setCurrentCharges}
        selectedSupplier={selectedSupplier}
        >
        </ChargesView>
      :
      <Fragment>
         <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
          <IconButton
            aria-label="Grn"
            onClick={() => props.togglePostInvoice()}>
            <CloseIcon />
          </IconButton>
          </Grid>
          <Grid item>
          <Typography component="h2" variant="h6" color="primary">
          Post Supplier Invoice: {selectedSupplier.name}
          </Typography>
          </Grid>
         <Grid item xs={6}>
            <Button 
              variant="outlined" 
              color="primary"
              onClick={(e)=>setManageChargesVisible(true)} > Manage Supplier Charges
            </Button>
         </Grid>
        </Grid>
      
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
        Total Invoice Charges: {getTotalChargesForInvoice()}
        </Grid>
        </Grid>
      <Table>
      <TableHead>
        <TableRow>
          <TableCell>
          </TableCell>
          <TableCell><b>Charge Name</b></TableCell>
          <TableCell><b>Charge Amount</b></TableCell>
          <TableCell><b>Charge VAT Amount</b></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {currentCharges.map((charge, key)=>(
          <TableRow key={key}>
            <TableCell>
            <Checkbox
                onChange={(e)=>toggleCharge(e, charge, key)}
                inputProps={{ 'aria-label': 'Select Charge' }}
            />
            
            </TableCell>
            <TableCell>
              {charge.name}
            </TableCell>
            <TableCell>
              {charge.amount}
            </TableCell>
            <TableCell>
              {charge.vat_amount}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      </Table>
      <Grid container spacing={3} style={{marginTop:10}}
        direction="row"
        justify="flex-end"
        alignItems ="center">
          <Grid item>
            <Button onClick={(e)=>props.togglePostInvoice()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button 
            variant="contained" 
            onClick={(e)=>postSupplierInvoice()}
            color="primary"
            disabled={selectedCharges.length===0}
            >
              Post Supplier Invoice
            </Button>
          </Grid>

      </Grid>
      </Fragment>
  )
}

export default function SupplierAccounts() {
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  
  const [currentYear, setCurrentYear] = useState("2021");
  
  const [makePaymentVisible, setMakePaymentVisible] = useState({});
  const [postInvoiceVisible, setPostInvoiceVisible] = useState(false);
  const [supplierViewVisible, setSupplierViewVisible] = useState(false);

  const [searchedSuppliers, setSearchedSuppliers] = useState([]);
  const [searchPhrase, setSearchPhrase] = useState("");
  const showAtaTime = 10;

  const years = ["2021","2022"];
  
 
  useEffect(() => {
    Promise.all([
      Api.getSuppliers(),
    ]).then(([suppliers]) => {
      setSuppliers(suppliers.reverse());
      setSearchedSuppliers(suppliers);
    }).catch((e)=>{
      console.log(e);
    });
  }, []);

  const toggleMakePayment = (_supplierid) =>{
    setMakePaymentVisible((prev)=>{
      return {
        ...prev,
        [_supplierid]: !makePaymentVisible[_supplierid]
      }
    });
  }

  function getSupplierCharges(_supplierid){
    let supplierIndex = suppliers.findIndex(supplier=>supplier._id===_supplierid);

    return supplierIndex!==-1?suppliers[supplierIndex].charges:[]
  }

  function togglePostInvoice(supplier){
    setPostInvoiceVisible((prev)=>{
      return !prev
    });
  }

  function toggleSupplier(supplier){
    setSupplierViewVisible((prev)=>{
      return !prev
    });
  }

  /* const toggleSupplier = (supplier) => {
    setSelectedSupplier(supplier);
  } */
  

  const updateSupplierCharges = (_supplierid, charges)=>{
    
    let tempSuppliers = [...suppliers];
    let supplierIndex = tempSuppliers.findIndex(supplier=>supplier._id===_supplierid);
    let tempSupplier = {...tempSuppliers[supplierIndex]};
    tempSupplier.charges = charges;
    tempSuppliers[supplierIndex] = tempSupplier;
    setSuppliers(tempSuppliers);
  }

  const makeSupplierPayment = (_supplierid, amount) =>{
    Api.makeSupplierPayment(_supplierid, amount).then((res)=>{
      toggleMakePayment(_supplierid);
      updateSupplierAccounts(_supplierid);
    }).catch((e)=>console.log(e));
  }

  function updateSupplierAccounts(_supplierid){
    let supplierIndex = suppliers.findIndex(supplier=>supplier._id===_supplierid);
    Api.getSupplierAccounts(_supplierid).then((accounts)=>{
      let tempArr = [...suppliers];
      tempArr[supplierIndex].accounts = accounts;
      setSuppliers(tempArr);
    }).catch((e)=>console.log(e));
  }

  const handlePhraseChange = useCallback((e) => {
    setSearchPhrase(e.target.value);
  }, []);

  useEffect(() => {
    let result = suppliers.filter(function (el) {
      let searchPhraseCondition = new RegExp(searchPhrase, "i");
      let filters = [];
      let tagstext = el.tags?.join('/');
      //console.log(tagstext);

      if(searchPhrase !==''){
        filters.push(searchPhraseCondition.test(el.short_name)
        || searchPhraseCondition.test(el.name) 
        || searchPhraseCondition.test(tagstext));
      }

      let filter = true;
      filters.forEach((f)=>{
        filter = filter && f 
      });

      return filter;
    });
    setSearchedSuppliers(result);
  }, [searchPhrase, suppliers]);

  function getPurchasesAmount(accounts){
    let purchasesAmount = 0;
    if(accounts['Merchandise Inventory'])purchasesAmount+=accounts['Merchandise Inventory'];
    if(accounts['PPE'])purchasesAmount+=accounts['PPE'];
    return purchasesAmount;
  }
  return (
    <Container maxWidth="lg">
        {postInvoiceVisible?
        <PostInvoiceView 
          selectedSupplier={selectedSupplier}
          setSelectedSupplier={setSelectedSupplier}
          updateSupplierCharges={updateSupplierCharges}
          updateSupplierAccounts={updateSupplierAccounts}
          togglePostInvoice={togglePostInvoice}
        />
        :
        supplierViewVisible?
        <SupplierView
        toggleSupplier={toggleSupplier}
        selectedSupplier={selectedSupplier}
        updateSupplierAccounts={updateSupplierAccounts}
        />
        :
        <Fragment>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Supplier Billing
            </Typography>
          </Grid>
         <Grid item xs={6}>
         </Grid>
        </Grid>
        
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
          <FormControl style={{width: '100%'}}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                label="Search Supplier Name or Tags"
                value={searchPhrase}
                onChange={handlePhraseChange}
              />
          </FormControl>
          </Grid>

          {/* <Grid item xs={6}>
          <FormControl style={{width: '100%'}}>
          <InputLabel id="yearLabel">Year</InputLabel>
            <Select
              name="year"
              id="year"
              labelId="yearLabel"
              fullWidth
              displayEmpty
              defaultValue={"2021"}
              onChange={(e)=>setCurrentYear(e.target.value)}
            >
              
              {years.map((y, index) => {
                return (
                  <MenuItem value={y} key={index}>
                    {y}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          </Grid> */}

        </Grid>
         <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item xs={6}>{searchedSuppliers.length} found</Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
            <Grid item xs={6}>
            </Grid>
            <Grid item xs={6}>
            </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Supplier Name</b>
              </TableCell>
              <TableCell>
                <b>Net Supplier Balance</b>
              </TableCell>
              <TableCell>
                <b>Payments</b>
              </TableCell>
              <TableCell>
                <b>Expenses</b>
              </TableCell>
              <TableCell>
                <b>Purchases</b>
              </TableCell>
              <TableCell>
                <b>VAT Asset</b>
              </TableCell>
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchedSuppliers.map((supplier, key) => (
                <TableRow key={key}>
                  <TableCell>
                  <Button onClick={()=>{setSelectedSupplier(supplier); toggleSupplier(supplier);}}>{supplier.name}</Button>
                  </TableCell>
                  <TableCell>
                    {supplier.accounts&&supplier.accounts["Net Supplier Balance"] || 0}
                  </TableCell>
                  <TableCell>
                    {supplier.accounts&&supplier.accounts['Supplier Receivables'] || 0}
                  </TableCell>
                  <TableCell>
                    {supplier.accounts&&supplier.accounts['Supplier Expenses'] || 0}
                  </TableCell>
                  <TableCell>
                    {supplier.accounts&&getPurchasesAmount(supplier.accounts) || 0}
                  </TableCell>
                  <TableCell>
                    {supplier.accounts&&supplier.accounts['VAT Asset'] || 0}
                  </TableCell>
                  <TableCell>
                  
                  <IconButton
                  title="Post Invoice"
                  aria-label="Post Invoice"
                  color="primary"
                  onClick={() => {    setSelectedSupplier(supplier);
                    togglePostInvoice(supplier);}}
                  >
                    <DescriptionIcon />
                  </IconButton>                  

                  {makePaymentVisible&&!makePaymentVisible[supplier._id]&&
                  <IconButton
                  title="Issue Payment"
                  aria-label="Issue Payment"
                  color="primary"
                  onClick={() => toggleMakePayment(supplier._id)}
                  >
                  <PaymentIcon />
                  </IconButton>}
                  {makePaymentVisible&&makePaymentVisible[supplier._id]&& 
                    <MakePaymentView 
                      supplier={supplier}
                      accounts={supplier.accounts} 
                      toggleMakePayment={toggleMakePayment}
                      makeSupplierPayment={makeSupplierPayment}
                    />}
                  </TableCell>
                 
                </TableRow>
              ))}
          </TableBody>
        </Table>
        </Fragment>}
      </Container>
  );
}