export const DRAWERWIDTH = 240;
export const BASE_URL = "https://localhost:3000/";
export const MODE = "DEVELOPMENT";
export const ORDER_STATUS = {
  New: "New",
  //PendingApprovalForFM: "PendingApprovalForFM",
  //PendingApprovalForSupplier: "PendingApprovalForSupplier",
  //ApprovedBySupplier: "ApprovedBySupplier",
  Approve: "Approve",
  Cancel: "Cancel",
  Restart: "Restart"
};

export const ORDER_STATUS_DISPLAY_TEXT = {
  New: "New",
  //PendingApprovalForFM: "Pending Approval from Finance Manager",
  //PendingApprovalForSupplier: "Pending Approval From Supplier",
  //ApprovedBySupplier: "Approved by Supplier",
  Approve: "Approved",
  Cancel: "Cancelled",
}

export const ACCOUNT_TYPES = {
  "Student Payables": ['Liability', "Short Term"],
  "Employee Payables": ['Liability', "Short Term"],
  "Supplier Payables": ['Liability', "Short Term"],
  "Social Security Payables": ['Liability', "Short Term"],
  "VAT Payable on Revenue": ['Liability', "Short Term"],
  "Consumables/Supplies": ['Asset', "Short Term"],
  "Merchandise Inventory": ['Asset', "Short Term"],
  "Cash From Students": ['Asset', "Short Term"],
  "Cash To Supplier": ['Asset', "Short Term"],
  "Cash To Employees": ['Asset', "Short Term"],
  "VAT Asset": ['Asset', "Short Term"],
  "Student Receivables": ['Asset', 'Short Term'],
  "Supplier Receivables": ['Asset', 'Short Term'],
  "Employee Receivables": ['Asset', 'Short Term'],
  "PPE": ['Asset', 'Long Term'],
  "Accumulated Depreciation": ['Asset', 'Long Term'],
  "Revenue from Student": ["Revenue", "Operational"],
  "Employee Payroll Expense": ["Expense", "Operational"],
  "Employee Social Security Expense": ["Expense", "Operational"],
  "Consumables/Supplies Expense": ["Expense", "Operational"],
  "Cost of Goods Sold": ["Expense", "Operational"],
  "Supplier Expenses": ["Expense", "Operational"],
  "Depreciation Expense": ["Expense", "Depreciation"],
  "Closed Depreciation Expense": ["Expense", "Depreciation"],
  "Retained Earnings": ["Equity", "Retained Earnings"],
  "Provisional Retained Earnings": ["Equity", "Retained Earnings"]
}