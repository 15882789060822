import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { fade } from "@material-ui/core/styles/colorManipulator";
import QRCode from 'qrcode.react';

import CommentIcon from "@material-ui/icons/Comment";
import {
  Typography,
  Grid,
  Button,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  CircularProgress,
  FormControl,
  Chip,
  Avatar,
  Menu,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Api } from "../../services/Api";
import FullScreenDialog from "../../screens/FullScreenDialog";
import CommentDialog from "../../screens/CommentDialog";
//import ViewProductCode from "components/Assignment/View";
import { default as ViewCapexProduct } from "../Product/Capex/View";
import { default as ViewInventoryProduct } from "../Product/Inventory/View";
import { default as ViewUsageProduct } from "../Product/Usage/View";
import { ORDER_STATUS, ORDER_STATUS_DISPLAY_TEXT } from "../../shared/constants";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/AddCircleOutline'
import FilterListIcon from '@material-ui/icons/FilterList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { positions } from "@material-ui/system";
import Box from "@material-ui/core/Box";
import { Fragment } from "react";
import { filter } from "lodash";
import { product } from "ramda";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(0),
  },
  checkboxGrid: {
    border: `${fade(theme.palette.text.primary, 0.2)} 1px solid`,
    marginTop: "5px",
    padding: "10px",
  },
  formControl: {
    paddingLeft: "10px",
    marginTop: theme.spacing(0),
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
    marginLeft: "5px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const initialState = {
  line_items: [],
};

const statusColors = {
  'New': 'default',
  'Approved': 'primary',
  'Canceled': 'secondary',
  'Sent To Supplier': 'primary'
}

const PurchaseOrderGrn = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const purchaseOrderId = id;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(initialState);
  const [poTotal, setPOTotal] = useState(0);
  const [inventoryProducts, setInventoryProducts] = useState([]);
  const [capexProducts, setCapexProducts] = useState([]);
  const [usageProducts, setUsageProducts] = useState([]);


  const [itemsReceived, setItemsReceived] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [suppliersObject, setSuppliersObject] = useState({});
  
  const [receiveFormVisible, setReceiveFormVisible] = useState({});

  const [itemsReceivedVisible, setItemsReceivedVisible] = useState(false);
  const [invoicesVisible, setInvoicesVisible] = useState(false);

  const [selectedLineItem, setSelectedLineItem] = useState(null);

  function getProductName(item){
    let searchedProducts = item._inventoryproductid?inventoryProducts:item._capexproductid?capexProducts:usageProducts;
    let _productid = item._inventoryproductid?item._inventoryproductid:item._capexproductid?item._capexproductid:item._usageproductid;
    const foundProduct = searchedProducts.find(
      (s) => s._id === _productid
      );
      return foundProduct&&foundProduct.short_name?foundProduct.short_name:"";
  }

  function getReceivedCount(item){
    let filteredItems = itemsReceived.filter(i=>i._lineitemid==item._id);
    return filteredItems.length;
  }

  function getInvoicedCount(item){
    let filteredItems = itemsReceived.filter(i=>(i._supplierinvoiceid?true:false)&&i._lineitemid==item._id);
    return filteredItems.length;
  }

  function getInvoicedItemsInInvoice(item, _supplierinvoiceid){
    let filteredItems = itemsReceived.filter(i=>(i._supplierinvoiceid==_supplierinvoiceid)&&i._lineitemid==item._id);
    return filteredItems;
  }

  function getAllInvoicedItemsInInvoice(_supplierinvoiceid){
    let filteredItems = itemsReceived.filter(i=>(i._supplierinvoiceid==_supplierinvoiceid));
    return filteredItems;
  }

  function toggleReceiveItems(item){
    setReceiveFormVisible((prev)=>{
      return {
        ...prev,
        [item._id]: !receiveFormVisible[item._id]
      }
    });
  }

  function receiveItems (product, noi){
    Api.receivePurchaseOrderItems(purchaseOrderId, product, noi).then((res)=>{
      if(res!==""){
        setTimeout(()=>Api.getPurchaseOrderReceivedItems(purchaseOrderId).then((items)=>{
          setItemsReceived(items);
        }), 1000);
        toggleReceiveItems(product);
      }else{
        toggleReceiveItems(product);
      }
    }).catch(e=>console.error(e));
  }

  function toggleItemsReceived(item){
    setSelectedLineItem(item);
    setItemsReceivedVisible((prev)=>{
      return !prev
    });
  }

  function toggleInvoices(){
    setInvoicesVisible((prev)=>{
      return !prev
    });
  }

  function returnItem (item){
    Api.returnPurchaseOrderItem(purchaseOrderId, item).then((res)=>{
      let tempItems = [...itemsReceived];
      var index = tempItems.findIndex(i=>i._id===item._id);
      index>=0&&tempItems.splice(index,1);
      setItemsReceived(tempItems);
    });
  }

  function issueInvoice(line_items, toggleIssueInvoice){
    console.log(line_items);
    Api.addInvoiceToPo(line_items, order._id).then(res=>{
      Promise.all([
        purchaseOrderId?Api.getPurchaseOrderInvoices(purchaseOrderId):null,
        purchaseOrderId?Api.getPurchaseOrderReceivedItems(purchaseOrderId):null
      ]).then(([invoices, receivedItems]) => {
        setItemsReceived(receivedItems);
        setInvoices(invoices);
        toggleIssueInvoice();
      });
    }).catch(e=>console.error(e));
  }

  useEffect(() => {
    Promise.all([
      Api.getInventoryProducts(),
      Api.getCapexProducts(),
      Api.getUsageProducts(),
      Api.getSuppliers(),
      purchaseOrderId?Api.getPurchaseOrderInvoices(purchaseOrderId):null,
      purchaseOrderId?Api.getPurchaseOrderReceivedItems(purchaseOrderId):null,
      purchaseOrderId?Api.getPurchaseOrder(purchaseOrderId):null,
    ]).then(([inventoryProducts, capexProducts, usageProducts, suppliers, invoices, receivedItems, po]) => {
      suppliers.forEach((supplier)=>{
        setSuppliersObject(old=>({...old, [supplier._id]: supplier}));
      });
      setInventoryProducts(inventoryProducts);
      setCapexProducts(capexProducts);
      setUsageProducts(usageProducts);
      setItemsReceived(receivedItems);
      setInvoices(invoices);
      if(po)setOrder(po);
    }).catch((e)=>{
      console.log("Something went wrong while getting po", e);
    });
}, [purchaseOrderId]);

 
  return (
    <div>
      {loading && <CircularProgress />}

      {
      invoicesVisible?
        <InvoicesView 
        invoices={invoices} 
        itemsReceived={itemsReceived} 
        toggleInvoices={toggleInvoices}
        lineItems={order.line_items}
        getInvoicedItemsInInvoice={getInvoicedItemsInInvoice} 
        getProductName={getProductName}
        getInvoicedCount={getInvoicedCount}
        getReceivedCount={getReceivedCount}
        issueInvoice={issueInvoice}
        getAllInvoicedItemsInInvoice={getAllInvoicedItemsInInvoice}/>
      :itemsReceivedVisible?
        <ItemsReceivedView 
        itemsReceived={itemsReceived} 
        toggleItemsReceived={toggleItemsReceived}
        selectedLineItem={selectedLineItem} 
        returnItem={returnItem}
        />
      :
      <Fragment>
        <Grid container spacing={3}>
        <Grid item>
          <Typography component="h2" variant="h6" color="primary">
            Purchase Order Goods Received Note
          </Typography>
        </Grid>
        </Grid>
        
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              value={order._id || ''}
              id="outlined-basic"
              label="_id"
              variant="outlined"
              fullWidth
              disabled
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Added On"
              variant="outlined"
              fullWidth
              disabled
              value={order.addedOn || ''}
            ></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
              <TextField
                disabled
                id="outlined-basic"
                variant="outlined"
                label="Supplier"
                value={suppliersObject&&suppliersObject[order._supplierid]&&suppliersObject[order._supplierid].short_name || ''}
                fullWidth
              ></TextField>
          </Grid>
          <Grid item xs={6}>
              <TextField
                multiline
                name="description"
                id="outlined"
                label="Description"
                variant="outlined"
                fullWidth
                value={order.description || ''}
                disabled
                rows={4}
              ></TextField>
          </Grid>
        </Grid>


        <Button
          aria-label="Grn"
          onClick={() => toggleInvoices()}
          startIcon={<AttachmentIcon />}
        >
          {invoices&&invoices.length} Invoice{invoices&&invoices.length!==1&&"s"}
        </Button>
       
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Product Type</b>
              </TableCell>
              <TableCell>
                <b>Item</b>
              </TableCell>
              <TableCell>
                <b>Qty Ordered</b>
              </TableCell>
              <TableCell>
                <b>Qty Received</b>
              </TableCell>
              <TableCell>
                <b>Qty Invoiced</b>
              </TableCell>
              <TableCell>
                <b>Receive</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order?.line_items?.map((item, key) => (
              <TableRow key={key}>
                <TableCell>
                  {item&&item._inventoryproductid?'inventory':item._capexproductid?'capex':'usage'}
                </TableCell>
                <TableCell>
                  {item&&getProductName(item)}
                </TableCell>
                <TableCell>
                  {item&&item.quantity}
                </TableCell>
                <TableCell>
                  {itemsReceived&&getReceivedCount(item)}
                </TableCell>
                <TableCell>
                  {itemsReceived&&getInvoicedCount(item)}
                </TableCell>
                <TableCell>
                {receiveFormVisible&&!receiveFormVisible[item._id]&&
                <IconButton
                  aria-label="Grn"
                  onClick={() => toggleReceiveItems(item)}
                >
                  <AddIcon />
                </IconButton>
                }
                {receiveFormVisible&&receiveFormVisible[item._id]&& 
                <ReceiveGoodsView 
                  lineitem={item} 
                  receiveItems={receiveItems}
                  getReceivedCount={getReceivedCount}
                  toggleReceiveItems={toggleReceiveItems}
                />}
                 <IconButton
                  aria-label="Grn"
                  onClick={() => toggleItemsReceived(item)}
                >
                  <FilterListIcon />
                </IconButton>

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button onClick={(e) => history.push("/purchaseorder")}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    }
    </div>
  );
};

const ReceiveGoodsView = ({lineitem, receiveItems, getReceivedCount, toggleReceiveItems})=>{
  const defaultNoi = lineitem.quantity-getReceivedCount(lineitem);
  const [noi, setNoi] = useState(defaultNoi);

  return(
    <Fragment>
      <TextField
        id="outlined-basic"
        variant="outlined"
        label="Number of Items Received"
        disabled={defaultNoi===0?true:false}
        defaultValue={defaultNoi}
        onChange={(e)=>setNoi(parseFloat(e.target.value.trim()))}
        onInput={(e) => {
          e.target.value = Math.max(0, parseInt(e.target.value.trim())).toString();
          if(isNaN(e.target.value)) e.target.value=defaultNoi;
          if(!isNaN(e.target.value) && parseInt(e.target.value)>defaultNoi) e.target.value=defaultNoi;
        }}
      ></TextField>
      <IconButton
       disabled={defaultNoi===0||!noi>0?true:false}
       onClick={()=>receiveItems(lineitem, noi)}>
        <DoneIcon />
      </IconButton>
      <IconButton
       onClick={()=>toggleReceiveItems(lineitem)}>
        <CloseIcon />
      </IconButton>
    </Fragment>
  )
}

const ItemsReceivedView = ({itemsReceived, toggleItemsReceived, selectedLineItem, returnItem}) =>{
  return(
    <Fragment>
        <Grid container spacing={3} alignItems="center">
        <Grid item>
        <IconButton
          aria-label="Grn"
          onClick={() => toggleItemsReceived()}>
          <CloseIcon />
        </IconButton>
        </Grid>
        <Grid item>
          <Typography component="h2" variant="h6" color="primary">
            Purchase Order Received Goods
          </Typography>
        </Grid>
        </Grid>
        
        <Grid container spacing={3}>
        <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Item ID</b>
              </TableCell>
              <TableCell>
                <b>Purchase Cost</b>
              </TableCell>
              <TableCell>
                <b>Purchase VAT</b>
              </TableCell>
              <TableCell>
                <b>Return</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemsReceived?.map((item, key) => (
              selectedLineItem._id===item._lineitemid&&
              <TableRow key={key}>
                <TableCell>
                  <QRCode value={item._id||''} />
                </TableCell>
                <TableCell>
                  {item&&item.purchase_cost}
                </TableCell>
                <TableCell>
                  {item&&item.purchase_vat_amount}
                </TableCell>
                <TableCell>
                <IconButton
                  aria-label="Grn"
                  onClick={() => returnItem(item)}
                >
                  <CloseIcon />
                </IconButton>

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </Grid>
        </Grid>
    </Fragment>
    
  )
}

const InvoicesView = ({invoices, toggleInvoices, lineItems, getInvoicedItemsInInvoice, getProductName,
  getInvoicedCount, getReceivedCount, issueInvoice, getAllInvoicedItemsInInvoice}) =>{
  
  const classes = useStyles();
  const [issueInvoiceVisible, setIssueInvoiceVisible] = useState(false);
  const [newLineItems, setNewLineItems] = useState(lineItems);
  const [issueButtonDisabled, setIssueButtonDisabled] = useState(true);

  useEffect(() => {
    let disabled=false;
    let totalItems = 0;
    newLineItems?.map((item, key) => {
      const maxItems = getMaxToInvoice(item);
      if(maxItems>0&&(item.invoiced_quantity>maxItems||item.invoiced_quantity<0))
        disabled=true;
      else
        totalItems += item.invoiced_quantity
    });
    if(totalItems===0) disabled= true;
    setIssueButtonDisabled(disabled);
  }, [newLineItems]);
    
  function toggleIssueInvoice(){
    let tempItems = [...newLineItems];
    tempItems.map((item,key)=>{
      item.invoiced_quantity = getMaxToInvoice(item);
    });
    setNewLineItems(tempItems);
    setIssueInvoiceVisible((prev)=>{
      return !prev
    });
  }

  //const [draftTransactionsVisible, setDraftTransactionsVisible] = useState({});
  //const [draftTransactions, setDraftTransactions] = useState({});
 /*  function toggleInvoiceTransactions(_invoiceid){
    getDraftTransactions(_invoiceid);
    setDraftTransactionsVisible((prev)=>{
      return {...prev, [_invoiceid]: !prev[_invoiceid]}
    })
  } */

  function getInvoicedProductAmount(item, _invoiceid){
    let items = getInvoicedItemsInInvoice(item, _invoiceid);
    let productAmount = 0;
    items.forEach((pitem)=>{
      productAmount += pitem.purchase_cost;
    });
    return productAmount;
  }

  function getInvoicedVATAmount(item, _invoiceid){
    let items = getInvoicedItemsInInvoice(item, _invoiceid);
    let vatAmount = 0;
    items.forEach((pitem)=>{
      vatAmount += pitem.purchase_vat_amount;
    });
    return vatAmount;
  }

  function getMaxToInvoice(item){
    return getReceivedCount(item) - getInvoicedCount(item)
  }
  
  function getInvoiceTotalAmounts(_invoiceid){
    let filtered = getAllInvoicedItemsInInvoice(_invoiceid);
    let totalPurchaseAmount = 0;
    let totalVatAmount = 0;

    filtered.forEach((item)=>{
      totalPurchaseAmount += item.purchase_cost;
      totalVatAmount += item.purchase_vat_amount;
    });

    return {
      totalPurchaseAmount: totalPurchaseAmount,
      totalVatAmount: totalVatAmount
    }
    
  }

  function handleInvoicedQuantityChange(item, value){
    let tempItems = [...newLineItems];
    let tempItemIndex = tempItems.findIndex(i=>i._id===item._id);
    if(isNaN(value) || parseFloat(value)<0 || (!parseFloat(value)&&!(parseFloat(value)===0))){
      value=-1
    }else{
      value = parseFloat(value);
    }
    tempItems[tempItemIndex].invoiced_quantity = value;
    setNewLineItems(tempItems);
  }

 /*  function getDraftTransactions(_invoiceid){
    Api.invoiceDraftTransactions(_invoiceid).then(res=>{
      //console.log(res);
      setDraftTransactions((prev)=>{
        return {
          ...prev,
          [_invoiceid]: res
        }
      })
    }).catch(e=>console.log(e));
  } */

  return(
    <Fragment>
        <Grid container spacing={3} alignItems="center">
        <Grid item>
        <IconButton
          aria-label="Grn"
          onClick={() => toggleInvoices()}>
          <CloseIcon />
        </IconButton>
        </Grid>
        <Grid item>
          <Typography component="h2" variant="h6" color="primary">
            Invoices for This Purchase Order
          </Typography>
        </Grid>
        </Grid>
        <Grid container spacing={3}>
         <Grid item xs={12}>
        <Button
          aria-label="Grn"
          onClick={() => toggleIssueInvoice()}
          startIcon={<AddIcon />}
        >
          Issue Invoice
        </Button>
        </Grid>
        {issueInvoiceVisible&& <Grid item xs={12}>
        <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Item</b>
                  </TableCell>
                  <TableCell>
                    <b>Maximum to Invoice</b>
                  </TableCell>
                  <TableCell>
                    <b>Quantity to Invoice</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newLineItems?.map((item, key) => (
                  //getMaxToInvoice(item)>0&&
                  <TableRow key={key}>
                    <TableCell>
                      {getProductName(item)}
                    </TableCell>
                    <TableCell>
                      {getMaxToInvoice(item)}
                    </TableCell>
                    <TableCell>
                     <TextField 
                     variant="outlined"
                     label="Items Invoiced"
                     defaultValue={item.invoiced_quantity}
                     /* value={item.invoiced_quantity} */
                     onChange={(e)=> handleInvoicedQuantityChange(item, e.target.value.trim())}
                     />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <IconButton
            disabled={issueButtonDisabled}
            onClick={()=>issueInvoice(newLineItems, toggleIssueInvoice)}>
              <DoneIcon />
            </IconButton>

        </Grid>}
        
        </Grid>
        {invoices.map((invoice, key)=>(
         <Grid container spacing={3} key={key}>
         <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Invoice issued on {invoice.issuedOn} </Typography>
              <Typography className={classes.secondaryHeading}>
                Total {getInvoiceTotalAmounts(invoice._id).totalPurchaseAmount + getInvoiceTotalAmounts(invoice._id).totalVatAmount}
                {' (Purchases Amount: ' +  getInvoiceTotalAmounts(invoice._id).totalPurchaseAmount} VAT {getInvoiceTotalAmounts(invoice._id).totalVatAmount})
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Grid container spacing={3}>
            <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Item</b>
                  </TableCell>
                  <TableCell>
                    <b>Qty Invoiced</b>
                  </TableCell>
                  <TableCell>
                    <b>Product Amount</b>
                  </TableCell>
                  <TableCell>
                    <b>VAT Amount</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lineItems?.map((item, key) => (
                  getInvoicedItemsInInvoice(item, invoice._id).length>0&&<TableRow key={key}>
                    <TableCell>
                      {getProductName(item)}
                    </TableCell>
                    <TableCell>
                      {getInvoicedItemsInInvoice(item, invoice._id).length}
                    </TableCell>
                    <TableCell>
                      {getInvoicedProductAmount(item, invoice._id)}
                    </TableCell>
                    <TableCell>
                      {getInvoicedVATAmount(item, invoice._id)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </Grid>
            
            {/* <Grid item xs={12}>
            <IconButton
              onClick={()=>toggleInvoiceTransactions(invoice._id)}>
              <AutorenewIcon />
            </IconButton>
            </Grid>
            {draftTransactionsVisible[invoice._id]&&
            <Grid item xs={12}>
              

              {draftTransactions[invoice._id]?.map((lineitem, key)=>(
                <Grid item xs={12} key={key}>
                  <Typography variant="h6">Draft Transaction: {getProductName(lineitem.lineitem)} / {getInvoicedItemsInInvoice(lineitem.lineitem, invoice._id).length}</Typography>
                  <Table>
                  <TableHead>
                  <TableRow>
                  <TableCell>
                    <b>Account Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Type</b>
                  </TableCell>
                  <TableCell>
                    <b>Amount</b>
                  </TableCell>
                  </TableRow>
                  </TableHead>
                  <TableBody>
                  {lineitem&&lineitem.entries&&lineitem.entries.map((entry, ekey)=>(
                  <TableRow key={ekey}>
                    <TableCell>
                    {entry.account_name}
                    </TableCell>
                    <TableCell>
                    {entry.type}
                    </TableCell>
                    <TableCell>
                    {entry.amount}
                    </TableCell>
                  </TableRow>
                  ))}
                   </TableBody>
                   </Table>
                </Grid>

              ))
              }
              
            </Grid>
            } */}

            </Grid>
            
            
            </AccordionDetails>
          </Accordion>
          </Grid>
          </Grid>
        ))}
       
    </Fragment>
    
  )
}

export default PurchaseOrderGrn;