import React, { memo, useRef, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { FormControl, Grid, TextField } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CommentDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const { field, closeHandler, setValue, disabled } = props;
  const [comment, setComment] = useState("");

  let textContentRef = React.useRef(null);
  //   const textContentRef = memo(() => {
  //       alert('memo');
  //       return React.createRef();
  //   }, []);
  //const textContentRef = React.createRef();
  // React.useLayoutEffect(() => {
  //   if (props.open && props.field) {
  //     if (textContentRef.current) {
  //       textContentRef.current.value = field.description;
  //     }
  //   }
  // });

  function handleChange(e) {
    setComment(e.target.value);
  }

  // React.useEffect(() => {
  //   return () => alert("dialog disposed");
  // }, []);

  React.useEffect(() => {
    if (props.open) {
      setComment(field.description);
    }
    setOpen(props.open);
    // if (props.open) {
    //   if (textContentRef.current && field)
    //     textContentRef.current.value = field.description;
    // }
  }, [props.open, field]);

  const handleClickOpen = () => {
    //setOpen(true);
  };
  // const handleClose = () => {
  //   //setOpen(false);
  //   closeHandler({
  //     reason: "ok",
  //     value: textContentRef.current.value,
  //     ...props,
  //   });
  // };

  const handleClose = () => {
    //setOpen(false);
    closeHandler({
      reason: "ok",
      value: comment,
      ...props,
    });
  };

  const handleCancel = () => {
    closeHandler({ reason: "cancel" });
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog
        onClose={handleCancel}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCancel}>
          Comment
        </DialogTitle>

        <DialogContent dividers>
          {/* <Typography gutterBottom>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
            in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          </Typography>
          <Typography gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
            lacus vel augue laoreet rutrum faucibus dolor auctor.
          </Typography>
          <Typography gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel
            scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus
            auctor fringilla.
          </Typography> */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                // className={classes.margin}
                variant="outlined"
              >
                <TextField
                  multiline
                  name="description"
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  fullWidth
                  rows={3}
                  // defaultValue=""
                  value={comment}
                  disabled={disabled ? true : false}
                  // inputRef={textContentRef}
                  onChange={handleChange}
                ></TextField>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
