import React, { useCallback } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { DATA as data, Db } from "../../services/data";
// import { useAuth } from "shared/helpers/RouteHelper";
// import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  // let auth = useAuth();
  // const location = useLocation();
  // const history = useHistory();

  // let login = useCallback(() => {
  //   let { from } = location.state || { from: { pathname: "/" } };
  //   auth.signin(() => {
  //     history.replace(from);
  //   });
  // }, [auth]);

  const signIn = (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            You seem to be signed out, sign in?
          </Typography>
          <form className={classes.form} noValidate>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => signInUser()}
              // onClick={login}
            >
              Sign In
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );

  const signInUser = () => {
    props.updateUserInformation({ token: "TEST" });
  };

  return <div>{signIn}</div>;
}
