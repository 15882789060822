import { ORDER_STATUS } from "../shared/constants";

const DATA = {
  product_types: [
    { name: "capex_products", _id: 1 },
    { name: "inventory_products", _id: 2 },
    { name: "usage_products", _id: 3 },
  ],
  _schoolid: 1,
  categories: [
    { name: "Vehicles", _id: 1, product_type: 1 },
    { name: "Electronic Equipment", _id: 2, product_type: 1 },
    { name: "Books for Sale", _id: 3, product_type: 2 },
    { name: "Electronics for Sale", _id: 4, product_type: 2 },
    { name: "Uniforms for Sale", _id: 5, product_type: 2 },
    { name: "Supplies for Use", _id: 6, product_type: 3 },
  ],
  sub_categories: [
    { name: "Transport Buses", _id: 1, category: 1 },
    { name: "Laptop Computer", _id: 2, category: 2 },
    { name: "Smart Board", _id: 3, category: 2 },
    { name: "Math Textbook", _id: 4, category: 3 },
    { name: "Tablet", _id: 5, category: 4 },
    { name: "Uniform T-Shirts", _id: 6, category: 5 },
    { name: "Pens", _id: 7, category: 6 },
  ],
  capex_products: [
    {
      _id: 1,
      category: "Vehicles",
      sub_category: "Transport Buses",
      short_name: "TC-2020",
      meta: {
        manufacturer: "Toyota",
        model: "Coaster",
        year: "2020",
      },
      current_count: 2,
      life_in_years: 8,
      purchase_tax: 1,
      product_type: "capex_products",
    },
    {
      _id: 2,
      category: "Electronic Equipment",
      sub_category: "Smart Board",
      short_name: "ST-6075S",
      meta: {
        manufacturer: "Smart Technologies",
        series: "6000S",
        model: "6075S",
        screen: "75 inch",
      },
      current_count: 9,
      life_in_years: 5,
      purchase_tax: 2,
      product_type: "capex_products",
    },
    {
      _id: 3,
      category: "Electronic Equipment",
      sub_category: "Laptop Computer",
      short_name: "MBP-2020-512",
      meta: {
        manufacturer: "Apple",
        model: "MacBook Pro 13 inch",
        storage: "512GB",
        ram: "8GB",
        processor_chip: "M1",
        year: "2020",
      },
      current_count: 12,
      life_in_years: 5,
      purchase_tax: 3,
      product_type: "capex_products",
    },
    {
      _id: 4,
      category: "Electronic Equipment",
      sub_category: "Laptop Computer",
      short_name: "MBP-2020-256",
      meta: {
        manufacturer: "Apple",
        model: "MacBook Pro 13 inch",
        storage: "256GB",
        ram: "8GB",
        chip: "M1",
        year: "2020",
      },
      current_count: 4,
      life_in_years: 5,
      purchase_tax: 4,
      product_type: "capex_products",
    },
  ],
  inventory_products: [
    {
      _id: 5,
      category: "Books for Sale",
      sub_category: "Math Textbook",
      short_name: "MBK-G2",
      meta: {
        level: "Grade 2",
        title: "McGraw-Hill My Math",
        author: "ALTIERI",
        volume: "1",
        edition: "1",
        publisher: "McGraw-Hill",
        audience: "student",
        ISBN13: "978-0021150212",
      },
      selling_price: 200,
      current_count: 20,
      purchase_tax: 0,
      sales_tax: 0,
      product_type: "inventory_products",
    },
    {
      _id: 6,
      category: "Electronics for Sale",
      sub_category: "Tablet",
      short_name: "IPAD-8th",
      meta: {
        manufacturer: "Apple",
        model: "iPad 8th Generation",
        storage: "128GB",
        year: "2020",
      },
      selling_price: 2000,
      current_count: 2,
      purchase_tax: 0,
      sales_tax: 0,
      product_type: "inventory_products",
    },
    {
      _id: 7,
      name: "Uniforms for Sale",
      sub_category: "Uniform T-Shirts",
      short_name: "t-shirt-s",
      category: "Uniform T-Shirts", //not set
      meta: {
        manufacturer: "ABC Clothing",
        size: "s",
        color: "blue",
      },
      selling_price: 80,
      current_count: 30,
      purchase_tax: 0,
      sales_tax: 0,
      product_type: "inventory_products",
    },
    {
      _id: 8,
      name: "Uniforms for Sale",
      sub_category: "Uniform T-Shirts",
      category: "Uniform T-Shirts", //not set
      short_name: "t-shirt-m",
      meta: {
        manufacturer: "ABC Clothing",
        size: "m",
        color: "grey",
      },
      selling_price: 80,
      current_count: 30,
      purchase_tax: 0,
      sales_tax: 0,
      product_type: "inventory_products",
    },
    {
      _id: 9,
      name: "Uniforms for Sale",
      category: "Uniform T-Shirts", //not set
      sub_category: "Uniform T-Shirts",
      short_name: "t-shirt-l",
      meta: {
        manufacturer: "ABC Clothing",
        size: "l",
        color: "white",
      },
      selling_price: 80,
      current_count: 30,
      purchase_tax: 0,
      sales_tax: 0,
      product_type: "inventory_products",
    },
  ],
  usage_products: [
    {
      _id: 10,
      category: "Supplies for Use",
      sub_category: "Pens",
      short_name: "pens-bic-14",
      meta: {
        manufacturer: "Pic",
        type: "14",
        color: "blue",
        bundle: 10,
      },
      current_count: 10,
      purchase_tax: 0,
      product_type: "usage_products",
    },
  ],
  suppliers: [
    {
      _id: 1,
      name: "School Supplier Inc",
      short_name: "School Supplier Inc",
      contacts: [
        {
          name: "Fred Doe",
          mobile: "+97333349393",
          email: "fred.doe@ssinc.co",
        },
      ],
      tags: ["stationary supplier", "book supplier"],
      isApproved: true
    },
    {
      _id: 2,
      name: "SSI 2",
      short_name: "SSI 2",
      contacts: [
        {
          name: "Fred Doe",
          mobile: "+97333349393",
          email: "fred.doe@ssinc.co",
        },
      ],
      tags: ["stationary supplier", "book supplier"],
      isApproved: true
    },
    {
      _id: 3,
      name: "SSI 3",
      short_name: "SSI 3",
      contacts: [
        {
          name: "Fred Doe",
          mobile: "+97333349393",
          email: "fred.doe@ssinc.co",
        },
      ],
      tags: ["stationary supplier", "book supplier"],
      isApproved: true
    },
    {
      _id: 4,
      name: "SSI 4",
      short_name: "SSI 4",
      contacts: [
        {
          name: "Fred Doe",
          mobile: "+97333349393",
          email: "fred.doe@ssinc.co",
        },
      ],
      tags: ["stationary supplier", "book supplier"],
      isApproved: true
    },
  ],
  purchase_orders: [
    {
      _id: 1,
      number: "PO-Order1",
      created: "2020-12-11",
      status: ORDER_STATUS.New,
      _supplierid: 1,
      description: '',
      line_items: [
        {
          _id: 1,
          _inventoryproductid: 1,
          quantity: 12,
          cost_per_unit: 120,
          purchase_tax: 1,
          description: ''
        },
        {
          _id: 2,
          _inventoryproductid: 2,
          quantity: 40,
          cost_per_unit: 11,
          purchase_tax: 2,
          description: ''
        },
      ],
      total_cost: 1903.2,
    },
    {
      _id: 2,
      number: "PO-Order2",
      created: "2020-12-11",
      status: ORDER_STATUS.New,
      _supplierid: 2,
      description: '',
      line_items: [
        {
          _id: 1,
          _inventoryproductid: 1,
          quantity: 12,
          cost_per_unit: 120,
          purchase_tax: 1,
          description: ''
        },
        {
          _id: 2,
          _inventoryproductid: 2,
          quantity: 40,
          cost_per_unit: 11,
          purchase_tax: 2,
          description: ''
        },
      ],
      total_cost: 1903.2,
    },
    {
      _id: 3,
      number: "PO-Order3",
      created: "2020-12-11",
      status: ORDER_STATUS.New,
      _supplierid: 3,
      description: '',
      line_items: [
        {
          _id: 1,
          _inventoryproductid: 1,
          quantity: 12,
          cost_per_unit: 120,
          purchase_tax: 1,
          description: '',
        },
        {
          _id: 2,
          _inventoryproductid: 2,
          quantity: 40,
          cost_per_unit: 11,
          purchase_tax: 2,
          description: '',
        },
      ],
      total_cost: 1903.2,
    },
    {
      _id: 4,
      number: "PO-Order4",
      created: "2020-12-11",
      status: ORDER_STATUS.New,
      _supplierid: 4,
      description: '',
      line_items: [
        {
          _id: 1,
          _inventoryproductid: 1,
          quantity: 12,
          cost_per_unit: 120,
          purchase_tax: 1,
          description: '',
        },
        {
          _id: 2,
          _inventoryproductid: 2,
          quantity: 40,
          cost_per_unit: 11,
          purchase_tax: 2,
          description: '',
        },
      ],
      total_cost: 1903.2,
    },
    {
      _id: 5,
      number: "PO-Order5",
      created: "2020-12-11",
      status: ORDER_STATUS.Cancel,
      _supplierid: 1,
      description: '',
      line_items: [
        {
          _id: 1,
          _inventoryproductid: 1,
          quantity: 12,
          cost_per_unit: 120,
          purchase_tax: 1,
          description: '',
        },
        {
          _id: 2,
          _inventoryproductid: 2,
          quantity: 40,
          cost_per_unit: 11,
          purchase_tax: 2,
          description: '',
        },
      ],
      total_cost: 1903.2,
    },
  ],
};

// class Db {
//   data;
//   constructor() {
//     this.data = DATA;
//   }
//   getData = () => {
//     return this.data;
//   };
//   updateProductCode(entry) {
//     const existingRecord = this.data.capex_products.find(
//       (product) => product._id === entry._id
//     );
//     existingRecord.category = entry.category;
//     existingRecord.sub_category = entry.sub_category;
//     existingRecord.meta.manufacturer = entry.meta.manufacturer;
//     existingRecord.short_name = entry.short_name;
//   }
//   addProductCode(entry) {
//     this.data.capex_products.push(entry);
//   }
//   createNewPurchaseOrder(order) {
//     order._supplierid = parseInt(order._supplierid);
//     this.data.purchase_orders.push(order);
//   }
//   updatePurchaseOrder(order) {

//     //we can make ajax calls and get data
//     const entry = this.data.purchase_orders.find((po) => po._id === order._id);
//     entry.line_items.length = 0;
//     order.line_items.forEach((li) => entry.line_items.push(li));
//     order._supplierid = parseInt(entry._supplierid);
//     order.created = entry.created;
//     order.number = entry.number;
//   }
// }

// const db = new Db();

//export { DATA, db as Db };

export { DATA };
