import { Fragment, useCallback, useEffect, useReducer, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Api } from "../../services/Api";
import { ACCOUNT_TYPES } from "../../shared/constants";
import Typography from "@material-ui/core/Typography";
import { 
  IconButton, 
  TextField, 
  Button, 
  Container, 
  MenuItem,
  Select,
  InputLabel,
  Switch, Chip, FormControl, FormControlLabel, InputAdornment, TableContainer } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { Grid, ButtonGroup } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add"

import FloatingActionButton from "../../screens/FloatingActionButton";

import Checkbox from "@material-ui/core/Checkbox";
import PaymentIcon from "@material-ui/icons/Payment";
import DescriptionIcon from "@material-ui/icons/Description";
import DoneIcon from "@material-ui/icons/Done"
import SearchIcon from "@material-ui/icons/Search"
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import Snapshots from './Snapshots';
import Statements from './Statements';
//import EmployeeView from './Employee';

function roundAmount(amount){
  let frac = 100;
  return Math.round((amount+Number.EPSILON)*frac) / frac;
}

export default function GLAccounts() {

  const years = [2021,2022,2023,2024,2025];
  const months = [0,1,2,3,4,5,6,7,8,9,10,11];

  const [glAccounts, setGlAccounts] = useState({});
  const [totalDebits, setTotalDebits] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [glAccountsArray, setGlAccountsArray] = useState([]);  
  const [snapshotsVisible, setSnapshotsVisible] = useState(false);
  const [statementsVisible, setStatementsVisible] = useState(false);

  const [refreshAccounts, setRefreshAccounts] = useState(true);

  function getMonthName(month){
    return month===0?"Jan":month===1?"Feb":month===2?"Mar":month===3?"Apr":month===4?"May":month===5?"Jun":month===6?"Jul":month===7?"Aug":month===8?"Sep":month===9?"Oct":month===10?"Nov":month===11?"Dec":"";
  }
  
  function isCalculated(key){
    return key==="Net Supplier Balance"||key==="Net Student Balance"||key==="Net Employee Balance"||key==="General Ledger Balance"/* ||key==="Provisional Retained Earnings" */;
  }

  
  function createGlArray(accounts){
    let tempAccountsArray = [];
    let tempTotalDebits = 0;
    let tempTotalCredits = 0;
    Object.entries(accounts).map(([key, value]) =>{
      if(!isCalculated(key)){
        let accountObject = {};
        accountObject.amount = value;
        if(key!=='Provisional Retained Earnings') value>0?(tempTotalCredits+=value):(tempTotalDebits+=value);
        accountObject.name = key;
        console.log(key);
        accountObject.type = ACCOUNT_TYPES[key]?ACCOUNT_TYPES[key]:['Unknown','Unknown'];
        tempAccountsArray.push(accountObject);
      }
    });
    setTotalDebits(tempTotalDebits);
    setTotalCredits(tempTotalCredits);
    setGlAccountsArray(tempAccountsArray);
  }
  
  function getGlAccountsByType(type){
    return glAccountsArray.filter((a)=>a.type[0]===type);
  }

  function toggleSnapshots(){
    snapshotsVisible&&setRefreshAccounts(!refreshAccounts);
    setSnapshotsVisible(!snapshotsVisible);
  }

  function toggleStatements(){
    setStatementsVisible(!statementsVisible);
  }

  useEffect(() => {
    Promise.all([
      Api.getGLAccounts(),
    ]).then(([accounts]) => {
      setGlAccounts(accounts);
      createGlArray(accounts);
    }).catch((e)=>{
      console.log(e);
    });
  }, [refreshAccounts]);
  
  return (
    <Container maxWidth="lg">
        {snapshotsVisible?
        <Snapshots toggleSnapshots={toggleSnapshots} getGlAccountsByType={getGlAccountsByType} glAccountsArray={glAccountsArray} />
          :statementsVisible?
        <Statements toggleStatements={toggleStatements} getGlAccountsByType={getGlAccountsByType} glAccountsArray={glAccountsArray} />
          :
        <Fragment>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="space-between"
          alignItems="center"
          >
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              General Ledger Accounts
            </Typography>
          </Grid>
          <Grid item>
          <ButtonGroup size="small" aria-label="Ledger Menu">
            <Button onClick={()=>toggleSnapshots()}>Closures</Button>
            <Button onClick={()=>toggleStatements()}>Statements</Button>
          </ButtonGroup>
         </Grid>
         <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Total Debits ({roundAmount(totalDebits)}) Total Credits ({roundAmount(totalCredits)})
            </Typography>
         </Grid>
        </Grid>
        
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Account Name</b>
              </TableCell>
              <TableCell>
                <b>Balance</b>
              </TableCell>
              <TableCell>
                <b>Debit/Credit</b>
              </TableCell>
              <TableCell>
                <b>Type</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { glAccountsArray&&getGlAccountsByType('Asset').map((account, key) => (
                <TableRow key={key}>
                  <TableCell>
                 {/*  <Button onClick={()=>toggleEmployee(value)}> */}{account.name}{/* </Button> */}
                  </TableCell>
                  <TableCell>
                    {account.amount}
                  </TableCell>
                  <TableCell>
                    {account.amount===0?"-":account.amount>0?"Credit":"Debit"}
                  </TableCell>
                  <TableCell>
                    {account.type[0]}
                  </TableCell>
                </TableRow>
              ))}
              { glAccountsArray&&getGlAccountsByType('Liability').map((account, key) => (
                <TableRow key={key}>
                  <TableCell>
                 {/*  <Button onClick={()=>toggleEmployee(value)}> */}{account.name}{/* </Button> */}
                  </TableCell>
                  <TableCell>
                    {account.amount}
                  </TableCell>
                  <TableCell>
                    {account.amount===0?"-":account.amount>0?"Credit":"Debit"}
                  </TableCell>
                  <TableCell>
                    {account.type[0]}
                  </TableCell>
                </TableRow>
              ))}
              { glAccountsArray&&getGlAccountsByType('Equity').map((account, key) => (
                <TableRow key={key}>
                  <TableCell>
                 {/*  <Button onClick={()=>toggleEmployee(value)}> */}{account.name}{/* </Button> */}
                  </TableCell>
                  <TableCell>
                    {account.amount}
                  </TableCell>
                  <TableCell>
                    {account.amount===0?"-":account.amount>0?"Credit":"Debit"}
                  </TableCell>
                  <TableCell>
                    {account.type[0]}
                  </TableCell>
                </TableRow>
              ))}
              { glAccountsArray&&getGlAccountsByType('Revenue').map((account, key) => (
                <TableRow key={key}>
                  <TableCell>
                 {/*  <Button onClick={()=>toggleEmployee(value)}> */}{account.name}{/* </Button> */}
                  </TableCell>
                  <TableCell>
                    {account.amount}
                  </TableCell>
                  <TableCell>
                    {account.amount===0?"-":account.amount>0?"Credit":"Debit"}
                  </TableCell>
                  <TableCell>
                    {account.type[0]}
                  </TableCell>
                </TableRow>
              ))}
              { glAccountsArray&&getGlAccountsByType('Expense').map((account, key) => (
                <TableRow key={key}>
                  <TableCell>
                 {/*  <Button onClick={()=>toggleEmployee(value)}> */}{account.name}{/* </Button> */}
                  </TableCell>
                  <TableCell>
                    {account.amount}
                  </TableCell>
                  <TableCell>
                    {account.amount===0?"-":account.amount>0?"Credit":"Debit"}
                  </TableCell>
                  <TableCell>
                    {account.type[0]}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <Grid
          style={{marginTop: 15}}
          container
          spacing={3}
          direction="row"
          justify="space-between"
          alignItems="center"
          >
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Calculated Accounts
            </Typography>
          </Grid>
         <Grid item>
          
         </Grid>
        </Grid>
        
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Account Name</b>
              </TableCell>
              <TableCell>
                <b>Balance</b>
              </TableCell>
              <TableCell>
                <b>Debit/Credit</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { Object.entries(glAccounts).map(([key, value]) => (
              isCalculated(key)&&
                <TableRow key={key}>
                  <TableCell>
                 {/*  <Button onClick={()=>toggleEmployee(value)}> */}{key}{/* </Button> */}
                  </TableCell>
                  <TableCell>
                    {value}
                  </TableCell>
                  <TableCell>
                    {value&&value>0?"Credit":"Debit"}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        
        </Fragment>
        }
      </Container>
  );
}