import {Fragment, useCallback, useEffect, useReducer, useState } from "react";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Api } from "../../services/Api";
import Typography from "@material-ui/core/Typography";
import { 
  Box,
  IconButton, 
  TextField, 
  Button,
  Container, 
  MenuItem,
  Select,
  InputLabel,
  Switch, 
  Chip, 
  FormControl, 
  FormControlLabel, 
  InputAdornment, 
  TableContainer,
  Tabs,
  Tab,
  Card,
  CardContent
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";

import FloatingActionButton from "../../screens/FloatingActionButton";
import ReceiptIcon from '@material-ui/icons/Receipt';
import Checkbox from "@material-ui/core/Checkbox";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SearchIcon from "@material-ui/icons/Search"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PurchaseOrderGrn from "../PurchaseOrder/Grn";
import QRCode from 'qrcode.react';

import ReactDOMServer from 'react-dom/server';
import FileDownload from 'js-file-download';
function roundAmount(amount){
  let frac = 100;
  return Math.round((amount+Number.EPSILON)*frac) / frac;
}

function getMonthName(month){
  return month===0?"Jan":month===1?"Feb":month===2?"Mar":month===3?"Apr":month===4?"May":month===5?"Jun":month===6?"Jul":month===7?"Aug":month===8?"Sep":month===9?"Oct":month===10?"Nov":month===11?"Dec":"";
}

const EmployeePayrollsView = (props) =>{
  const payrolls = props.payrolls;
  //const invoicePayments = props.invoicePayments;
  const voidPayroll = props.voidPayroll;
  //const setSelectedProduct = props.setSelectedProduct;
  //const updateNewCharge = props.updateNewCharge;
  const [selectedPayroll, setSelectedPayroll] = useState(null);
  //const [selectedInvoicePayment, setSelectedInvoicePayment] = useState(null);
  const employee = props.employee;

  return (/* selectedInvoice?
        <StudentOfficialInvoiceView 
        student={student} 
        invoice={selectedInvoice} 
        invoicePayment={selectedInvoicePayment}
        setSelectedInvoice={setSelectedInvoice} />
      : */
       <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Period</b>
              </TableCell>
              <TableCell>
                <b>Employee Payable</b>
              </TableCell>
              <TableCell>
                <b>Social Security Payable</b>
              </TableCell>
              <TableCell>
                <b>Action</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payrolls&&payrolls.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>
                  <Button style={obj.voided?{color: '#ff1111'}:{color: '#000000'}}
                  onClick={() => {setSelectedPayroll(obj);/* setSelectedInvoicePayment(invoicePayments[obj._id]||null) */}}>{(getMonthName(obj.month) || '') + ' ' + (obj.year || '') }
                  </Button>
                    </TableCell>
                  <TableCell>
                    {obj.employee_payable || 0}
                  </TableCell>
                  <TableCell>
                    {obj.ss_payable || 0}
                  </TableCell>
                  <TableCell>
                    {!obj.voided?<Button onClick={()=> voidPayroll(obj)}>Void</Button>:<Typography style={obj.voided?{color: '#ff1111'}:{color: '#000000'}}>VOIDED</Typography>}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
  )
}

const EmployeePaymentsView = (props) =>{
  const payments = props.payments;
  const employee = props.employee;
  const [selectedPayment, setSelectedPayment] = useState(null);
  return (
       /* selectedPayment?
        <StudentPaymentReceiptView 
        student={student} 
        payment={selectedPayment} 
        setSelectedPayment={setSelectedPayment} />
       : */
       <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Payment Date</b>
              </TableCell>
              <TableCell>
                <b>Amount</b>
              </TableCell>
              <TableCell>
                <b>Payslip</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments&&payments.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>{obj.paidOn || ''}</TableCell>
                  <TableCell>
                    {obj.paid_amount || 0}
                  </TableCell>
                  <TableCell>
                  <IconButton
                  title="View Receipt"
                  aria-label="View Receipt"
                  color="primary"
                  onClick={() => setSelectedPayment(obj)}
                  >
                  <ReceiptIcon />
                  </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
  )
}

const EmployeeStatementView = (props) =>{
  const refunds = props.refunds;
  const payments = props.payments;
  const payrolls = props.payrolls;
  const debitNotes = props.debitNotes;

  const [employeeStatementAccounts, setEmployeeStatementAccounts] = useState([]);

  useEffect(() => {
    const employeeAccounts = [];
    refunds.map((refund, key)=>{
      let obj = {
        type: "Refund",
        acc_type: "Debit",
        transDate: refund.refundedOn,
        amount: refund.refund_amount
      };
      employeeAccounts.push(obj);
    });
    payments.map((payment, key)=>{
      let obj = {
        type: "Payment",
        acc_type: "Debit",
        transDate: payment.paidOn,
        amount: payment.paid_amount,
      };
      employeeAccounts.push(obj);
    });
    payrolls.map((payroll, key)=>{
      let obj = {
        type: "Payroll",
        acc_type: "Credit",
        transDate: new Date(payroll.year, payroll.month+1).toISOString(),
        amount: payroll.employee_payable,
      };
      employeeAccounts.push(obj);
    });

    debitNotes.map((dn, key)=>{
      let obj = {
        type: "Debit Note",
        acc_type: "Debit",
        transDate: dn.voidedOn,
        amount: dn.employee_payable,
      };
      employeeAccounts.push(obj);
    });

    employeeAccounts.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.transDate) - new Date(b.transDate);
    });

    let carryingBalance = 0;
    employeeAccounts.map((obj, key) => {
      obj.acc_type==="Debit"?carryingBalance-=obj.amount:carryingBalance+=obj.amount;
      obj.carryingBalance = roundAmount(carryingBalance);
    });

    setEmployeeStatementAccounts(employeeAccounts);

  }, [refunds, payments, payrolls]);
  
  return (
    <Fragment>
      <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell>
                <b>Type</b>
              </TableCell>
              <TableCell>
                <b>Debit</b>
              </TableCell>
              <TableCell>
                <b>Credit</b>
              </TableCell>
              <TableCell>
                <b>Balance</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employeeStatementAccounts&&employeeStatementAccounts.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>{obj.transDate || ''}</TableCell>
                  <TableCell>{obj.type || ''}</TableCell>
                  <TableCell>
                    {obj.acc_type==="Debit" && obj.amount}
                  </TableCell>
                  <TableCell>
                    {obj.acc_type==="Credit" && obj.amount}
                  </TableCell>
                  <TableCell>
                    {obj.carryingBalance || 0}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

    </Fragment>
      )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function Employee(props) {
  const [loading, setLoading] = useState(false);
  const [refundError, setRefundError] = useState("");
  const [payrolls, setPayrolls] = useState([]);

  const [payments, setPayments] = useState([]);
  const [debitNotes, setDebitNotes] = useState([]);
  const [refunds, setRefunds] = useState([]);
  

  /* const [invoicePayments, setInvoicePayments] = useState({});
  const [issueRefundVisible, setIssueRefundVisible] = useState(false); */

  const [tabValue, setTabValue] = useState(0);
  const employee = props.selectedEmployee;
  const toggleEmployee = props.toggleEmployee;
  const updateEmployeeAccounts = props.updateEmployeeAccounts;
  const updateAllPayrolls = props.updateAllPayrolls;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  useEffect(() => {
    console.log("effect on employee");
    employee&&Promise.all([
      Api.getEmployeePayrolls(employee),
      Api.getEmployeePayments(employee),
      /* Api.getStudentRefunds(student) */
    ]).then(([payrolls, payments/* , refunds */]) => {
      setPayrolls(payrolls);
      setPayments(payments);
      /*setRefunds(refunds); */
      let dns = [];
      payrolls.map((pr, key)=>{
        pr.voided&&dns.push(pr);
      });
      setDebitNotes(dns);
    }).catch((e)=>{
      console.log(e);
    });
  }, [employee]);

/*   useEffect(() =>{
    let remaining_amount =0;
    let invoicePaymentsObject = {};
    payments.map((payment, key)=>{
      remaining_amount += payment.paid_amount;
    });
    refunds.map((refund, key)=>{
      remaining_amount -= refund.refund_amount;
    });

    let invoiceIndex = 0;
    while(remaining_amount>0&&invoiceIndex<invoices.length){
      if(!invoices[invoiceIndex].voided){
        if(invoices[invoiceIndex].net_amount>=remaining_amount){
          invoicePaymentsObject[invoices[invoiceIndex]._id] = {
            paid_amount: roundAmount(remaining_amount),
            due_amount: roundAmount(invoices[invoiceIndex].net_amount) - roundAmount(remaining_amount)
          }
          remaining_amount=0;
        }else{
          invoicePaymentsObject[invoices[invoiceIndex]._id] = {
            paid_amount: roundAmount(invoices[invoiceIndex].net_amount),
            due_amount: 0
          }
          remaining_amount -= invoices[invoiceIndex].net_amount;
        }
      }
      invoiceIndex++;
    }
    //console.log(invoices[invoices.length-1].ksa_einvoice?invoices[invoices.length-1].ksa_einvoice:"none");
    //console.log(invoicePaymentsObject);
    setInvoicePayments(invoicePaymentsObject);
   
  }, [invoices, payments, refunds]) */
  
  /* const issueStudentRefund = (amount, reason, method) =>{
    Api.issueStudentRefund(student,amount,reason,method).then((res)=>{
      setIssueRefundVisible(false);
      Api.getStudentRefunds(student).then((refunds)=>{
        setRefunds(refunds);
        updateStudentAccounts(student._id);
      });
    }).catch((err)=>{
      setRefundError("Cannot refund amount because the student balance is 0 or negative. Refunds can only be issued on positive student balance");
    })
  } */

  const voidPayroll = (payroll) => {
    Api.voidEmployeePayroll(payroll).then((res)=>{
      console.log(res);
      updateEmployeeAccounts(employee._id);
      updateAllPayrolls();
    }).catch((e)=>{
      console.log(e);
    });
  }

  return (
      <Fragment>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
          <IconButton
            aria-label="Grn"
            onClick={() => toggleEmployee()}>
            <CloseIcon />
          </IconButton>
          </Grid>
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Employee Accounts
            </Typography>
          </Grid>
         <Grid item xs={6}>
         </Grid>
        </Grid>

        <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Grid container alignItems="center" justify="center">
            <Grid item xs={6}>
              <Typography variant="h5" component="div">
                {employee.name}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="primary">
                {employee.nationalid}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" component="div">
                Balance: {employee.accounts&&employee.accounts["Net Employee Balance"]?
                          employee.accounts["Net Employee Balance"]:0}
              </Typography>
            </Grid>
            
          </Grid>
          
          
        </CardContent>
        </Card>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Payrolls" {...a11yProps(0)} />
            <Tab label="Payments" {...a11yProps(1)} />
            <Tab label="Statement" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <EmployeePayrollsView voidPayroll={voidPayroll} payrolls={payrolls}  employee={employee} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <EmployeePaymentsView 
          student={employee} 
          payments={payments}  />
        </TabPanel>
        {/* <TabPanel value={tabValue} index={2}> */}
          {/* <StudentRefundsView refunds={refunds} 
          student={student} 
          setIssueRefundVisible={setIssueRefundVisible} 
          issueRefundVisible = {issueRefundVisible}
          issueStudentRefund = {issueStudentRefund} /> */}
        {/* </TabPanel> */}
        <TabPanel value={tabValue} index={2}>
          <EmployeeStatementView refunds={[]} payrolls={payrolls} payments={payments} debitNotes={debitNotes} />
        </TabPanel>
      </Fragment>
    );
}