import React, {
  useCallback,
  useEffect,
  useState,
  Fragment
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { fade } from "@material-ui/core/styles/colorManipulator";
import VisibilityIcon from "@material-ui/icons/Visibility";

import {
  Typography,
  Grid,
  Button,
  MenuItem,
  Select,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  CircularProgress,
  IconButton,
  FormControl,
  Chip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import moment, { isDate } from "moment";
import { useHistory, useParams } from "react-router-dom";
import { Api } from "../../services/Api";
import { Autocomplete } from "@material-ui/lab";
import {
  Controller,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FullScreenDialog from "../../screens/FullScreenDialog";
import CommentDialog from "../../screens/CommentDialog";
import { default as ViewCapexProduct } from "../Product/Capex/View";
import { default as ViewInventoryProduct } from "../Product/Inventory/View";
import { default as ViewUsageProduct } from "../Product/Usage/View";
import { ORDER_STATUS } from "../../shared/constants";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(0),
  },
  checkboxGrid: {
    border: `${fade(theme.palette.text.primary, 0.2)} 1px solid`,
    marginTop: "5px",
    padding: "10px",
  },
}));

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const statusColors = {
  'New': 'default',
  'Approved': 'primary',
  'Canceled': 'secondary',
  'Sent To Supplier': 'primary'
}

const ManagePurchaseOrder = (props) => {
  //1- add new variable to initial state
  //2- add to validation schema
  //3- add markup

  const classes = useStyles();
  const { id } = useParams();
  const purchaseOrderId = id;
  const isAdd = /* isNaN(purchaseOrderId) || */ !purchaseOrderId ? true : false;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  
  const validationSchema =  Yup.object().shape({
    total_cost: Yup.number().default(0),
    addedOn: Yup.string(),
    status: Yup.string().default(ORDER_STATUS.New),
    description: Yup.string(),
    _id: Yup.string(),
    _supplierid: Yup.mixed(),
    line_items: Yup.array().of(
      Yup.object().shape({
        _id: Yup.string(),
        /* _inventoryproductid: Yup.mixed().test(
          "is-not",
          "Please select",
          (value) => {
            if (value) return true;
            return false;
          }
        ), */
        _inventoryproductid: Yup.string()                                                                        
                  .when(['_usageproductid','_capexproductid'], {                                                    
                  is: (_usageproductid, _capexproductid) => (!_usageproductid || _usageproductid.length === 0)&&(!_capexproductid || _capexproductid.length === 0),                      
                  then: Yup.string()                                                   
                  .required('Please Select'),}),
        _capexproductid: Yup.string()                                                                        
                  .when(['_inventoryproductid','_usageproductid'], {                                                    
                  is: (_inventoryproductid, _usageproductid) => (!_usageproductid || _usageproductid.length === 0)&&(!_inventoryproductid || _inventoryproductid.length === 0),                      
                  then: Yup.string()                                                   
                  .required('Please Select'),}),
        _usageproductid: Yup.string()                                                                        
                  .when(['_inventoryproductid','_capexproductid'], {                                                    
                  is: (_inventoryproductid, _capexproductid) => (!_capexproductid || _capexproductid.length === 0)&&(!_inventoryproductid || _inventoryproductid.length === 0),                      
                  then: Yup.string()                                                   
                  .required('Please Select'),}),
        quantity: Yup.number()
          .transform((v) => (isNaN(parseFloat(v)) ? -1 : parseFloat(v)))
          .min(0.01, "Quantity can not be empty or less than zero"),
        cost_per_unit: Yup.number()
          .transform((v) => (isNaN(parseFloat(v)) ? -1 : parseFloat(v)))
          .min(0.001, "Cost per unit can not be empty or less than zero"),
        purchase_tax: Yup.number(),
        sales_tax: Yup.number(),
      }, [['_usageproductid','_capexproductid'], ['_inventoryproductid','_usageproductid'], ['_inventoryproductid','_capexproductid']])
    ),
  });

  const [inventoryProducts, setInventoryProducts] = useState([]);
  const [capexProducts, setCapexProducts] = useState([]);
  const [usageProducts, setUsageProducts] = useState([]);

  const initialState = {
    line_items : [],
    total_cost : 0,
    addedOn : "",
    _id : "",
    _supplierid : null,
    description : ""
  }

  const [order, setOrder] = useState(initialState);
  const [orderStatus, setOrderStatus] = useState(ORDER_STATUS.New);

  // functions to build form returned by useForm() hook
  const date = moment().format("yyyy-MM-DD");
  const [open, setOpen] = useState({ open: false, id: "", type: "" });
  const [suppliers, setSuppliers] = useState([]);

  const [confirmDialog, setConfirmDialog] = useState({
    confirm: null,
    text: "",
    open: false,
    orderStatus: "",
  });
  const [commentOpenFlag, setCommentOpenFlag] = useState({
    open: false,
    comment: "",
    field: null,
    index: -1,
    setValue: null,
    disabled: false,
  });

  const {
    register,
    handleSubmit,
    reset,
    errors: fieldsErrors,
    control,
    setValue,
    formState,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { fields, remove, insert, append } = useFieldArray({
    control,
    name: "line_items",
  });

  const commentsArray = useFieldArray({
    control,
    name: "comments",
  });

  function roundAmount(amount){
    let frac = 100;
    return Math.round((amount+Number.EPSILON)*frac) / frac;
  }
  
  useEffect(() => {
      Promise.all([
        Api.getInventoryProducts(),
        Api.getCapexProducts(),
        Api.getUsageProducts(),
        Api.getApprovedSuppliers(),
        purchaseOrderId?Api.getPurchaseOrder(purchaseOrderId):null,  
      ]).then(([inventoryProducts, capexProducts, usageProducts, suppliers, po]) => {
        if(po&&po.status!=='New') history.push("/purchaseorder/view/"+purchaseOrderId);
        setInventoryProducts(inventoryProducts);
        setCapexProducts(capexProducts);
        setUsageProducts(usageProducts);
        if(po)setOrder(po);
        setSuppliers(suppliers);
        if(po){
          setValue("_supplierid", po._supplierid);
          setValue("_id", po._id);
          setValue("addedOn", po.addedOn);
          setValue("status", po.status);
          setValue("line_items", po.line_items);
          po.total_cost = 0;
          po.line_items&&po.line_items.map((obj, key)=>{
            po.total_cost += obj.total_cost;
          });
          setValue("total_cost", roundAmount(po.total_cost));
        }  
      }).catch((e)=>{
        console.log("Something went wrong while getting po", e);
      });
  }, [purchaseOrderId]);

  const commentFields = commentsArray.fields;
  const commentAppend = commentsArray.append;
  const commentPrepend = commentsArray.prepend;

  const addLineItem = useCallback(() => {
    append({
      _id: parseFloat(Date.now()),
      _inventoryproductid: null,
      _capexproductid: null,
      _usageproductid: null,
      quantity: 0,
      cost_per_unit: 0,
      total_cost: 0,
      purchase_tax: 0,
    });
  }, [append]);

  const removeItemCallback = useCallback(
    (idx, entry) => {
      remove(idx);
      let sum = 0;
      const f = fields;
      fields.forEach((f) => {
        if (f._id !== entry._id) {
          sum += parseFloat(f["total_cost"]);
        }
      });
      setValue("total_cost", sum);
    },
    [fields, remove, setValue]
  );

  const addComment = useCallback(() => {
    const comment = getValues("comment");
    setValue("comment", "");
    commentAppend({
      _id: Date.now(),
      comment: comment,
      commentDate: Date.now(),
      updatedDate: Date.now(),
      commentBy: 1,
      edited: false,
      expanded: true,
      isNew: true,
    });
  }, [commentAppend, commentPrepend]);

  const updatePriceCallback = useCallback(
    (event, entry, isArray, index, selectedProductType) => {
      if (isArray) {
        let productIdName = selectedProductType==='inventory'?
        '_inventoryproductid':selectedProductType==='capex'?'_capexproductid':'_usageproductid';
        let productChange = false;
        if (!event.target.name) {
          productChange = true;
        }
        setValue(
          "line_items",
          fields.map((f) => {
            if (f.id === entry.id) {
              if (!productChange) {
                console.log('is not product change');
                f[event.target.name] = event.target.value || 0;
                f["total_cost"] = parseFloat(f["cost_per_unit"]) 
                * parseFloat(f["quantity"]) 
                * (1+parseFloat(f["purchase_tax"]));
              } else {
                console.log('is product change');
                let productsArray = selectedProductType==='inventory'?inventoryProducts:selectedProductType==='capex'?capexProducts:usageProducts;
                const existingProduct = productsArray.find(
                  (p) => p._id === event.target.value
                );
                console.log('existing', existingProduct);
                
                if (!existingProduct) f[productIdName] = null;
                else f[productIdName] = event.target.value;
                console.log('id now', f[productIdName]);
                f["purchase_tax"] = existingProduct
                  ? existingProduct.purchase_tax
                  : 0;
                f["total_cost"] = parseFloat(f["cost_per_unit"]) 
                * parseFloat(f["quantity"]) 
                * (1+parseFloat(f["purchase_tax"]));
              }
              return { ...f };
            } else return f;
          })
          , {shouldValidate: true, shouldDirty : true});
          
        let total = 0;
        fields.forEach((oho) => {
          total += oho.total_cost;
        });
        setValue("total_cost", total);
      }
    },
    [getValues, setValue, fields, inventoryProducts]
  );

  //Similar to onSubmit in Inventory Products
  const submitOrder = (data) => {
    setLoading(true);
    fields.forEach((f, index) => {
      data.line_items[index].purchase_tax = f.purchase_tax;
      data.line_items[index].total_cost = f.total_cost;
    });
    console.log('submit', data);
    Api.savePurchaseOrder(data).then((res)=>{
      console.log(res);
      history.push("/purchaseorder");
    }).catch((e)=>{
      console.log("Cannot Save PO", e);
    }).finally(()=>{
      setLoading(false);
    });
  };

  const handleDialog = (result, orderStatus) => {
    if (result) {
      if (orderStatus === ORDER_STATUS.Restart) orderStatus = ORDER_STATUS.New;
      Api.updateOrderStatus(id, orderStatus).then(() => {
        history.push("/purchaseorder");
      });
    } else {
      setConfirmDialog((prev) => {
        return {
          ...prev,
          open: false,
        };
      });
    }
  };

  const handleChange = (event) => {
    setConfirmDialog((prev) => {
      return {
        ...prev,
        open: true,
        text: "Are you sure you want to change order status?",
        confirm: handleDialog,
        orderStatus: event.target.value,
      };
    });
  };

  const getSupplier = (_supplierid) => {
    console.log(_supplierid);
    let supplier = _supplierid&&suppliers?suppliers[suppliers.findIndex(s=>s._id===_supplierid)]:null;
    console.log(supplier);
    return(supplier);
  }

  function getSupplierName(_supplierid){
    const foundSupplier = suppliers.find(
    (s) => s._id === _supplierid
    );
    console.log(foundSupplier);
    return foundSupplier&&foundSupplier.short_name?foundSupplier.short_name:"";
  }

  return (
    <div>
      {loading && <CircularProgress />}
      <AlertDialog
        open={confirmDialog.open}
        orderStatus={confirmDialog.orderStatus}
        text={confirmDialog.text}
        confirm={handleDialog}
      />
      <FullScreenDialog
        {...open}
        notify={setOpen}
        render={(_) => {
          if (open.type === "capex")
            return <ViewCapexProduct {...open} />;
          if (open.type === "inventory")
            return <ViewInventoryProduct {...open} />;
          return <ViewUsageProduct {...open} />;
        }}
      ></FullScreenDialog>
      <CommentDialog
        {...commentOpenFlag}
        closeHandler={({ reason, ...rest }) => {
          debugger;
          if (reason === "cancel") {
            setCommentOpenFlag((prev) => {
              return {
                ...prev,
                open: false,
              };
            });
          } else {
            debugger;
            const { value, field, index } = rest;
            setValue(
              "line_items",
              fields.map((f) => {
                if (f.id === field.id) {
                  f["description"] = value;
                  return { ...f };
                } else return f;
              })
            );

            setCommentOpenFlag((prev) => {
              return {
                ...prev,
                open: false,
              };
            });
          }
        }}
      ></CommentDialog>

<Fragment>
        <Grid container spacing={3}>
        <Grid item>
          <Typography component="h2" variant="h6" color="primary">
            {isAdd? 'Add Purchase Order': 'Edit Purchase Order'}
          </Typography>
        </Grid>
        <Grid item sm container>
        <Grid item sm>
          {!isAdd&&<Chip label={order.status} color={statusColors[order.status]} />}
        </Grid>
        </Grid>
        </Grid>
      </Fragment>
      <form onSubmit={handleSubmit(submitOrder)}>
        <Grid container spacing={3}>
          {!isAdd&&<Grid item xs={6}>
          <FormControl
              fullWidth
              variant="outlined"
            >
            <Controller
              name="_id"
              control={control}
              defaultValue={order._id}
              as={
                <TextField
                  fullWidth
                  label="_id"
                  variant="outlined"
                  disabled
                ></TextField>
              }
            ></Controller>
            </FormControl>
          </Grid>}
          {!isAdd&&<Grid item xs={6}>
          <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
              name="addedOn"
              control={control}
              defaultValue={order.addedOn}
              as={
                <TextField
                    name="addedOn"
                    variant="outlined"
                    label="PO Date"
                    fullWidth
                    disabled
                  />
              }
            />
            </FormControl>
          </Grid>}
          <Grid item xs={6}>
            <InputLabel id="demo-simple-select-label">Supplier</InputLabel>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              {suppliers && suppliers.length > 0 && (
                <Controller
                  name="_supplierid"
                  control={control}
                  render={(props) => (
                    <Autocomplete
                      {...props}
                      options={suppliers}
                      defaultValue={{
                        _id: order._supplierid,
                        short_name: getSupplierName(order._supplierid)
                      }}
                      getOptionLabel={(option) => option.short_name}
                      onChange={(e, newVal) => {
                        props.onChange(newVal&&newVal._id?newVal._id:null);
                        const op = getSupplier(newVal&&newVal._id?newVal._id:null);
                        if (op) return newVal;
                        return null;
                      }}
                      renderInput={(params) => (
                        <TextField
                          error={fieldsErrors.supplier ? true : false}
                          {...params}
                        />
                      )}
                    ></Autocomplete>
                  )}
                ></Controller>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="description"
                control={control}
                defaultValue={order.description}
                as={
                  <TextField
                    multiline
                    name="description"
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    rows={3}
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item>
            <IconButton
              title="Add line item"
              aria-label="done"
              onClick={addLineItem}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              {orderStatus === ORDER_STATUS.New && (
                <TableCell>
                  <b>Action</b>
                </TableCell>
              )}
              <TableCell>
                <b>Product Type</b>
              </TableCell>
              <TableCell>
                <b>Product</b>
              </TableCell>
              <TableCell>
                <b>Qty</b>
              </TableCell>
              <TableCell>
                <b>Cost Per Unit</b>
              </TableCell>
              <TableCell>
                <b>Purchase VAT</b>
              </TableCell>
              <TableCell>
                <b>Total Cost</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, key) => (
              <LineItem
                setOpen={setOpen}
                key={item.id}
                index={key}
                onChange={updatePriceCallback}
                entity={item}
                inventoryProducts={inventoryProducts}
                capexProducts={capexProducts}
                usageProducts={usageProducts}
                control={control}
                fieldsErrors={fieldsErrors}
                _setValue={setValue}
                getValues={getValues}
                status={orderStatus}
                onRemove={removeItemCallback}
                setCommentOpenFlag={setCommentOpenFlag}
              />
            ))}
          </TableBody>
        </Table>

        <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Controller
              name="total_cost"
              control={control}
              defaultValue={order.total_cost}
              as={
                <TextField
                  variant="outlined"
                  fullWidth
                  helperText={""}
                  disabled
                ></TextField>
              }
            ></Controller>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button onClick={(e) => history.push("/purchaseorder")}>
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(submitOrder)}
            >
              {isAdd ? "Add " : "Save "} Purchase Order
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

function LineItem({index,onChange,entity,inventoryProducts,capexProducts,usageProducts,control,getValues,
  _setValue,fieldsErrors,status,onRemove,setOpen,setCommentOpenFlag,
  ...rest
}) {
  const savedProductType = entity._inventoryproductid?"inventory":entity._capexproductid?"capex":"usage";
  const [entry, _] = useState(entity);
  const [customSelectKey, setCustomSelectKey] = useState('');
  const [selectedProductType, setSelectedProductType] = useState(savedProductType);
  const [Products, setProducts] = useState([]);

  useEffect(() => {

    console.log("changing product type", selectedProductType);

    setProducts(selectedProductType==="inventory"
                ?inventoryProducts:
                selectedProductType==="capex"?capexProducts:usageProducts);

  }, [selectedProductType, inventoryProducts, capexProducts, usageProducts]);

  function getProductName(productType){
    const idField = productType==='inventory'?"_inventoryproductid":productType==='capex'?"_capexproductid":"_usageproductid";
    const foundProduct = Products.find(
    (p) => p._id === entry[idField]
    );
    return foundProduct?foundProduct.short_name:"";
  }

  //When changing product type
  const updateProductTypeCallback = useCallback(
    (e) => {
      console.log("product type update callback");
      onChange({ target: { name: "", value: null } }, entry, true, index, e.target.value);
      const v = e.target.value;
      setSelectedProductType(v);
      setCustomSelectKey(Math.random().toString());
    },
    [onChange, entry, index]
  );

  const handleChangeLineItemProp = useCallback(
    (e, newValue) => {
      //_setValue(e.target.name, e.target.value);
      onChange(e, entry, true, index, selectedProductType);
    },
    [onChange, index]
  );

  //once product is selected
  const handleChangeProduct = useCallback(
    (e, newValue, props) => {
      if (newValue)
        onChange(
          { target: { name: "", value: newValue._id } },
          entry,
          true,
          index,
          selectedProductType
        );
      else onChange({ target: { name: "", value: null } }, entry, true, index, selectedProductType);
      if (newValue) props.onChange(newValue._id);
      else props.onChange("");
    },
    [entry, onChange, index]
  );

  return (
    <TableRow key={entity.id}>
      {status === ORDER_STATUS.New && (
        <TableCell>
          <IconButton
            aria-label="remove"
            onClick={() => onRemove(index, entry)}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
      <TableCell>
        <Select
          id="ProductType"
          label="Product Type"
          variant="outlined"
          value={selectedProductType}
          fullWidth
          onChange={updateProductTypeCallback}
        >
          <MenuItem value="capex">Capex</MenuItem>
          <MenuItem value="inventory">Inventory</MenuItem>
          <MenuItem value="usage">Usage</MenuItem>
        </Select>
      </TableCell>
      <TableCell>
        {Products && Products.length > 0 && (
          <CustomSelect
            key={customSelectKey}
            setOpen={setOpen}
            index={index}
            control={control}
            name={selectedProductType==='inventory'?
            `line_items.${index}._inventoryproductid`:
            selectedProductType==='capex'?
            `line_items.${index}._capexproductid`:
            `line_items.${index}._usageproductid`
            }
            keyProp="_inventoryproductid"
            defaultValue={{
              _id: selectedProductType==='inventory'?entry._inventoryproductid:selectedProductType==='capex'?entry._capexproductid:entry._usageproductid,
              short_name: getProductName(selectedProductType),
            }}
            fieldsErrors={fieldsErrors}
            handleChangeProduct={handleChangeProduct}
            getOptionSelected={(o, v) => {
              return v._id === o._id;
            }}
            clearOnBlur={false}
            clearOnEscape={true}
            options={Products}
            getOptionLabel={(option) => {
              return option.short_name || "";
            }}
            selectedProductType={selectedProductType}
          ></CustomSelect>
        )}
      </TableCell>
      <TableCell>
        <Input
          control={control}
          index={index}
          keyProp="quantity"
          name={`line_items.${index}.quantity`}
          defaultValue={entity.quantity}
          id="Quantity"
          label="Quantity"
          variant="outlined"
          fullWidth
          fieldsErrors={fieldsErrors}
          helperText={""}
          type="number"
          handleChangeLineItemProp={handleChangeLineItemProp}
          max={4}
        ></Input>
      </TableCell>
      <TableCell>
        <Input
          control={control}
          index={index}
          keyProp="cost_per_unit"
          name={`line_items.${index}.cost_per_unit`}
          defaultValue={entity.cost_per_unit}
          id="cost_per_unit"
          label="Cost Per Unit"
          variant="outlined"
          fullWidth
          fieldsErrors={fieldsErrors}
          helperText={""}
          type="number"
          handleChangeLineItemProp={handleChangeLineItemProp}
          /* max={10} */
        ></Input>
      </TableCell>
      <TableCell>
      <TextField
          value={entity.purchase_tax}
          variant="outlined"
          fullWidth
          helperText={""}
          disabled
        ></TextField>
      </TableCell>
      <TableCell>
        <TextField
          value={entity.total_cost}
          variant="outlined"
          fullWidth
          helperText={""}
          disabled
        ></TextField>
      </TableCell>
    </TableRow>
  );
}

const Input = ({
  control,
  name,
  defaultValue,
  fieldsErrors,
  handleChangeLineItemProp,
  ...rest
}) => {
  const { index, keyProp, max } = rest;
  let hasError = false;
  hasError =
    fieldsErrors &&
    fieldsErrors["line_items"] &&
    fieldsErrors["line_items"][index] &&
    fieldsErrors["line_items"][index][keyProp];
  const showError = hasError ? true : false;
  return (
    <Controller
      /* type="number" */
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required: true }}
      error={showError}
      render={(props) => {
        return (
          <TextField
            inputProps={{ maxLength: max }}
            name={keyProp}
            /* onInput={(e) => {
              e.target.value = Math.max(0, parseFloat(e.target.value))
                .toString()
                .slice(0, max);
            }} */
            error={showError}
            onChange={(e) => {
              props.onChange(e.target.value);
              handleChangeLineItemProp(e);
            }}
            value={props.value ? props.value : ""}
            {...rest}
          ></TextField>
        );
      }}
    ></Controller>
  );
};

const CustomSelect = ({
  control,
  name,
  defaultValue,
  selectedProductType,
  fieldsErrors,
  handleChangeProduct,
  setOpen,
  ...rest
}) => {
  const { index, keyProp } = rest;
  let hasError = false;
  hasError =
    fieldsErrors &&
    fieldsErrors["line_items"] &&
    fieldsErrors["line_items"][index] &&
    fieldsErrors["line_items"][index][keyProp];
  const showError = hasError ? true : false;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      error={showError}
      helperText={showError ? "ERROR" : ""}
      defaultValue={defaultValue._id}
      render={(props) => (
        <Autocomplete
          defaultValue={defaultValue?defaultValue:null}
          onChange={(e, newVal) => {
            handleChangeProduct(e, newVal, props);
          }}
          renderOption={(option) => (
            <Chip
              label={option.short_name}
              deleteIcon={
                <VisibilityIcon titleAccess="View Product" />
              }
              onDelete={() =>
                setOpen({
                  open: true,
                  id: option._id,
                  type: selectedProductType,
                })
              }
            />
          )}
          renderInput={(params) => (
            <TextField
              style={{ width: "200px" }}
              error={showError}
              {...params}
              margin="normal"
            />
          )}
          {...rest}
        ></Autocomplete>
      )}
    ></Controller>
  );
};

function AlertDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const { open: pOpen, confirm, text, orderStatus } = props;
  useEffect(() => {
    setOpen(pOpen);
  }, [pOpen]);
  const handleClose = () => {
    confirm(true, orderStatus);
  };
  const handleCancel = () => {
    confirm(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Order Status</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function CommentItem({ field, index, control }) {
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    setExpanded(field.expanded);
  }, [field.expanded]);
  const handleChange = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <>
      <Controller
        control={control}
        name={`comments.${index}._id`}
        defaultValue={field._id}
        as={<span style={{ display: "none" }}>{field._id}</span>}
      ></Controller>
      <Controller
        control={control}
        name={`comments.${index}.isNew`}
        defaultValue={field.isNew}
        as={<span style={{ display: "none" }}>{field.isNew}</span>}
      ></Controller>
      <Accordion square expanded={expanded} onChange={(e) => handleChange()}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Box display="contents" p={1} bgcolor="background.paper">
            <Box
              style={{ backgroundColor: "unset" }}
              p={1}
              width="100%"
              bgcolor="grey.300"
            >
              Commented By User
            </Box>
            {field.edited && (
              <Box
                style={{ backgroundColor: "unset" }}
                p={1}
                flexShrink={1}
                bgcolor="grey.300"
              >
                Item 2
              </Box>
            )}
            <Box
              style={{ backgroundColor: "unset" }}
              p={1}
              flexShrink={0}
              bgcolor="grey.300"
            >
              {moment(field.commentDate).format("YYYY-MM-DD hh:mm A")}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Controller
            control={control}
            name={`comments.${index}.comment`}
            defaultValue={field.comment}
            as={<p disabled>{field.comment}</p>}
          ></Controller>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default ManagePurchaseOrder;