import React, { useCallback, useEffect, useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { Api } from "../../../services/Api";
import {
  Typography,
  Grid,
  Button,
  MenuItem,
  Select,
  CircularProgress,
  FormControl,
  Input,
  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
} from "@material-ui/core";

import {
  useHistory,
  useParams,
} from "react-router-dom";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
//import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(0),
  },
  checkboxGrid: {
    border: `${fade(theme.palette.text.primary, 0.2)} 1px solid`,
    marginTop: "5px",
    padding: "10px",
  },
}));

const ManageProduct = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const isClone = props.isClone;
  const productId = id;
  
  const initialState = {
    entity: {
      _usageproductcategoryid: null,
      short_name: "",
      meta: {
      },
      purchase_tax: 0,
      //default_dep_years: 5,
      product_type: "usage",
      //default_salvage_value: 0,
      description: ''
    },
    metas: [],
    categories: [],
    loading: false,
    error: undefined,
  };

  const product_type = "usage_products";
  const parentType = "usage"
  const [state, setState] = useState(initialState);
  
  const _metas = state.metas;

  const [items, setItems] = useState([]);

  const onUpdateMeta = useCallback(
    (actionType, row) => {
      switch (actionType) {
        case "EDIT":
          const metas = [..._metas];
          const meta = metas.find((m) => m.id === row.id);
          meta.key = row.key;
          meta.value = row.value;
          //state.meta[row.key] = row.value;
          setState((oldState) => {
            return { ...oldState, metas };
          });
          break;
        case "ADD":
          setState((oldState) => {
            const metas = [..._metas];
            metas.push({ id: (Date.now() + Math.random()).toString() });
            return { ...oldState, metas };
          });
          break;
        case "DELETE":
          const eMetas = [..._metas];
          const metaIndex = eMetas.findIndex((m) => m.id === row.id);
          if (eMetas.length === 0) eMetas.length = 0;
          else eMetas.splice(metaIndex, 1);
          //state.meta[row.key] = row.value;
          setState((oldState) => {
            return { ...oldState, metas: eMetas };
          });
          break;
        default:
          break;
      }
    },
    [_metas, setState]
  );

  useEffect(() => {
    //console.log("fetch data");
    //console.log(isClone);
    if (productId) {
      Promise.all([
        Api.getUsageProductById(productId),
        Api.getUsageProductItems(productId),
        Api.getUsageCategories(),
      ]).then(([res1, items, res2]) => {
        setItems(items);
        setValue("product_type", product_type);
        !isClone&&setValue("short_name", res1.short_name);
        res1._usageproductcategoryid?
          setValue("usageproductcategoryid", res1._usageproductcategoryid):
          setValue("usageproductcategoryid", 'unassigned');
        
        setValue("default_dep_years", res1.default_dep_years);
        setValue("purchase_tax", res1.purchase_tax);
        setValue("default_salvage_value", res1.default_salvage_value);
        setValue("description", res1.description || '');
        const metas = [];
        
        res1.meta&&Object.keys(res1.meta).forEach((m, idex) => {
          const obj = {};
          obj["key"] = m;
          obj["value"] = res1.meta[m];
          obj["isEditMode"] = false;
          obj.id = (Date.now() + Math.random()).toString();
          metas.push(obj);
          insert(idex, obj);
        });

        setValue("metas", metas);
        setState({
          entity: res1,
          categories: res2,//res2,
          loading: false,
          error: undefined,
          metas: metas,
        });
      });
    } else {
      Api.getUsageCategories().then((categories)=>{
        setState((prevState) => {
          return {
            ...prevState,
            categories: categories
          }
        });
      });
    }
    return () => console.log("manage disposed");
  }, [productId]);
  
  const isAdd = !id;
  const { entity, loading, categories, error } = state;
  const validationSchema = Yup.object().shape({
    metas: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required("Enter a field name"),
        value: Yup.string().required("Enter a field value"),
      })
    ),
    short_name: Yup.string().required("Enter a short name"),
    description: Yup.string(),
    usageproductcategoryid: Yup.string(),
   /*  default_salvage_value: Yup.number()
      .default(-1)
      .when(() => {
          return Yup.number()
            .transform((v) => (v ? parseFloat(v) : -1))
            .moreThan(-1, "Default Salvage Value is required");
      }),
      default_dep_years: Yup.number()
      .default(0)
      .when("product_type", () => {
          return Yup.number()
            .default(0)
            .transform((v) => (isNaN(parseFloat(v)) ? -1 : parseFloat(v)))
            .min(1, "Sales Tax can not be empty or less than 1");
      }),  */
      purchase_tax: Yup.number()
      .default(0)
      .transform((v) => (isNaN(parseFloat(v)) ? -1 : parseFloat(v)))
      .min(0, "Purchase Tax can not be empty or less than zero"),
    
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    errors: fieldsErrors,
    control,
    setValue,
    formState,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { fields, remove, insert, append } = useFieldArray({
    control,
    name: "metas",
  });

  function onSubmit(data) {
    setState({ ...state, loading: true });
    console.log("onsubmit", data);
    const newMeta = {};
    data.metas && data.metas.forEach((m) => {
      newMeta[m.key] = m.value;
    });
    const copy = {...entity};
    copy.meta = newMeta;
    copy._usageproductcategoryid = data.usageproductcategoryid==='unassigned'?null:data.usageproductcategoryid;
    //copy.default_salvage_value = data.default_salvage_value;
    //copy.default_dep_years = data.default_dep_years;
    copy.short_name = data.short_name;
    copy.purchase_tax = parseFloat(data.purchase_tax);
    copy.description = data.description || '';
    
    if(isClone) {
      const clone = { ...copy };
      delete clone._id;
      Api.saveUsageProduct(clone).then((response) => {
        console.log('response from manage... going back to list', response);
        setState({ ...state, loading: false });
        history.push(`/products/${parentType}`);
      }).catch(e=>console.log(e));

    }else{
      Api.saveUsageProduct(copy).then(() => {
        setState({ ...state, loading: false });
        history.push(`/products/${parentType}`);
      });
    }
  }

  function getItemsDepAmount(){
    let itemsDep = 0;
    let itemsValue = 0;
    let itemsNetValue = 0;
    items.map((item, key)=>{
      console.log(item);
      const receivedDate = moment(item.receivedOn).endOf('day');
      const daysSinceReceived = moment("2022-01-31").endOf('day').diff(receivedDate, 'days', true);
      const salvage_value = 1200;
      const depYears = 5;
      const purchase_value = item.purchase_cost;

      const depPerYear = (purchase_value-salvage_value)/depYears;

      const depPerDay = depPerYear/365;
      itemsDep += (depPerDay * daysSinceReceived)

      itemsValue+=purchase_value;

    });
    return {itemsDep,itemsValue};
  }

  return (
    <div>
      {loading && <CircularProgress />}
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {isAdd ? "Add " : isClone? "Clone " : "Manage "} Usage Product
        {/* Product Detail */}
      </Typography>
      {!isAdd&&!isClone&&
      <div></div>
      }
      <form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
        
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="short_name"
                control={control}
                defaultValue={entity.short_name}
                rules={{ required: true }}
                as={
                  <TextField
                    autoFocus
                    name="short_name"
                    id="outlined-basic"
                    label="Short Name"
                    variant="outlined"
                    fullWidth
                    error={fieldsErrors.short_name ? true : false}
                    helperText={
                      fieldsErrors.short_name
                        ? fieldsErrors.short_name.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <InputLabel id="category-select-label">Category</InputLabel>
              <Controller
                name="usageproductcategoryid"
                control={control}
                defaultValue={entity._usageproductcategoryid?entity._usageproductcategoryid:'unassigned'}
                render={(props) => (
                  <Select
                    labelId="category-select-label"
                    name="usageproductcategoryid"
                    id="usageproductcategoryid"
                    label="Category"
                    variant="outlined"
                    fullWidth
                    displayEmpty
                    error={fieldsErrors.category ? true : false}
                   /*  helperText={
                      fieldsErrors.category
                        ? fieldsErrors.category.message
                        : null
                    } */
                    {...props}
                  >
                    <MenuItem value="unassigned">Unassigned</MenuItem>
                    {categories.map((c, index) => {
                      return (
                        c.active&&
                        <MenuItem value={c._id} key={index}>
                          {c.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              ></Controller>
            </FormControl>
          </Grid>
                      
        </Grid>
       
        <Grid container spacing={3}>
        <Grid item xs={12}>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="purchase_tax"
                control={control}
                defaultValue={entity.purchase_tax}
                rules={{ required: true }}
                as={
                  <TextField
                    name="purchase_tax"
                    id="outlined-basic"
                    label="Purchase Tax (%)"
                    variant="outlined"
                    fullWidth
                    type="number"
                    error={fieldsErrors.purchase_tax ? true : false}
                    helperText={
                      fieldsErrors.purchase_tax
                        ? fieldsErrors.purchase_tax.message
                        : null
                    }
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
        
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              className={classes.margin}
              variant="outlined"
            >
              <Controller
                name="description"
                control={control}
                defaultValue={entity.description}
                as={
                  <TextField multiline
                    name="description"
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    rows={3}
                  ></TextField>
                }
              ></Controller>
            </FormControl>
          </Grid>
        </Grid>
        
        <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item>
            {/* <Button
              variant="contained"
              color="primary"
              //onClick={(e) => onUpdateMeta("ADD")}
              onClick={(e) => append({ key: "", value: "" })}
            >
              Add Meta
            </Button> */}
            <IconButton
              title="Click here to add custom field"
              aria-label="done"
              onClick={() => append({ key: "", value: "" })}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <MetaRow
          {...{
            meta: _metas,
            onUpdateMeta,
            control,
            fieldsErrors,
            fields,
            remove,
          }}
        />
        <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button onClick={(e) => history.push(`/products/${parentType}`)}>
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {isAdd ? "Add " : isClone? "Clone ": "Save "} Product
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  selectTableCell: {
    width: 40,
  },
  tableCell: {
    minWidth: 150,
    height: 40,
  },
  input: {
    width: '100%',
    height: 40,
  },
}));

const CustomTableCell = ({
  editable,
  row,
  name,
  value,
  onChange,
  isKey,
  idx,
  control,
  fieldsErrors,
}) => {
  const classes = useStyles1();
  const { isEditMode } = row;
  const cname = isKey ? `metas.${idx}.key` : `metas.${idx}.value`;
  const fielName = isKey ? `key` : `value`;
  const hasError =
    fieldsErrors.metas &&
    fieldsErrors.metas[idx] &&
    (isKey ? fieldsErrors.metas[idx]["key"] : fieldsErrors.metas[idx]["value"]);
  let errorMsg = "";
  if (hasError) {
    errorMsg = isKey
      ? fieldsErrors.metas[idx]["key"].message
      : fieldsErrors.metas[idx]["value"].message;
  }
  return (
    <TableCell align="left" className={classes.tableCell}>
      {editable && isEditMode ? (
        <Controller
          name={cname}
          control={control}
          defaultValue={value}
          rules={{ required: true }}
          as={
            <Input
              name={name}
              className={classes.input}
              error={hasError ? true : false}
              helperText={errorMsg ? errorMsg : null}
            />
          }
        ></Controller>
      ) : (
        // <Input
        //   value={value}
        //   name={name}
        //   onChange={(e) => onChange(e, row, isKey)}
        //   className={classes.input}
        // />
        <Controller
          name={cname}
          control={control}
          defaultValue={value}
          rules={{ required: true }}
          as={
            <Input
              name={name}
              className={classes.input}
              error={hasError ? true : false}
              helperText={errorMsg ? errorMsg : null}
            />
          }
        ></Controller>
      )}
    </TableCell>
  );
};

function MetaRow({
  meta,
  onUpdateMeta,
  control,
  fieldsErrors,
  fields,
  remove,
}) {
  const [rows, setRows] = React.useState(meta);
  const [previous, setPrevious] = React.useState({});
  const classes = useStyles1();

  useEffect(() => {
    setRows(meta);
    return () => console.log("disposed");
  }, [meta]);

  const onToggleEditMode = (id) => {
    setRows((state) => {
      return rows.map((row) => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onChange = (e, row, isKey) => {
    if (!previous[row.id]) {
      setPrevious((state) => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map((row) => {
      if (row.id === id) {
        if (isKey) {
          return { ...row, [name]: value, key: value };
        } else {
          return { ...row, [name]: value, value: value };
        }
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = (id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        const hello = previous[id] ? previous[id] : row;
        const anotherHello = { ...hello, isEditMode: !hello.isEditMode };
        return anotherHello;
      }
      return row;
    });
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    setRows(newRows);
    //onToggleEditMode(id);
  };

  const onRevert1 = (id) => {
    setPrevious((state) => {
      delete state[id];
      return state;
    });
  };

  const onEdit = (row) => {
    onRevert1(row.id);
    onUpdateMeta("EDIT", row);
  };

  const onDelete = (row) => {
    onUpdateMeta("DELETE", row);
  };

  const onAdd = () => {
    onUpdateMeta("ADD");
  };

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="caption table">
        {/* <caption>Product Meta</caption> */}
        <TableHead>
          <TableRow>
            <TableCell align="left" />
            <TableCell align="left">Field</TableCell>
            <TableCell align="left">Value</TableCell>
            {/* <TableCell align="left">Action</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((row, idx) => (
            <TableRow key={row.id}>
              <TableCell className={classes.selectTableCell}>
                {row.isEditMode ? (
                  <>
                    <IconButton aria-label="done" onClick={() => onEdit(row)}>
                      <DoneIcon />
                    </IconButton>
                    <IconButton
                      aria-label="revert"
                      onClick={() => onRevert(row.id)}
                    >
                      <RevertIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    {/* <IconButton
                      aria-label="delete"
                      onClick={() => onToggleEditMode(row.id)}
                    >
                      <EditIcon />
                    </IconButton> */}
                    <IconButton
                      aria-label="remove"
                      // onClick={() => onDelete(row)}
                      onClick={() => remove(idx)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </TableCell>

              <>
                <CustomTableCell
                  key={row.id + idx + 1}
                  {...{
                    editable: true,
                    row,
                    name: row.key,
                    value: row.key,
                    onChange,
                    isKey: true,
                    control: control,
                    fieldsErrors: fieldsErrors,
                    idx: idx,
                  }}
                />
                <CustomTableCell
                  key={row.id + idx + 2}
                  {...{
                    editable: true,
                    row,
                    name: row.key,
                    value: row.value,
                    onChange,
                    isKey: false,
                    control: control,
                    fieldsErrors: fieldsErrors,
                    idx: idx,
                  }}
                />
              </>
              {/* <TableCell className={classes.selectTableCell}>
                {idx === rows.length - 1 && (
                  <IconButton aria-label="done" onClick={() => onAdd()}>
                    <AddIcon />
                  </IconButton>
                )}
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default ManageProduct;