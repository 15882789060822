import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(0),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(0),
  },
}));

export default function FloatingActionButton(props) {
  const classes = useStyles();
  const handleClick = props.handleClick;
  return (
    <div className={classes.root}>
      <Fab onClick={(e)=>handleClick(e)} color="primary" aria-label="add" size ="small"> 
        <AddIcon />
      </Fab>
      
    </div>
  );
}
