import { Fragment/* , useCallback */, useEffect, /* useReducer, */ useState } from "react";
/* import { useHistory, useRouteMatch } from "react-router-dom"; */
import { Api } from "../../services/Api";
import Typography from "@material-ui/core/Typography";
import { 
  IconButton, 
  TextField, 
  Button,
 /*  Container, 
  MenuItem,
  Select,
  InputLabel,
  Switch,  */
  Chip, 
  /* FormControl, 
  FormControlLabel, 
  InputAdornment, 
  TableContainer  */
} from "@material-ui/core";
/* import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip"; */
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from "@material-ui/icons/Done";
/* import CancelIcon from "@material-ui/icons/Cancel"; */
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";

/* import FloatingActionButton from "../../screens/FloatingActionButton";

import Checkbox from "@material-ui/core/Checkbox";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SearchIcon from "@material-ui/icons/Search"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PurchaseOrderGrn from "../PurchaseOrder/Grn"; */

const ProductSelectView = (props) =>{
  const products = props.products;
  const setSelectedProduct = props.setSelectedProduct;
  const updateNewCharge = props.updateNewCharge;
  return (
    <Autocomplete
      options={products}
      defaultValue={null}
      onChange={(e, newVal) => {
        setSelectedProduct(newVal);
        updateNewCharge("name", newVal?newVal.short_name:'');
        updateNewCharge("amount", newVal?newVal.selling_price:0.01);
        updateNewCharge("vat_rate", newVal?newVal.sales_tax:0);
        updateNewCharge("_inventoryproductid", newVal?newVal._id:null);
      }}
      getOptionLabel={(option) => {
        return option.short_name || "";
      }}
      renderOption={(option) => (
        <Chip
          label={option.short_name}
          deleteIcon={
            <VisibilityIcon titleAccess="Click here to view product" />
          }
          onDelete={() =>{}
            /* setOpen({
              open: true,
              id: option._id,
              type: selectedProductType,
            }) */
          }
        />
      )}
      renderInput={(params) => (
        <TextField
          style={{ width: "200px" }}
          //error={showError}
          {...params}
          margin="normal"
        />
      )}
    ></Autocomplete>
  )
}

function roundAmount(amount){
  let frac = 100;
  return Math.round((amount+Number.EPSILON)*frac) / frac;
}

export default function Charges(props) {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [charges, setCharges] = useState([]);
  const [addChargeVisible, setAddChargeVisible] = useState(false);
  const [newCharge, setNewCharge] = useState({amount:0.01, vat_rate:0});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const currentLevelId = props.currentLevelId;
  const toggleManageCharges = props.toggleManageCharges;
  const setCurrentCharges = props.setCurrentCharges;
  
  const minChargeAmount = 0.01;
  const minChargeVatRate = 0;
  const maxChargeVatRate = 0.5;

  
  useEffect(() => {
    Promise.all([
      Api.getLevelCharges(currentLevelId),
      Api.getInventoryProducts()
    ]).then(([res, products]) => {
      setCharges(res.fee_charges);
      setProducts(products);
    }).catch((e)=>{
      console.log(e);
    });
  }, [currentLevelId]);


  function removeCharge(key){
    let tempCharges = [...charges];
    tempCharges.splice(key,1);
    setCharges(tempCharges);
  }

  function toggleAddCharge(){
    setAddChargeVisible((prev)=>{
      return !prev
    });
    setSelectedProduct(null);
  }

  function updateNewCharge(key, value){
    setNewCharge((prev)=>{
      return {
        ...prev,
        [key]: key==="amount"||key==="vat_rate"?parseFloat(value):value,
      }
    });
  }

  function addNewCharge(){
    console.log()
    if(newCharge.name && newCharge.name.length>0&& newCharge.amount > 0 && newCharge.vat_rate >= 0){
      let tempNewCharge = {...newCharge};
      tempNewCharge.vat_amount = roundAmount(newCharge.vat_rate*newCharge.amount);
      tempNewCharge.amount = roundAmount(newCharge.amount);
      let tempCharges = [...charges];
      tempCharges.push(tempNewCharge);
      setCharges(tempCharges);
      toggleAddCharge();
    }
  }

  function saveCharges(){
    Api.updateLevelCharges(currentLevelId, charges).then((res)=>{
      console.log(res);
      setCurrentCharges(charges);
      toggleManageCharges();
    }).catch((e)=>{
      console.log(e);
    })
  }
  
  return (
        <Fragment>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
          <IconButton
            aria-label="Grn"
            onClick={() => toggleManageCharges()}>
            <CloseIcon />
          </IconButton>
          </Grid>
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              Manage Level Charges
            </Typography>
          </Grid>
         <Grid item xs={6}>
         </Grid>
        </Grid>

         <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item xs={6}>{charges.length} found</Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          //style={{ marginTop: 10 }}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {!addChargeVisible&&
          <Grid item>
            <IconButton
              title="Add Charge"
              aria-label="done"
              onClick={() => toggleAddCharge()}
            >
              <AddIcon />
            </IconButton>
          
          </Grid>}

          {addChargeVisible&&
          <Grid item>
          <ProductSelectView updateNewCharge={updateNewCharge} products={products} setSelectedProduct={setSelectedProduct} />
          <Table><TableBody><TableRow>
            <TableCell>
            {selectedProduct? 
              selectedProduct.short_name
            :
            <TextField
            label="Charge Name"
            variant="outlined"
            onChange={(e)=>updateNewCharge("name", e.target.value.trim())}
            />
            }
            </TableCell>
            <TableCell>
            {selectedProduct? 
              selectedProduct.selling_price
            :
            <TextField
            label="Charge Amount"
            variant="outlined"
            defaultValue={minChargeAmount}
            onChange={(e)=>updateNewCharge("amount", e.target.value.trim())}
            onInput={(e) => {          
              if(!isNaN(e.target.value) && parseFloat(e.target.value)<0) e.target.value=minChargeAmount;
              if(isNaN(e.target.value)) e.target.value=minChargeAmount;
            }}
            />
            }
            </TableCell>
            <TableCell>
            {selectedProduct? 
              selectedProduct.sales_tax
            :
            <TextField
            label="Charge VAT Rate"
            variant="outlined"
            defaultValue={minChargeVatRate}
            onChange={(e)=>updateNewCharge("vat_rate", e.target.value.trim())}
            onInput={(e) => {          
              if((!isNaN(e.target.value)) 
              && (parseFloat(e.target.value)<0) 
              && (parseFloat(e.target.value)<minChargeVatRate 
              || (parseFloat(e.target.value)>maxChargeVatRate))) 
                e.target.value=minChargeVatRate;
              if(isNaN(e.target.value)) e.target.value=minChargeVatRate;
            }}
            />
            }
            </TableCell>
            <TableCell>
            <IconButton
              title="Add Charge"
              aria-label="done"
              onClick={() => addNewCharge()}
            >
              <DoneIcon />
            </IconButton>
            </TableCell>
            <TableCell>
            <IconButton
              title="Cancel"
              aria-label="cancel"
              onClick={() => toggleAddCharge()}
            >
              <CloseIcon />
            </IconButton>
            </TableCell>
          </TableRow></TableBody></Table>
          </Grid>
          } 
        </Grid>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>Amount</b>
              </TableCell>
              <TableCell>
                <b>VAT Rate</b>
              </TableCell>
              <TableCell>
                <b>VAT Amount</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {charges&&charges.map((obj, key) => (
                <TableRow key={key}>
                  <TableCell>
                    <IconButton aria-label="remove" onClick={() => removeCharge(key)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>{obj.name || ''}</TableCell>
                  <TableCell>
                    {obj.amount || 0}
                  </TableCell>
                  <TableCell>
                    {obj.vat_rate || 0}
                  </TableCell>
                  <TableCell>
                    {obj.vat_amount || 0}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Grid container spacing={3} style={{marginTop:10}}
        direction="row"
        justify="flex-end"
        alignItems ="center">
          <Grid item>
            <Button onClick={(e)=>toggleManageCharges()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button 
            variant="contained" 
            onClick={(e)=>saveCharges()}
            color="primary"
            >
              Save Level Charges
            </Button>
          </Grid>

      </Grid>
      </Fragment>
    );
}