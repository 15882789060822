import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { fade } from "@material-ui/core/styles/colorManipulator";
import CommentIcon from "@material-ui/icons/Comment";
import {
  Typography,
  Grid,
  Button,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  CircularProgress,
  FormControl,
  Chip,
  Avatar,
  Menu,
  IconButton
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Api } from "../../services/Api";
import FullScreenDialog from "../../screens/FullScreenDialog";
import CommentDialog from "../../screens/CommentDialog";
//import ViewProductCode from "components/Assignment/View";
import { default as ViewCapexProduct } from "../Product/Capex/View";
import { default as ViewInventoryProduct } from "../Product/Inventory/View";
import { default as ViewUsageProduct } from "../Product/Usage/View";
import { ORDER_STATUS, ORDER_STATUS_DISPLAY_TEXT } from "../../shared/constants";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DoneIcon from "@material-ui/icons/Done";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { positions } from "@material-ui/system";
import Box from "@material-ui/core/Box";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(0),
  },
  checkboxGrid: {
    border: `${fade(theme.palette.text.primary, 0.2)} 1px solid`,
    marginTop: "5px",
    padding: "10px",
  },
  formControl: {
    paddingLeft: "10px",
    marginTop: theme.spacing(0),
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
    marginLeft: "5px",
  },
}));

const initialState = {
  line_items: [],
};

const statusColors = {
  'New': 'default',
  'Approved': 'primary',
  'Canceled': 'secondary',
  'Sent To Supplier': 'primary'
}

const ViewPurchaseOrder = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const purchaseOrderId = id;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(initialState);
  const [poTotal, setPOTotal] = useState(0);
  const [inventoryProducts, setInventoryProducts] = useState([]);
  const [capexProducts, setCapexProducts] = useState([]);

  const [suppliersObject, setSuppliersObject] = useState({});
  const [commands, setCommands] = useState([]);
  let orderStatus = "";
  const [open, setOpen] = useState({ open: false, id: "", type: "" });
  const [commentOpenFlag, setCommentOpenFlag] = useState({
    open: false,
    comment: "",
    field: null,
    index: -1,
    setValue: null,
    disabled: false,
  });
  const [confirmDialog, setConfirmDialog] = useState({
    confirm: null,
    text: "",
    open: false,
    orderStatus: "",
  });
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCommand = (command) => {
    Api.executePurchaseOrderCommand(command, purchaseOrderId)
    .then((res)=>{
      setTimeout(()=>{ 
        Api.getPurchaseOrderStatus(purchaseOrderId).then((res)=>{
          setOrder((prev) => {
            return {
              ...prev,
              status: res
            }
          });
        }).catch((e)=>console.log(e));
      }
      , 2000);
    })
    .catch(e=>console.log(e));
    setAnchorEl(null);
  };

  const testGrn = (_inventoryproductid) =>{
    Api.testGrn(purchaseOrderId, _inventoryproductid);
  }

  function roundAmount(amount){
    let frac = 100;
    return Math.round((amount+Number.EPSILON)*frac) / frac;
  }
  
  useEffect(() => {
    Promise.all([
      Api.getInventoryProducts(),
      Api.getCapexProducts(),
      Api.getSuppliers(),
      purchaseOrderId?Api.getPurchaseOrder(purchaseOrderId):null,
      Api.getPurchaseOrderCommands()
    ]).then(([inventoryProducts, capexProducts, suppliers, po, cmds]) => {
      suppliers.forEach((supplier)=>{
        setSuppliersObject(old=>({...old, [supplier._id]: supplier}));
      });
      setInventoryProducts(inventoryProducts);
      setCapexProducts(capexProducts);
      if(po)setOrder(po);
      let totalCost = 0;
      po.line_items?.forEach((item)=>{
        totalCost = totalCost + item.total_cost;
      })
      po.line_items&&setPOTotal(roundAmount(totalCost));
      setCommands(cmds);
    }).catch((e)=>{
      console.log("Something went wrong while getting po", e);
    });
}, [purchaseOrderId]);

  const updateOrderStatus = useCallback(
    (status) => {
      Api.updateOrderStatus(id, status).then(() => {
        history.push("/purchaseorder");
      });
    },
    [id]
  );

  const handleDialog = (result, orderStatus) => {
    if (result) {
      if (orderStatus === ORDER_STATUS.Restart) orderStatus = ORDER_STATUS.New;
      Api.updateOrderStatus(id, orderStatus).then(() => {
        history.push("/purchaseorder");
      });
    } else {
      setConfirmDialog((prev) => {
        return {
          ...prev,
          open: false,
        };
      });
    }
  };

  const handleChange = (event) => {
    setConfirmDialog((prev) => {
      return {
        ...prev,
        open: true,
        text: "Are you sure you want to change order status?",
        confirm: handleDialog,
        orderStatus: event.target.value,
      };
    });
  };

  return (
    <div>
      {loading && <CircularProgress />}
      
      <AlertDialog
        open={confirmDialog.open}
        orderStatus={confirmDialog.orderStatus}
        text={confirmDialog.text}
        confirm={handleDialog}
      />

      <FullScreenDialog
        {...open}
        notify={setOpen}
        render={(_) => {
          if (open.type === "capex")
            return <ViewCapexProduct {...open} />;
          if (open.type === "inventory")
            return <ViewInventoryProduct {...open} />;
          return <ViewUsageProduct {...open} />;
        }}>
      </FullScreenDialog>

      <Fragment>
        <Grid container spacing={3}>
        <Grid item>
          <Typography component="h2" variant="h6" color="primary">
            View Purchase Order
          </Typography>
        </Grid>
        <Grid item sm container>
        <Grid item sm>
          <Chip label={order.status} color={statusColors[order.status]} />
        </Grid>
        </Grid>
        <Grid item>  
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              Select Command
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
            {commands&&commands.length>0&&commands.map((obj, key)=>(
              <MenuItem
              key={key}
              onClick={()=>handleCommand(obj)}
              >
              {obj}
              </MenuItem>
            ))}
            </Menu>
        </Grid>
        </Grid>
      </Fragment>
        

      <form>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              value={order._id || ''}
              id="outlined-basic"
              label="_id"
              variant="outlined"
              fullWidth
              disabled
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Added On"
              variant="outlined"
              fullWidth
              disabled
              value={order.addedOn || ''}
            ></TextField>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
              <TextField
                disabled
                id="outlined-basic"
                variant="outlined"
                label="Supplier"
                value={suppliersObject&&suppliersObject[order._supplierid]&&suppliersObject[order._supplierid].short_name || ''}
                fullWidth
              ></TextField>
          </Grid>
          <Grid item xs={6}>
              <TextField
                multiline
                name="description"
                id="outlined"
                label="Description"
                variant="outlined"
                fullWidth
                value={order.description || ''}
                disabled
                rows={4}
              ></TextField>
          </Grid>
        </Grid>
       
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Product Type</b>
              </TableCell>
              <TableCell>
                <b>Item</b>
              </TableCell>
              <TableCell>
                <b>Qty</b>
              </TableCell>
              <TableCell>
                <b>Cost Per Unit</b>
              </TableCell>
              <TableCell>
                <b>Purchase VAT</b>
              </TableCell>
              <TableCell>
                <b>Total Cost</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order?.line_items?.map((item, key) => (
              <LineItem
                setOpen={setOpen}
                key={key}
                index={key}
                entity={item}
                status={orderStatus}
                setCommentOpenFlag={setCommentOpenFlag}
                inventoryProducts={inventoryProducts}
                capexProducts={capexProducts}
                testGrn={testGrn}
              />
            ))}
          </TableBody>
        </Table>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <TextField
              variant="outlined"
              fullWidth
              helperText={""}
              disabled
              value={poTotal}
            ></TextField>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{ marginTop: 10 }}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Grid item>
            <Button onClick={(e) => history.push("/purchaseorder")}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

function LineItem({
  testGrn,
  index,
  entity,
  status,
  setOpen,
  setCommentOpenFlag,
  inventoryProducts,
  capexProducts,
  ...rest
}) {

  let product_type = "";
  if(entity._inventoryproductid) product_type="inventory"
  else if(entity._capexproductid) product_type="capex";
  else product_type="usage";

  function getProductName(_productid){
    let searchedProducts = entity._inventoryproductid?inventoryProducts:capexProducts;
    const foundProduct = searchedProducts.find(
      (s) => s._id === _productid
      );
      return foundProduct&&foundProduct.short_name?foundProduct.short_name:"";
  }
  return (
    <TableRow key={index}>
      <TableCell>
        <TextField
          variant="outlined"
          fullWidth
          disabled
          value={product_type}
        ></TextField>
      </TableCell>
      <TableCell>
        <Chip
          label={getProductName(entity._inventoryproductid?entity._inventoryproductid:entity._capexproductid)}
          deleteIcon={
            <VisibilityIcon titleAccess="Click here to view product" />
          }
          onDelete={() =>
            setOpen({
              open: true,
              id: entity._inventoryproductid?entity._inventoryproductid:entity._capexproductid,
              type: product_type,
            })
          }
        />
      </TableCell>
      <TableCell>
        <TextField
          variant="outlined"
          fullWidth
          disabled
          value={entity.quantity}
        ></TextField>
      </TableCell>
      <TableCell>
        <TextField
          variant="outlined"
          fullWidth
          disabled
          value={entity.cost_per_unit}
        ></TextField>
      </TableCell>
      <TableCell>
        <TextField
          variant="outlined"
          fullWidth
          disabled
          value={entity.purchase_tax}
        ></TextField>
      </TableCell>
      <TableCell>
        <TextField
          value={entity.total_cost}
          variant="outlined"
          fullWidth
          disabled
        ></TextField>
      </TableCell>
    </TableRow>
  );
}

function AlertDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const { open: pOpen, confirm, text, orderStatus } = props;
  useEffect(() => {
    setOpen(pOpen);
  }, [pOpen]);

  const handleClose = () => {
    confirm(true, orderStatus);
  };

  const handleCancel = () => {
    confirm(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Order Status</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ViewPurchaseOrder;