import { Fragment, useCallback, useEffect, useReducer, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Api } from "../../services/Api";
import Typography from "@material-ui/core/Typography";
import { 
  IconButton, 
  TextField, 
  Button,
  Container, 
  MenuItem,
  Select,
  InputLabel,
  Switch, 
  Chip, 
  FormControl, 
  FormControlLabel, 
  InputAdornment, 
  TableContainer 
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Cancel";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";

import FloatingActionButton from "../../screens/FloatingActionButton";

import Checkbox from "@material-ui/core/Checkbox";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SearchIcon from "@material-ui/icons/Search"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PurchaseOrderGrn from "../PurchaseOrder/Grn";

export default function Snapshots(props) {
  const [loading, setLoading] = useState(false);
  const [snapshots, setSnapshots] = useState([]);
  const [addSnapshotVisible, setAddSnapshotVisible] = useState(false);
  const [refreshSnapshots, setRefreshSnapshots] = useState(true);
  const [closingDate, setClosingDate] = useState(null);

  const toggleSnapshots = props.toggleSnapshots;
  const getGlAccountsByType = props.getGlAccountsByType;

  useEffect(() => {
    Promise.all([
      Api.getGLSnapshots(),
    ]).then(([res]) => {
      setSnapshots(res);
    }).catch((e)=>{
      console.log(e);
    });
  }, [refreshSnapshots]);

  function toggleAddSnapshot(){
    setAddSnapshotVisible((prev)=>{
      return !prev
    });
  }

  function createSnapshot(){
    closingDate&&Api.createGlSnapshot(closingDate).then(res=>{
      setRefreshSnapshots(!refreshSnapshots);
    }).catch(e=>console.log(e));
  }

  function dateOnly(date){
    return date.split("T")[0];
  }
  
  return (
        <Fragment>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item>
          <IconButton
            aria-label="Grn"
            onClick={() => toggleSnapshots()}>
            <CloseIcon />
          </IconButton>
          </Grid>
          <Grid item>
            <Typography component="h2" variant="h6" color="primary">
              General Ledger Closures
            </Typography>
          </Grid>
         <Grid item xs={6}>
         </Grid>
        </Grid>

         <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item xs={6}>{snapshots.length} found</Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {!addSnapshotVisible&&
          <Grid item>
            <IconButton
              title="Add Charge"
              aria-label="done"
              onClick={() => toggleAddSnapshot()}
            >
              <AddIcon />
            </IconButton>
          
          </Grid>}

         {addSnapshotVisible&&
          <Grid item>
          <Table><TableBody><TableRow>
            <TableCell>
          
            <TextField
            label="Closing Date"
            variant="outlined"
            onChange={(e)=>setClosingDate(e.target.value.trim())}
            />
            
            </TableCell>
            <TableCell>
            <IconButton
              title="Close Accounts"
              aria-label="done"
              onClick={() => createSnapshot()}
            >
              <DoneIcon />
            </IconButton>
            </TableCell>
            <TableCell>
            <IconButton
              title="Cancel"
              aria-label="cancel"
              onClick={() => toggleAddSnapshot()}
            >
              <CloseIcon />
            </IconButton>
            </TableCell>
          </TableRow></TableBody></Table>
          </Grid>
          }
        </Grid>

        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell></TableCell> */}
              <TableCell>
                <b>Closed On</b>
              </TableCell>
              <TableCell>
                <b>Snapped On</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {snapshots&&snapshots.map((obj, key) => (
                <TableRow key={key}>
                  {/* <TableCell> */}
                    {/* <IconButton aria-label="remove" onClick={() => removeCharge(key)}>
                      <DeleteIcon />
                    </IconButton> */}
                  {/* </TableCell> */}
                  <TableCell>{obj.closing_date || ''}</TableCell>
                  <TableCell>
                    {obj.snappedOn || 0}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
       
      </Fragment>
    );
}