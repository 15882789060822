import FeesView from "./Fees";
import { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";

function Index() {
  const { params, url } = useRouteMatch();
  return (
  <Switch>
    <Route path={`${url}/`} exact render={(props) => <FeesView {...params} />} />
    <Route path="*" render={(props) => <Redirect to={`/404`} />}></Route>
  </Switch>);
}

export default Index;
